//@import "var.conf";
@import "base.css";
@import "common.css";
//@import "page2.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
@media print,
screen and (min-width: 768px) {
  /*
  共通
  -------------------------------------*/
  #Main{
    .concernBlock{
      max-width:1280px;
      min-width:1000px;
      margin:0 auto;
      h3,h2{
        margin-bottom:50px;
        span{
          color:#ac832d;
          display:block;
          font-size:20px;
          font-family: "Roboto Slab", serif;
          font-weight: 300;
          letter-spacing:2px;
        }
      }
      .contSubBox{
        width:25%;
        float:left;
        border-bottom:1px solid #f1f1f1;
        figure{
          img{
            width:100%;
            height:auto;
          }
        }
        h4{
          position:relative;
          width:160px;
          height:40px;
          line-height:40px;
          text-align:center;
          margin:-20px auto 0;
          font-size:18px;
          color:#ac832d;
          background-color:#fff;
          border-top:1px solid #e7d48a;
          border-bottom:1px solid #e7d48a;
          font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
          font-weight: 400;
        }
        ul{
          margin:15px 0 12px;
          border-right:1px solid #f1f1f1;
          padding:12px 25px;
          li{
            &+li{
              border-top:1px solid #f1f1f1;
            }
            a{
              display:block;
              font-size:16px;
              color:#888;
              text-decoration:none;
              line-height:20px;
              padding:10px 4px;
              background-image:url(../img/arrow02.png);
              background-repeat:no-repeat;
              background-size:7px auto;
              background-position:center right 5px;
              &:hover{
                background-image:url(../img/arrow04.png);
                color:#c5a22b;
              }
            }
          }
        }
        &:last-of-type{
          ul{
            border:none;
          }
        }
      }
    }
    .catBox{
      border:1px solid #e5e5e5;
      border-radius:6px;
      position:relative;
      padding:30px 30px 22px;
      transition: 0.4s;
      overflow:hidden;
      figure{
        width:160px;
        //position:absolute;
        //top:30px;
        //left:30px;
        float:left;
        img{
          width:100%;
          height:auto;
        }
      }
      dl{
        padding-left:190px;
        dt{
          font-size:20px;
          line-height:30px;
          margin-bottom:5px;
          a{
            color:#222;
            text-decoration:none;
          }
        }
      }
      p{
        padding-left:190px;
        font-size:14px;
        line-height:28px;
        color:#888;
        &.price{
          color:#aaa;
        }
      }
      &:hover{
        border:1px solid #e8d492;
        box-shadow: 0 6px 8px 0 rgba(100, 81, 35, 0.2);
        dl dt a,p,{
          color:#c5a22b;
        }
      }
      &.topCatBox{
        padding:60px 60px 55px;
        figure{
          position:static;
          width:320px;
          float:left;
        }
        .txtTitle{
          float:right;
          width:558px;
          div{
            color:#888;
            font-size:16px;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            border-bottom:3px solid #f1f1f1;
            padding:8px 4px 2px 28px;
            display:inline-block;
            margin-bottom:15px;
            letter-spacing: 3px;
          }
          dl,p{
            padding-left:28px;
          }
          dl{
            dt,dd{
              display:inline-block;
              vertical-align:middle;
            }
            dt{
              font-size:24px;
              margin-right:10px;
            }
          }
        }
        &:hover{
          .txtTitle div{
            color:#c5a22b;
          }
        }
      }
    }
    .typeList{
      margin-bottom:8px;
      li{
        display:inline-block;
        margin-top:5px;
        margin-right:5px;
        color:#ac832d;
        font-size:12px;
        padding:0 5px 0 20px;
        height:20px;
        line-height:20px;
        border:1px solid #e9dcb9;
        background-color:#fbf6e3;
        background-repeat:no-repeat;
        background-position:center left 5px;
        letter-spacing:1px;
        &.make{
          background-image:url(../img/treatment_icon_make.png);
          background-size:13px auto;
        }
        &.pain{
          background-image:url(../img/treatment_icon_smile.png);
          background-size:12px auto;
        }
        &.introduction{
          background-image:url(../img/treatment_icon_people.png);
          background-size:14px auto;
        }
        &.monitor{
          background-image:url(../img/treatment_icon_monitor.png);
          background-size:12px auto;
        }
        &.lank01,&.lank02,&.lank03,&.rank01,&.rank02,&.rank03{
          background-image:url(../img/treatment_icon_crown.png);
          background-size:13px auto;
        }
        &.staff{
          background-image:url(../img/treatment_icon_staff.png);
          background-size:9px auto;
        }
        &.cost{
          background-image:url(../img/treatment_icon_cost.png);
          background-size:11px auto;
        }
        &.heart{
          background-image:url(../img/treatment_icon_heart.png);
          background-size:12px auto;
        }
        &.time{
          background-image:url(../img/treatment_icon_time.png);
          background-size:12px auto;
        }
        &.repeat{
          background-image:url(../img/treatment_icon_repeat.png);
          background-size:12px auto;
        }
      }
    }
    .underLink{
      background-color:#f9f8f5;
      padding:5px 15px 15px;
      li{
        display:inline-block;
        height:40px;
        line-height:40px;
        margin-right:4px;
        margin-top:10px;
        a{
          padding:0 15px;
          background-color:#fff;
          &:before{
            content:"";
            display:inline-block;
            width:13px;
            height:40px;
            vertical-align:top;
            background-image:url(../img/arrow01.png);
            background-size:13px auto;
            background-repeat:no-repeat;
            background-position:center left;
            margin-right:8px;
          }
        }
      }
    }
    .boxTitle{
      position:relative;
      margin-bottom:50px;
      h3{
        font-size:28px;
        text-align:left;
        display:inline-block;
        padding-right:14px;
        background-color:#fff;
        position:relative;
        span{
          font-family: 'Roboto Slab', serif;
          font-weight: 300;
          color:#ac832d;
          font-size:18px;
          display:block;
          letter-spacing:1.5px;
        }
      }
      &:before{
        content:"";
        position:absolute;
        left:0;
        top:45px;
        display:block;
        width:100%;
        height:3px;
        border-bottom:1px solid #e5e5e5;
        border-top:1px solid #e5e5e5;
      }
    }
    .newsList{
      width:800px;
      margin:30px auto 0;
      border-top:1px solid #dbdbdb;
      li{
        border-bottom:1px solid #f1f1f1;
        a{
          display:block;
          width:100%;
          padding:15px 0;
          text-decoration:none;
          dl{
            display:table;
            width:100%;
            table-layout:fixed;
            dt,dd{
              display:table-cell;
              vertical-align:top;
              transition: .4s;
            }
            dt{
              font-size:16px;
              line-height:32px;
              color:#222;
            }
            dd{
              font-size:14px;
              line-height:32px;
              color:#aaa;
              width:105px;
            }
          }
          &:hover{
            dl{
              dt,dd{
                color:#c5a22b;
              }
            }
          }
        }
      }
    }
    .columnSliderWrap{
      margin-top:50px;
      overflow:hidden;
      .bx-wrapper{
        position: relative;
        width: 1000px;
        margin: 0 auto;
        .bx-viewport{
          overflow: visible !important;
          width: 1000px !important;
          &:before,&:after{
            content:"";
            width:100%;
            height:100%;
            display:block;
            opacity: 0.4;
            position:absolute;
            top:0;
            left:-1030px;
            border-radius:6px;
            background:#fff;
            z-index:100;
          }
          &:after{
            left:auto;
            right:-1030px;
          }
        }
        .bx-prev,.bx-next{
          display:block;
          position:absolute;
          top:35%;
          margin:auto;
          width:60px;
          height:60px;
          text-indent:-9999px;
          text-decoration:none;
          border-radius:50%;
          background-color:#fff;
          border:1px solid #f0e8c8;
          box-shadow:0px 0px 10px 0px rgba(0,0,0,0.05);
          background-repeat:no-repeat;
          background-size:9px auto;
          background-position:center center;
          &:hover{
            border:1px solid #e8d492;
            box-shadow:0px 0px 20px 0px rgba(0,0,0,0.1);
          }
        }
        .bx-prev{
          background-image:url(../img/arrow03.png);
          left:-20px;
        }
        .bx-next{
          background-image:url(../img/arrow04.png);
          right:-20px;
        }
        .bx-pager{
          margin-top:18px;
          text-align:center;
          .bx-pager-item{
            display:inline-block;
            vertical-align:top;
            width:12px;
            height:12px;
            &+.bx-pager-item{
              margin-left:18px;
            }
            a{
              display:block;
              width:100%;
              height:100%;
              border-radius:50%;
              text-indent:-9999px;
              background-color:#dadada;
              &.active{
                background-color:#dccb87;
              }
            }
          }
        }
      }
    }
    .columnSlider{
      .columnSliderBox{
        width:1000px !important;
        border:1px solid #e5e5e5;
        border-radius:6px;
        position:relative;
        padding:60px 60px 55px;
        background-color:#fff;
        margin:0 30px 0 0;
        height:315px;
        transition:0.4s;
        figure{
          width:320px;
          float:left;
          img{
            width:100%;
            height:auto;
          }
        }
        .txtTitle{
          float:right;
          width:558px;
          dl,p{
            padding-left:28px;
          }
          dl{
            dt{
              font-size:24px;
              margin-top:10px;
              line-height:30px;
              margin-bottom:5px;
              a{
                color:#222;
                text-decoration:none;
              }
            }
            dd{
              font-size:20px;
              font-family: "Roboto Slab", serif;
              font-weight: 300;
              color:#ac832d;
            }
          }
        }
        p{
          font-size:14px;
          line-height:28px;
          color:#888;
          &.price{
            color:#aaa;
          }
        }
        &:hover{
          border:1px solid #e8d492;
          box-shadow: 0 6px 8px 0 rgba(100, 81, 35, 0.2);
          .txtTitle dl dt a,.txtTitle dl dd,p,{
            color:#c5a22b;
          }
        }
      }
    }
    .underCommonGuide{
      border:4px solid #f1f1f1;
      width:1000px;
      margin:0 auto;
      padding:35px 55px;
      &+.underCommonGuide{
        margin-top:30px;
      }
      dl{
        &+dl{
          margin-top:30px;
        }
        dt{
          font-size: 16px;
          line-height: 32px;
          font-weight: 700;
          color:#ac832d;
          border-bottom:1px solid #e7d48a;
          margin-bottom:23px;
          padding-bottom:5px;
          letter-spacing: 0.1em;
        }
        dd{
          font-size: 16px;
          line-height: 32px;
          img{
            max-width: 100%;
            height: auto;
          }
          a{
            padding-right: 24px;
            background: url(../img/icon_link.png) no-repeat right center;
            background-size: 15px auto;
            font-size: 16px;
          }
          &.minorLink{
            text-align: right;
            a{
              background:none;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #TopNavImg{
      max-width:1280px;
      min-width:1060px;
      padding-left:30px;
      display:table;
      width:100%;
      margin:0 auto;
      table-layout: fixed;
      margin-top:15px;
      #GlobalNav{
        width:250px;
        min-width:100%;
        display:table-cell;
        vertical-align:top;
        text-align:left;
        padding-top: 14px;
        ul{
          text-align:left;
          li{
            display:block;
            text-align:left;
            .childAbout{
            	width: 230px;
            }
            &+li{
              margin-left:0;
            }
          }
        }
        >ul{
          &:first-of-type{
            border:none;
          }
        }
      }
      #TopMainImg{
/*        display:table-cell;
        vertical-align:top;
        position:relative;
        .mainImgSlider{
          li{
            position:relative;
            img{
              width:100%;
              height:auto;
            }
            .mainTxt{
              text-align:left;
              position:absolute;
              top:50%;
              transform: translateY(-50%);
              font-size:42px;
              color:#ac832d;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              line-height:60px;
              letter-spacing:16px;
              &:before{
                content:"";
                display:block;
                width:143px;
                height:167px;
                background-image:url(../img/slide_logo.png);
                background-repeat:no-repeat;
                background-position:top left;
                background-size:143px auto;
                position:absolute;
                left:-60px;
                top:-67px;
              }
              span{
                position:relative;
              }
              &.right{
                right:48px;
              }
              &.right02{
                right:24px;
              }
              &.left{
                left:100px;
              }
              &.fontW{
                color:#fff;
                text-shadow:0px 0px 10px rgba(0,0,0,0.3);
              }
            }
          }
        }
        .bx-wrapper{
          .bx-viewport{
            overflow:visible!important;
          }
          .bx-pager{
            margin-top:15px;
            text-align:left;
            .bx-pager-item{
              display:inline-block;
              vertical-align:top;
              width:70px;
              height:3px;
              &+.bx-pager-item{
                margin-left:4px;
              }
              a{
                display:block;
                width:100%;
                height:100%;
                text-indent:-9999px;
                background-color:#e6e6e6;
                &.active{
                  background-color:#e7d48a;
                }
              }
            }
          }
        }
        .topLink{
          position:absolute;
          right:30px;
          bottom:-30px;
          width:192px;
          height:192px;
          z-index:55;
          a{
            display:block;
            width:100%;
            height:100%;
            background: linear-gradient(to right, #a3781d 0%,#c8a743 36%,#dcc77f 67%,#c9a536 100%);
            box-shadow:0px 0px 10px 0px rgba(0,0,0,0.2);
            border-radius:50%;
            text-align:center;
            position:relative;
            font-size:14px;
            line-height:24px;
            text-decoration:none;
            color:#fff;
            padding-top:45px;
            span{
              display:block;
            }
            &:after{
              content:"More";
              border-bottom:1px solid #fff;
              font-size:14px;
              font-family: 'Roboto Slab', serif;
              font-weight: 300;
              padding-bottom:8px;
              line-height:1;
              margin-top:20px;
              display:inline-block;
            }
            &:hover{
              box-shadow:0px 0px 20px 0px rgba(0,0,0,0.4);
            }
          }
        }
*/      }
    }
      #TopMainImg{
		  position: relative;
		  margin:0 auto;
		  padding-top: 56.25%;
		  width:100%;
/*      max-width:1280px;
*/      min-width:1060px;
		  .video_item{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
		  }
		}
    #Main{
      margin-top:90px;
      .h3{
        span{
          color:#ac832d;
          display:block;
          font-size:20px;
          font-family: "Roboto Slab", serif;
          font-weight: 300;
          letter-spacing:2px;
        }
      }
      .subTitle{
        font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
        font-weight: 400;
        font-size:20px;
        color:#888;
        text-align:center;
        margin-bottom:13px;
      }
      .note{
        color:#888;
        text-align:center;
        margin-top:20px;
        letter-spacing:3px;
      }
      .linkBtn{
        width:250px;
        height:50px;
        line-height:50px;
        margin:45px auto 0;
        a{
          font-size:16px;
          background-color:#fff;
        }
      }
      #ContBox01{
        padding-top: 80px;
        .contSubBox{
          border-bottom:none;
        }
      }
      #ContBox02{
        margin-top:15px;
        height:752px;

        background:url(../img/top_mission.jpg) no-repeat center center;
        background-size:cover;
        padding-top:75px;
        h2{
          span{
            font-size:36px;
          }
        }
        p{
          text-align:center;
          font-size:18px;
          line-height:40px;
          font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
          font-weight: 400;
          color:#fff;
          margin-top:34px;
          letter-spacing:3px;
          text-shadow:0px 0px 10px rgba(0,0,0,0.6);
          &+p{
            margin-top:45px;
          }
        }
      }
      #ContBox03{
        margin-top:84px;
        h2,h2 span{
          font-size:32px;
          line-height:1;
          span{
            margin-top:7px;
            letter-spacing: 5px;
          }
        }
        .topCatBlock{
          margin-top:20px;
          overflow:hidden;
          .catBox{
            position:relative;
            width:485px;
            float:left;
            margin-top:30px;
            &:nth-of-type(even){
              margin-right:30px;
            }
            .rankIcon{
              position:absolute;
              top:18px;
              left:18px;
              width:45px;
              height:45px;
              z-index:2;
              display:block;
              background-color:#f5f5f5;
              border-radius:50%;
              text-align:center;
              line-height:45px;
              font-family: "Roboto Slab", serif;
              font-size: 18px;
              font-weight: 300;
              color:#aaa;
              &.rankIcon01{
                top:40px;
                left:40px;
                width:60px;
                height:60px;
                line-height:60px;
                font-size:24px;
                color:#fff;
                background: linear-gradient(45deg, #b58b32 0%,#dabe68 36%,#ebd896 67%,#cfb054 100%);
              }
              &.rankIcon02{
                color:#fff;
                background: linear-gradient(45deg, #818181 0%,#adaeae 36%,#e7e7e8 67%,#b7b8b7 100%);
              }
              &.rankIcon03{
                color:#fff;
                background: linear-gradient(45deg, #b87d37 0%,#d5a46a 36%,#e8c7a1 67%,#deaa6d 100%);
              }
            }
          }
          .topCatBox{
            width:100%;
            float:none;
          }
        }
        .linkBtn{
          margin: 30px auto 0;
          width: 130px;
          height: 40px;
          line-height: 40px;
          a {
            font-size: 14px;
          }
        }
      }
      #ContBox04{
        margin-top:55px;
        h2{
          font-size:20px;
          span{
            font-size:36px;
          }
        }
        .linkBtn{
          width:130px;
          height:40px;
          line-height:40px;
          margin:30px auto 0;
          a{
            font-size:14px;
          }
        }
      }
      #ContBox05{
        margin-top:90px;
        padding-top:80px;
        padding-bottom:100px;
        //background:url(../img/clum_bg_ue.png) no-repeat center top,url(../img/clum_bg_shita.png) no-repeat center bottom;
        background-image:url(../img/clum_bg_ue.png),url(../img/clum_bg_shita.png);
        background-repeat:no-repeat,no-repeat;
        background-position:top center,bottom,center;
        background-size:100% auto,100% auto;
        background-color:#f9f7f3;
        .blogList{
          background-color:#fff;
          margin-top:45px;
        }
      }
      #ContBox06{
        margin-top: 70px;
        h2{
          font-size: 20px;
          margin-bottom: 40px;
          span {
            font-size: 36px;
          }
        }
        .bnrBlogList{
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          li{
            width: calc( (100% / 4) - 15px );
            margin-right: 20px;
            margin-bottom: 20px;
            &:nth-of-type(4n){
              margin-right: 0;
            }
            a{
              display: block;
              &:hover{
                opacity: 0.7;
                //box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .1);
              }
            }
            figure{
              width: 100%;
              height: auto;
              img{
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }

  /*
  はじめての方へ
  -------------------------------------*/
  #PageWelcome.pageIndex {
    #Main{
    	#FirstNav{
        border:1px solid #e5e5e5;
        width:1000px;
        height:50px;
        line-height:50px;
        margin:0 auto;
        border-radius:6px;
        ul{
          font-size:0;
          letter-spacing:normal;
          li{
            width:calc(100% / 2);
            display:inline-block;
            font-size:14px;
            text-align:center;
            position:relative;
            &+li{
              &:before{
                content:"";
                display:block;
                width:1px;
                height:30px;
                background-color:#f1f1f1;
                position:absolute;
                top:0;
                bottom:0;
                left:0;
                margin:auto;
              }
            }
            a{
              display:block;
              width:100%;
              height:100%;
              color:#888;
              text-decoration:none;
              letter-spacing:1px;
              &:hover{
                color:#c5a22b;
              }
            }
            span{
              position:relative;
              display:inline-block;
              &:after{
                content:"";
                display:none;
                width:100%;
                height:2px;
                background-color:#e7d48a;
                position:absolute;
                bottom:2px;
                left:0;
              }
            }
          }
        }
      }
      margin-bottom:135px;
      #ContBox01{
      	margin-top:80px;
        margin-bottom:80px;
        dl{
          color:#888;
          font-size:20px;
          line-height: 36px;
          font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
          font-weight: 400;
          text-align:center;
          margin:45px 0 50px;
        }
        p+p{
          margin-top:20px;
        }
        .contSubBox{
          max-width:1280px;
          min-width:1000px;
          margin:0 auto;
          h4{
            font-size:28px;
            color:#ac832d;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            border-bottom:3px solid #f1f1f1;
            padding-bottom:20px;
            line-height:1;
            margin-bottom:30px;
            letter-spacing:5px;
          }
        }
        #ContSubBox01{
          padding-left:60px;
          width: 100%;
          display: table;
          table-layout: fixed;
          div,figure{
            display:table-cell;
            vertical-align: top;
          }
          figure{
            width:49%;
            img{
              width:100%;
              height:auto;
            }
          }
          div{
            padding-top:110px;
            padding-left:60px;
            p{
              width:420px;
              margin-bottom:20px;
            }
            img{
              width:250px;
              height:auto;
            }
          }
        }
        #ContSubBox02{
          margin-top:60px;
          .txt{
            width:1000px;
            margin:0 auto;
            h4,p{
              width:875px;
              margin-left:auto;
            }
          }
          .img{
            margin:54px 0 0 auto;
            position:relative;
            max-width:1140px;
            display:table;
            width:100%;
            table-layout: fixed;
            img{
              width:100%;
              height:auto;
            }
            figure{
              display:table-cell;
              vertical-align:top;
              &:first-of-type{
                padding-right:60px;
                text-align:right;
                img{
                	width:250px;
                }
              }
              &:nth-of-type(2){
                //padding-left:310px;
                //width:100%;
                width:73%;
                padding-top:70px;
              }
            }
          }
        }
        #ContSubBox03{
          margin-top:90px;
          width:1000px;
          .txt{
            width:750px;
            float:left;
          }
          .img{
            float:right;
            width:190px;
            img{
              width:100%;
              height:auto;
            }
          }
        }
        #ContSubBox04{
          margin-top:85px;
          //display:table;
          //width:100%;
          //table-layout: fixed;
          position:relative;
          >div{
            display:table;
            width:100%;
            table-layout: fixed;
          }
          figure,div div{
            display:table-cell;
            vertical-align:top;
          }
          figure{
            width:35%;
            img{
              width:100%;
              height:auto;
            }
          }
          div div{
            padding-top:28px;
            padding-left:60px;
            //width:685px;
            h4,p{
              width:625px;
            }
          }
        }
      }
    }
  }

  /*
  遠方からお越しのお客様へ
  -------------------------------------*/
  #PageDistant{
    #Main{
      #FirstNav{
        border:1px solid #e5e5e5;
        width:1000px;
        height:50px;
        line-height:50px;
        margin:65px auto;
        border-radius:6px;
        ul{
          font-size:0;
          letter-spacing:normal;
          li{
            width:calc(100% / 2);
            display:inline-block;
            font-size:14px;
            text-align:center;
            position:relative;
            &+li{
              &:before{
                content:"";
                display:block;
                width:1px;
                height:30px;
                background-color:#f1f1f1;
                position:absolute;
                top:0;
                bottom:0;
                left:0;
                margin:auto;
              }
            }
            a{
              display:block;
              width:100%;
              height:100%;
              color:#888;
              text-decoration:none;
              letter-spacing:1px;
              &:hover{
                color:#c5a22b;
              }
            }
            span{
              position:relative;
              display:inline-block;
              &:after{
                content:"";
                display:none;
                width:100%;
                height:2px;
                background-color:#e7d48a;
                position:absolute;
                bottom:2px;
                left:0;
              }
            }
          }
        }
      }
    }
  }
  #PageWelcome.pageIndex #Main #FirstNav ul li:first-child,
  #PageDistant.pageIndex #Main #FirstNav ul li:nth-child(2){
    a{
      color:#ac832d;
    }
    span:after{
      display:block;
    }
  }
  // ここから本文
  #PageDistant.pageIndex {
    #Main{
      margin-bottom:115px;
      .contBox{
        h3{
          margin-top:80px;
          font-size:32px;
          letter-spacing:0.2em;
        }
      }
      #ContBox01{
        .txt{
          width:800px;
          margin:45px auto 80px;
          p{
          	font-size:17px;
          	text-align:center;
          }
          p+p{
          	margin-top:20px;
          }
        }
      }
      #ContBox02{
      	border:4px solid #f1f1f1;
      	width:1000px;
      	height:516px;
      	margin:0 auto 165px auto;
      	padding:35px 55px;
      	dt{
      		margin-bottom:27px;
      		border-bottom:1px solid #e7d48a;
      		padding-bottom:8px;
      		line-height:28px;
      		letter-spacing:.1em;
      		font-size:16px;
      		font-weight:700;
      		color:#ac832d;
      	}
      	img{
      		float:left;
      		width:320px;
      		margin-top:4px;
      	}
      	dd.part00 {
      		line-height:32px;
      		font-size:17px;
      		padding:7px 0 22px 70px;
      		border-bottom:1px solid #f1f1f1;
      		margin-left:340px;
        	&.part01{background-image:url(../img/enpou/icon_form.gif)}
        	&.part02{background-image:url(../img/enpou/icon_hotel.gif)}
        	&.part03{background-image:url(../img/enpou/icon_travel.gif)}
      		background-repeat:no-repeat;
      		background-position:10px -10px;
      		background-size:55px;

      	}
      	dd.part00+dd.part00{
      		margin-top:12px;
      	}
      	p{
      		font-size:17px;
      		margin-left:350px;
      		margin-top:25px;
      	}
      }
    }
  }

  /*
  クリニック メディア 共通
  -------------------------------------*/
  #PageAbout,
  #PageMedia{
    #Main{
      #ClinicNav{
        border:1px solid #e5e5e5;
        width:1000px;
        height:50px;
        line-height:50px;
        margin:0 auto;
        border-radius:6px;
        ul{
          font-size:0;
          letter-spacing:normal;
          li{
            width:calc(100% / 6);
            // width:calc(100% / 5); //ドクター紹介非表示のため
            display:inline-block;
            font-size:14px;
            text-align:center;
            position:relative;
            &+li{
              &:before{
                content:"";
                display:block;
                width:1px;
                height:30px;
                background-color:#f1f1f1;
                position:absolute;
                top:0;
                bottom:0;
                left:0;
                margin:auto;
              }
            }
            a{
              display:block;
              width:100%;
              height:100%;
              color:#888;
              text-decoration:none;
              letter-spacing:1px;
              &:hover{
                color:#c5a22b;
              }
            }
            span{
              position:relative;
              display:inline-block;
              &:after{
                content:"";
                display:none;
                width:100%;
                height:2px;
                background-color:#e7d48a;
                position:absolute;
                bottom:2px;
                left:0;
              }
            }
          }
        }
      }
    }
  }
  #PageAbout.pageIndex #Main #ClinicNav ul li:first-child,
  #PageAbout.pageDoctorIndex #Main #ClinicNav ul li:nth-child(2),
  #PageAbout.pageStaffIndex #Main #ClinicNav ul li:nth-child(3),
  #PageAbout.pageClinicIndex #Main #ClinicNav ul li:nth-child(4),
  #PageAbout.pageRecruitIndex #Main #ClinicNav ul li:nth-child(5),
  #PageMedia #Main #ClinicNav ul li:nth-child(6){
    a{
      color:#ac832d;
    }
    span:after{
      display:block;
    }
  }
  /*
  クリニックについて
  -------------------------------------*/
  #PageAbout.pageIndex {
    #Main{
      margin-bottom:115px;
      .contBox{
        h3{
          margin-top:80px;
          margin-bottom:45px;
        }
      }
      #ContBox01{
        ol{
          width:800px;
          border-top:1px solid #e7d48a;
          border-bottom:1px solid #e7d48a;
          margin:0 auto;
          list-style: none;
          background-image:url(../img/logo.png);
          background-repeat:no-repeat;
          background-size:98px auto;
          background-position:83px center;
          padding:42px 0 45px 240px;
          li{
            color:#ac832d;
            font-size:20px;
            line-height:46px;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            letter-spacing:3.5px;
            span{
              font-family: 'Roboto Slab', serif;
              font-weight: 300;
              font-size:32px;
              font-style: italic;
              padding-right:15px;
            }
          }
        }
      }
      #ContBox02{
        .titleTmg{
          position:relative;
          height:300px;
          background-image:url(../img/contents/clinic_img.jpg);
          background-repeat:no-repeat;
          background-size:cover;
          background-position:center;
          h4{
            position:absolute;
            top:50%;
            left:52%;
            transform: translateY(-50%) translateX(-50%);
            color:#fff;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            font-size:42px;
            letter-spacing:16px;
            span{
              &.span_h4{
                font-family: 'Roboto Slab', serif;
                font-weight: 300;
                display:block;
                position:relative;
                padding-left:63px;
                font-size:24px;
                letter-spacing:2.5px;
                left:140px;
                top:10px;
                &:before{
                  content:"";
                  display:block;
                  height:1px;
                  width:50px;
                  background-color:#fff;
                  position:absolute;
                  top:0;
                  bottom:0;
                  left:0;
                  margin:auto;
                }
              }
            }
          }
        }
        .txt{
          width:800px;
          margin:50px auto 0;
          p+p{
            margin-top:20px;
          }
        }
      }
      #ContBox03{
        table{
          width:1000px;
          margin:0 auto;
          border:1px solid #e5e5e5;
          td,th{
            border:1px solid #e5e5e5;
            font-size:16px;
            padding:23px 15px;
          }
          th{
            background-color:#f5f5f5;
            width:220px;
          }
        }
      }
    }
  }

  /*
  ドクター紹介
  -------------------------------------*/
  #PageAbout.pageDoctorIndex {
    #Main{
    	margin-bottom:140px;
      h3{
        margin:84px 0 50px;
      }
      #ContBox01{
        // 19/04/11谷岡作業ここから
        .contLinkBox{
          background-color: #f9f8f5;
          margin-bottom: 60px;
          padding: 15px 0;
          ul{
            display: flex;
            justify-content: center;
            //width: 660px;
            //margin: 0 auto;
            &::after{
             content: "";
             display:block;
             clear: both;
             }
             li{
              margin-right: 20px;
              // float: left;
              border-radius: 5px;
              border: 1px solid #e7d48a;
              background-color: #fff;
              /*width: 490px;*/
              width: 320px;
              height: 130px;
              -webkit-transition: all .3s;
              transition: all .3s;
              &:last-of-type{
                margin-right: 0;
              }
              &:hover{
                -webkit-box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .1);
                box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .1);
              }
              a{
                text-decoration: none;
                dl{
                  padding-top: 50px;
                  padding-bottom: 55px;
                  padding-left:130px;
                  position: relative;
                  &:before{
                    content: "";
                    display: block;
                    position: absolute;
                    left: 15px;
                    top: 15px;
                    margin: auto;
                    width: 100px;
                    height: 100px;
                  }
                  &::after{
                    content: "";
                    display: block;
                    background: url(../img/contents/arrow01.png) 0 0 / 16px auto no-repeat;
                    width: 16px;
                    height: 8px;
                    position: absolute;
                    right: 20px;
                    bottom: 65px;
                    margin: auto;
                  }
                  dt{
                    color: #c5a22b;
                    font-size: 16px;
                    margin-right: 15px;
                    display: inline-block;
                  }
                  dd{
                    color: #c5a22b;
                    font-size: 16px;
                    display: inline-block;
                  }
                }
              }
            }
            .list01 a dl::before{
              background:  url(../img/contents/doctor01_02.png) 0 0 / 100px 100px no-repeat;
            }
            .list02 a dl::before{
              background:  url(../img/contents/doctor03_02.png) 0 0 / 100px 100px no-repeat;
            }
            .list03 a dl::before{
              background:  url(../img/contents/doctor06.png) 0 0 / 100px 100px no-repeat;
            }
          }
        }
        //ここまで
      .contSubBox{
        border:1px solid #e5e5e5;
        border-radius:6px;
        padding:60px 60px 50px;
        &+.contSubBox{
          margin-top:60px;
        }
        .topProfile{
          position:relative;
          padding-left:340px;
          min-height: 368px;
          figure{
            position:absolute;
            top:0;
            left:0;
            img{
              width:280px;
              height:auto;
            }
          }
          .nameImg div{
            padding-top:20px;
            p,h4{
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
            }
            p{
              font-size:20px;
              color:#888;
            }
            >span{
              color:#888;
              font-size:14px;
            }
            h4{
              font-size:28px;
              margin:2px 0;
              letter-spacing:2px;
              span{
                color:#b58b32;
                font-size:14px;
                font-family: 'Roboto Slab', serif;
                font-weight: 300;
                padding-left:1em;
                letter-spacing:1.5px;
              }
            }
          }
          >dl{
            margin-top:36px;
            >dt{
              display:inline-block;
              font-size:14px;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              border-bottom:1px solid #222;
              padding-bottom:5px;
              margin-bottom:22px;
            }
            >dd{
              dl{
                width:100%;
                display:table;
                dt,dd{
                  display:table-cell;
                  vertical-align:top;
                  font-size:14px;
                  line-height:28px;
                }
                dt{
                  width:78px;
                }
              }
            }
          }
        }
        .videoBox{
          margin: 50px auto 0;
          iframe{
            width: 100%;
          }
        }
        .bottomProfile{
          margin-top:50px;
          .right{
            float:right;
            width:250px;
            text-align:right;
            img{
              width:100%;
              height:auto;
              &+img{
                width:180px;
                margin-left:auto;
                margin-top:30px;
              }
            }
          }
          .left{
            p{
              &+p{
                margin-top:20px;
              }
            }
            .leftTxt{
              width:574px;
            }
            img{
              width:100%;
              height:auto;
              margin:50px 0;
            }
          }
        }
      }

      // 240312楊作業start
      #Doctor03 {
        .bottomProfile .left .leftTxt {
          width: 100%;
        }
      }
      // 240312楊作業end
    }
  }
}

  /*
  スタッフ紹介
  -------------------------------------*/
  #PageAbout.pageStaffIndex {
    #Main{
      margin-bottom:155px;
      h3{
        margin:80px 0 55px;
      }
      #ContBox01{
        .boxImage {
          width: 100%;
          text-align: center;
          overflow-x: hidden;
          img {
            width: 1280px;
            height: auto;
          }
        }
        .innerBasic{
          img{
            width:100%;
            height:auto;
          }
          >div{
            width:800px;
            margin:45px auto 0;
          }
          h4{
            color:#ac832d;
            font-size:42px;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            position:relative;
            letter-spacing:16px;
            margin-bottom:37px;
            &:before{
              content:"";
              display:block;
              width:143px;
              height:167px;
              background:url(../img/slide_logo.png) no-repeat top left;
              background-size:143px auto;
              position:absolute;
              left:-66px;
              top:-66px;

            }
          }
          p{
            &+p{
              margin-top:20px;
            }
          }
          .showBox_sp{
            ul{
              margin:35px 0;
              li{
                width:266px;
                float:left;
                &+li{
                  margin-left:1px;
                }
              }
            }
            figure{
              margin:35px 0;
            }
          }
        }
      }
      #ContBox02{
        .messageBox{
          border:1px solid #e5e5e5;
          border-radius:6px;
          padding:60px 60px 0;
          p+p{
            margin-top:20px;
          }
          .clearfix{
            padding-bottom:50px;
            &+.clearfix{
              border-top:1px solid #f1f1f1;
              padding-top:60px;
            }
            .staffName{
              width:312px;
              float:left;
              position:relative;
              img{
                width:100%;
                height:auto;
              }
              dl{
                position:absolute;
                left:0;
                bottom:9px;
                font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                font-weight: 400;
                dt{
                  font-size:14px;
                  color:#888;
                }
                dd{
                  font-size:28px;
                  span{
                    color:#b58b32;
                    font-family: 'Roboto Slab', serif;
                    font-weight: 300;
                    font-size:14px;
                    padding-left:8px;
                  }
                }
              }
            }
            .showBox_sp{
              float:right;
              width:540px;
            }
          }
        }
      }
      #ContBox03{
        .galleryBox{
          border:1px solid #e5e5e5;
          border-radius:6px;
          padding:60px;
          .gallerySlider{
            img{
              width:100%;
              height:auto;
            }
          }
          .bx-wrapper{
            position:relative;
            .bx-prev,.bx-next{
            	display:block;
              position:absolute;
              top:40%;
              margin:auto;
              width:60px;
              height:60px;
              text-indent:-9999px;
              text-decoration:none;
              border-radius:50%;
              background-color:#fff;
              border:1px solid #f0e8c8;
              box-shadow:0px 0px 10px 0px rgba(0,0,0,0.05);
              background-repeat:no-repeat;
              background-size:9px auto;
              background-position:center center;
              &:hover{
                border:1px solid #e8d492;
                box-shadow:0px 0px 20px 0px rgba(0,0,0,0.1);
              }
            }
            .bx-prev{
              background-image:url(../img/arrow03.png);
              left:-30px;
            }
            .bx-next{
              background-image:url(../img/arrow04.png);
              right:-30px;
            }
            .bx-pager{
              margin-top:30px;
              text-align:center;
              .bx-pager-item{
                display:inline-block;
                vertical-align:top;
                width:12px;
                height:12px;
                &+.bx-pager-item{
                  margin-left:18px;
                }
                a{
                  display:block;
                  width:100%;
                  height:100%;
                  border-radius:50%;
                  text-indent:-9999px;
                  background-color:#dadada;
                  &.active{
                    background-color:#dccb87;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  施設案内
  -------------------------------------*/
  #PageAbout.pageClinicIndex {
    #Main{
    	margin-bottom:166px;
      h3{
        margin:85px 0 50px;
      }
      img{
        width:100%;
        height:auto;
      }
      .facilityBlock{
        .facilityBox{
          margin-bottom:45px;
          position:relative;
          &+.facilityBox{
            padding-top:45px;
            border-top:1px solid #f1f1f1;
          }
          figure{
            width:595px;
            float:left;
          }
          div{
            width:375px;
            float:right;
            padding-top:52px;
            img{
              width:250px;
              position:absolute;
              bottom:0;
              left:625px;
            }
          }
          &:nth-of-type(odd){
            div{
              padding-left:30px;
            }
          }
          &:nth-of-type(even){
            figure{
              float:right;
            }
            div{
              float:left;
              p{
                padding-right:30px;
              }
              img{
                left:125px;
              }
            }
          }
          &:last-of-type{
            div{
              padding-top:22px;
            }
          }
        }
      }
    }
  }

  /*
  採用情報
  -------------------------------------*/
  #PageAbout.pageRecruitIndex {
    #Main{
      margin-bottom: 130px;
      .contBox{
        h3{
          margin-bottom:48px;
        }
        margin:80px 0 50px;
      }
      #ContBox01{
        #ContSubBox01{
          overflow-x: hidden;
          figure{
            width: 1280px;
            height:600px;
            background:url(../img/contents/recruit_main.jpg) no-repeat center center;
            background-size:cover;
            margin: 0 auto;
          }
          .innerBasic{
            position:relative;
            //margin-top:-100px;
            background:#fff;
            //padding-top:80px;
            text-align:center;
            h4{
              font-size:42px;
              color:#ac832d;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              letter-spacing:15px;
              margin:40px 0;
            }
            p{
            	text-align:center;
            	letter-spacing: 1.2px;
              &+p{
                margin-top:20px;
              }
            }
          }
        }
        #ContSubBox02{
        	margin-top:80px;
          height:770px;
          position: relative;
          .bg_pc {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            &_inner {
              width: 1280px;
              height: 100%;
              background:url(../img/contents/recruit_takazawa.jpg) no-repeat center center;
              background-size:cover;
              margin: 0 auto;
            }
          }
          .innerBasic{
            position:relative;
            height:100%;
          }
          .showBox_sp{
            width:500px;
            margin-left:auto;
            position:absolute;
            top: 50%;
            transform: translateY(-50%);
            right:0;
            p{
              span.disp_pc{
                display:inline;
              }
              &:first-of-type{
                margin-bottom:22px;
              }
              &+p{
                margin-top:20px;
              }
            }
          }
        }
      }
      #ContBox02{
        .div_border{
          border:1px solid #e5e5e5;
          border-radius:6px;
          padding:60px 60px 0;
          .clearfix{
            position:relative;
            padding-left:340px;
            margin-bottom:50px;
            &+.clearfix{
              border-top:1px solid#f1f1f1;
              padding-top:60px;
            }
            .nameImg{
              position:relative;
              figure{
                width:280px;
                position:absolute;
                top:0;
                left:-340px;
                img{
                  width:100%;
                  height:auto;
                }
              }
              dl{
                font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                font-weight: 400;
                margin-bottom:20px;
                dt{
                  color:#888;
                  font-size:14px;
                }
                dd{
                  font-size:28px;
                  span{
                    font-size:14px;
                    color:#b58b32;
                    font-family: "Roboto Slab", serif;
                    font-weight: 300;
                    padding-left:8px;
                  }
                }
              }
            }
            p+p{
              margin-top:20px;
            }
          }
        }
      }
      #ContBox03{
        table{
          border:1px solid #e5e5e5;
          width:100%;
          margin-bottom:60px;
          th,td{
            border:1px solid #e5e5e5;
            font-size:16px;
            line-height:32px;
            padding:20px 15px;
          }
          th{
            background-color:#f5f5f5;
            width:220px;
          }
        }
        .linkBtn{
          margin:90px auto 0;
          width:800px;
          height:100px;
          line-height:100px;
          a{
            color:#c3a541;
            font-size:24px;
            background:url(../img/arrow04.png) no-repeat center right 30px;
            background-size:11px auto;
            &:before{
              content:"";
              display:inline-block;
              width:40px;
              height:100px;
              vertical-align:top;
              background:url(../img/icon_form.png) no-repeat center left;
              background-size:40px auto;
              margin-right:30px;
            }
          }
        }
      }
    }
  }

  /*
  採用応募フォーム
  -------------------------------------*/
  #PageAbout.pageRecruitEntryIndex,
  #PageAbout.pageRecruitEntryConfirm,
  #PageAbout.pageRecruitEntryThanks {
    #Main{
      h3{
        margin-bottom:42px;
        span{
          display:block;
          font-size:20px;
        }
      }
      #ContBox01{
        >p{
          text-align:center;
        }
      }
      form {
        margin-top: 50px;
        dl{
          border-top: 1px solid #f1f1f1;
          display:table;
          width:100%;
          &:last-of-type{
            border-bottom: 1px solid #f1f1f1;
          }
          dt,dd{
            display: table-cell;
            vertical-align: top;
            font-size:16px;
            padding-top: 30px;
          }
          dt{
            width: 200px;
            line-height:20px;
          }
          dd{
            &:first-of-type {
            	width:50px;
              span{
                width: 40px;
                display: inline-block;
                vertical-align: top;
                color: #fefefe;
                font-size: 12px;
                background-color: #e42f2f;
                text-align: center;
                height: 20px;
                line-height: 20px;
              }
            }
            &:last-of-type{
              padding:15px 0;
              input,textarea,select{
              	width:100%;
                background-color: #eeeeee;
                border-radius: 5px;
                height: 50px;
                padding-left:18px;
                &:focus{
                  border:1px solid #e8d492;
                  background-color:#fff;
                }
                &.calendar{
                  background-image:url(../img/icon_calendar.png);
                  background-repeat:no-repeat;
                  background-size:20px auto;
                  background-position:center right 15px;
                }
              }
              textarea{
                height:200px;
                padding-top:10px;
                resize: vertical;
              }
              select{
                background-image:url(../img/arrow07.png);
                background-repeat:no-repeat;
                background-size:11px auto;
                background-position:center right 15px;
              }
              select::-ms-expand {
                display: none;
              }
              .radio{
                span{
                  width: 39px;
                  height: 39px;
                  margin-right: 5px;
                  background: transparent url(../img/radio02.png) no-repeat scroll left center;
                  background-size:39px auto;
                  line-height: 24px;
                  &.checked{
                    background: transparent url(../img/radio01.png) no-repeat scroll left center;
                    background-size:39px auto;
                  }
                  input{
                    width:39px;
                    height:39px;
                  }
                }
              }
              label{
                &+.radio{
                  margin-left: 24px;
                }
                &.comment{
                  padding-left:22px;
                }
              }
              .comment{
                font-size:14px;
                color:#888;
              }
              input:-webkit-input-placeholder{
                color: #888;
              }
              input::-webkit-input-placeholder{
                color: #888;
              }
              input:-ms-input-placeholder{
                color: #888;
              }
              textarea:-webkit-input-placeholder{
                color: #888;
              }
              textarea::-webkit-input-placeholder{
                color: #888;
              }
              textarea:-ms-input-placeholder{
                color: #888;
              }
              #text1,#text2,#text7{
                width:300px;
              }
              #text4,#text10{
                width:170px;
              }
              #text5,#text6{
                width:500px;
              }
              #text8,#text11{
                width:180px;
              }
              #text8{
                margin-bottom:10px;
              }
              #text6+.comment{
              	display:block;
              	margin-top:5px;
              }
            }
          }
          &:nth-of-type(9){
            dd{
              &:last-of-type{
                padding:25px 0;
              }
            }
          }
        }
        ul {
          margin: 60px auto 151px;
          li {
            &.confirm{
              input {
                margin: 0 auto;
                width: 340px;
                height: 80px;
                background-color: #d1ae39;
                border-radius: 7px;
                display: block;
                background: transparent url(../img/arrow05.png) no-repeat center right 32px;
                background-size: 11px auto;
                line-height: 80px;
                text-align: center;
                font-size: 20px;
                color: #fff;
                letter-spacing: 2px;
                transition: 0.4s;
                &:hover{
                  background-color:#e2b623;
                  box-shadow: 0 4px 6px 0 rgba(100, 81, 35, 0.2);
                }
              }
            }
            &.reset{
              input {
                margin:30px auto 0;
                width: 150px;
                height: 40px;
                background-color: #fff;
                border:1px solid #e5e5e5;
                border-radius: 7px;
                display: block;
                text-align: center;
                font-size: 14px;
                color: #888;
                letter-spacing: 2px;
                transition: 0.4s;
                &:hover{
                  border:1px solid #cfcfcf;
                  box-shadow: 0 4px 6px 0 rgba(100, 81, 35, 0.1);
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  採用応募フォーム（入力内容のご確認）
  -------------------------------------*/
  #PageAbout.pageRecruitEntryConfirm {
    #Main{
      form{
        dl{
          dt,dd,dd:last-of-type{
            padding:27px 0;
          }
          &:nth-of-type(8){
            dd{
              &:last-of-type{
                vertical-align:middle;
              }
            }
          }
        }
      }
    }
  }

  /*
  採用応募フォーム（送信完了）
  -------------------------------------*/
  #PageAbout.pageRecruitEntryThanks {
    #Main{
      p{
        &+p{
          margin-top:20px;
        }
      }
      .linkBtn{
      	margin:50px auto 0;
        width:250px;
        height:50px;
        line-height:50px;
        a{
          font-size:16px;
        }
      }
    }
  }

  /*
  メディアの皆様へ
  -------------------------------------*/
  #PageMedia.pageMediaIndex {
    #Main{
      margin-bottom:155px;
      h3{
        margin:83px 0 42px;
      }
      #ContBox01{
        .innerBasic{
          p{
            text-align:center;
            letter-spacing: 1.5px;
            &+p{
              margin-top:20px;
            }
          }
          p.txtCap{
            line-height: 1.2;
            margin-top: 25px;
            span{
              color:#888;
              font-size: 14px;
            }
          }
        }
      }
      #ContBox02 {
        width: 1000px;
        margin: 0 auto;
        margin-top: 81px;
        p {
          text-align: center;
          margin-top: 42px;
          letter-spacing: 1.5px;
          a {
          }
          span {
            display: block;
          }
        }
        form {
          margin-top: 50px;
          >dl {  //共通設定
            border-top: 1px solid #f1f1f1;
            padding-bottom: 11px;
            font-size: 0;
            letter-spacing: normal;

            dd.any{
              display: inline-block;
              margin-top: 30px;
              width: 40px;
              height: 20px;
              background-color: #b8b8b8;
              line-height: 20px;
              text-align: center;
              vertical-align: top;
              font-size: 12px;
              color: #fefefe;
            }
            dd.require {
              width: 40px;
              display: inline-block;
              vertical-align: top;
              color: #fefefe;
              font-size: 12px;
              background-color: #e42f2f;
              text-align: center;
              height: 20px;
              line-height: 20px;
              margin-top: 30px;
            }
            dt {
              width: 200px;
              display: inline-block;
              vertical-align: top;
              margin-top: 30px;
              line-height: 20px;
              font-size: 16px;
              color: #222222;
              padding-left: 10px;
              letter-spacing: 2px;
            }
            dd:nth-of-type(2) {
              display: inline-block;
              font-size: 16px;
              color: #222;
              width: 750px;
              padding-top: 18px;
              padding-left: 10px;
              >div.check {
                display: inline-block;
                margin-right:24px;
                &:last-of-type{
                  margin-right:0;
                }
                label {
                  margin-left: 5px;
                  letter-spacing: 1.5px;
                  vertical-align: middle;
                }
                span {
                  width: 38px;
                  height: 38px;
                  background: transparent url(../img/checkbox02.png) no-repeat scroll left center;
                  background-size: 38px 38px;
                  input {
                    font-size: 16px;
                    width: 38px;
                    height: 38px;
                  }
                }
                span.checked {
                  background: transparent url(../img/checkbox01.png) no-repeat scroll left center;
                  background-size: 38px 38px;
                }
              }
              >div.cautionText {

              }
              >input {
                font-size: 16px;
                border-radius: 5px;
                width: 300px;
                height: 50px;
                background-color: #eee;
                font-size: 16px;
                color: #222;
                padding-left: 17px;
                letter-spacing: 1.5px;
                &:focus{
                  border: 1px solid #e8d492;
                  background-color: #fff;
                }
              }
              >span {
                display:block;
                margin-top: 11px;
                margin-bottom: 12px;
                line-height: 14px;
                letter-spacing: 1.5px;
                font-size: 14px;
                color: #888;
              }
              input:-webkit-input-placeholder{
                color: #888;
              }
              input::-webkit-input-placeholder{
                color: #888;
              }
              input:-ms-input-placeholder{
                color: #888;
              }
              textarea:-webkit-input-placeholder{
                color: #888;
              }
              textarea::-webkit-input-placeholder{
                color: #888;
              }
              textarea:-ms-input-placeholder{
                color: #888;
              }
            }
            &:first-of-type {  //媒体の種類
            }
            &:nth-of-type(2),  //媒体名
            &:nth-of-type(3),  //媒体URL
            &:nth-of-type(4),  //御社名
            &:nth-of-type(9) {  //メールアドレス
              dd {
                &:nth-of-type(2) {
                  input {
                    width: 500px;
                  }
                }
              }
            }
            &:nth-of-type(10) {  //確認用メールアドレス
              dd:nth-of-type(2) {
                input {
                  width: 500px;
                }
                span {
                  display: block;
                  margin-top: 9px;
                  margin-bottom: 6px;
                  font-size: 14px;
                  color: #888888;
                  letter-spacing: 1.5px;
                }
              }
            }
            &:nth-of-type(11) {  //電話番号
            }
            &:last-of-type {  //取材内容
              border-bottom: 1px solid #f1f1f1;
              padding-bottom: 6px;
              >dd {
                &:nth-of-type(2) {
                  width: 750px;
                  textarea {
                    border-radius: 5px;
                    padding-top: 13px;
                    padding-left: 16px;
                    width: 750px;
                    height: 200px;
                    background-color: #eee;
                    letter-spacing: 1.5px;
                    font-size: 16px;
                    color: #222;
                    resize: none;
                    &:focus{
                      border: 1px solid #e8d492;
                      background-color: #fff;
                    }
                  }
                }
              }
            }
          }
          dl:first-of-type{
            dd:nth-of-type(2) {
              padding-top: 20px;
            }
          }
          p.parent_agree {
            color: #e42f2f;
            font-size: 14px;
            line-height: 24px;
            margin-top: 25px;
            span {
              a {
                color: #e42f2f;
                position: relative;
                &:after {
                  content: "";
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  background: url(../img/img_pdf.png) no-repeat;
                  background-position: right center;
                  background-size: 20px auto;
                  position: absolute;
                  top: 0px;
                  right: -24px;
                }
              }
            }
          }
          p.minorLink{
            margin:25px 0;
            text-align: right;
            a{
              font-size: 16px;
              background: url(../img/icon_link.png) no-repeat right center;
              background-size: 15px auto;
              padding-right: 24px;
            }
          }
          >div.confirmBtn {
            ul {
              margin: 55px auto 120px;
              width: 340px;
              height: 80px;
              li {
                width: 100%;
                width: 100%;
                input {
                  border-radius: 7px;
                  background-color: #d1ae39;
                  display: block;
                  width: 100%;
                  height: 100%;
                  background: transparent url(../img/arrow05.png) no-repeat center right 32px;
                  background-size: 11px auto;
                  line-height: 80px;
                  text-align: center;
                  letter-spacing: 2px;
                  font-size: 20px;
                  color: #fff;
                  margin:0 auto;
                  transition: .4s;
                  &:hover{
                    box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .2);
                    background-color: #e2b623;
                  }
                }
              }
            }
          }
        }
      }

    }
  }
  /*
  取材申し込みフォーム（入力内容のご確認）
  -------------------------------------*/
  #PageMedia.pageMediaConfirm {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            margin-top: 58px;
            margin-bottom: 150px;
            h3 {
              font-size: 32px;
              margin-bottom: 59px;
              span {
                display: block;
                font-size: 20px;
                margin-top: 4px;
                letter-spacing: 4px;
              }
            }
            p {
              font-size: 16px;
              text-align: center;
              letter-spacing: 1.5px;
              span {
                display: block;
              }
            }
            form{
              >dl {  //全体設定
                border-top: 1px solid #f1f1f1;
                padding-bottom: 22px;
                font-size: 0;
                letter-spacing: normal;
                >dt {
                  width: 200px;
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 30px;
                  font-size: 16px;
                  color: #222222;
                  padding-left: 10px;
                  letter-spacing: 2px;
                }
                dd.require {
                  width: 40px;
                  display: inline-block;
                  vertical-align: top;
                  color: #fefefe;
                  font-size: 12px;
                  background-color: #e42f2f;
                  text-align: center;
                  height: 20px;
                  line-height: 20px;
                  margin-top: 30px;
                }
                dd.any{
                  display: inline-block;
                  margin-top: 30px;
                  width: 40px;
                  height: 20px;
                  background-color: #b8b8b8;
                  line-height: 20px;
                  text-align: center;
                  vertical-align: top;
                  font-size: 12px;
                  color: #fefefe;
                }
                >dd {
                  &:nth-of-type(2) {
                    display: inline-block;
                    font-size: 16px;
                    color: #222;
                    width: 750px;
                    padding-top: 24px;
                    padding-left: 10px;
                    letter-spacing: 1.5px;
                    line-height: 33px;
                  }
                }
                &:first-of-type {  //媒体の種類
                  margin-top: 50px;
                }

                &:last-of-type {  //取材内容
                  border-bottom: 1px solid #f1f1f1;
                }
              }
              >div {
                >ul.sendBtn {  //送信ボタン
                  margin: 72px auto 30px;
                  width: 340px;
                  height: 80px;
                  >li {
                    width: 100%;
                    input {
                      display: block;
                      background-color: #d1ae39;
                      border-radius: 7px;
                      width: 100%;
                      height: 100%;
                      background: transparent url(../img/arrow05.png) no-repeat center right 32px;
                      background-size: 11px auto;
                      line-height: 80px;
                      text-align: center;
                      letter-spacing: 2px;
                      font-size: 20px;
                      color: #fff;
                      transition: .4s;
                      &:hover{
                        box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .2);
                        background-color: #e2b623;
                      }
                    }
                  }
                }
              }
              >ul.backBtn {  //戻るボタン
                >li {
                  margin: 0 auto;
                  width: 150px;
                  height: 40px;
                  >input {
                    display: block;
                    border: 1px solid #e5e5e5;
                    border-radius: 7px;
                    width: 100%;
                    height: 100%;
                    line-height: 40px;
                    text-align: center;
                    text-decoration: none;
                    font-size: 14px;
                    color: #888;
                    transition:0.4s;
                    &:hover{
                      box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .1);
                      border: 1px solid #cfcfcf;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*
  取材申し込みフォーム（送信完了）
  -------------------------------------*/
  #PageMedia.pageMediaThanks {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            h3 {
              margin-bottom: 57px;
              span {
                display: block;
                font-size: 20px;
                margin-top: 7px;
                letter-spacing: 4px;
              }
            }
            p {
              font-size: 16px;
              text-align: center;
              letter-spacing: 1.5px;
              &:nth-of-type(2) {
                margin-top: 22px;
                span {
                  display: block;
                }
              }
            }
            ul {
              margin-top: 50px;
              li {
                margin: 0 auto;
                width: 250px;
                height: 50px;
                text-align: center;
                a {
                  display: block;
                  width: 100%;
                  height: 100%;
                  line-height: 50px;
                  text-decoration: none;
                  font-size: 16px;
                  color: #c5a22b;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  施術方法
  -------------------------------------*/
  #PageTreatment.pageIndex {
    #Main{
      margin-bottom:130px;
      h3{
        margin-bottom: 50px;
        span{
          color:#ac832d;
          display:block;
          font-size:20px;
          font-family: "Roboto Slab", serif;
          font-weight: 300;
          letter-spacing:2px;
        }
      }
      #ContBox01{
        margin-top: 82px;
      }
      #ContBox02{
        padding:0 30px;
        max-width:1280px;
        margin:88px auto 0;
        .contSubBox{
          border-top:1px solid #f1f1f1;
          &:last-of-type{
            border-bottom:1px solid #f1f1f1;
          }
          dl{
            display:table;
            width:100%;
            padding:15px 0;
            table-layout:fixed;
            dt,dd{
              display:table-cell;
              vertical-align:top;
            }
            dt{
              border-right:1px solid #e7d48a;
              width:250px;
              color:#ac832d;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              padding-top:15px;
              font-size:18px;
            }
            dd{
              padding-left:10px;
              padding-bottom:10px;
              ul{
                position: relative;
                &:before{
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: block;
                  width: 15px;
                  height: 100%;
                  background-color: #fff;
                }
                li{
                  display:inline-block;
                  margin:8px 0 0;
                  border-left: 1px solid #dbdbdb;
                  a{
                    font-size:16px;
                    color:#888;
                    padding:4px 15px;
                    text-decoration:none;
                    display:block;
                    &:hover{
                      color:#c5a22b;
                    }
                  }
                }
              }
            }
          }
        }
      }
      #ContBox03{
        h3{
          margin-bottom: -9px;
        }
        h4{
          font-size: 32px;
          font-family: "Roboto Slab", serif;
          font-weight: 300;
          color: #ac832d;
          text-align: center;
          letter-spacing: 0.2em;
        }
        .note {
          margin-top: 10px;
          text-align: center;
          letter-spacing: 3px;
          color: #888;
        }
        .topCatBlock{
          margin-top:20px;
          overflow:hidden;
          .catBox{
            position:relative;
            width:485px;
            float:left;
            margin-top:30px;
            &:nth-of-type(even){
              margin-right:30px;
            }
            .rankIcon{
              position:absolute;
              top:18px;
              left:18px;
              width:45px;
              height:45px;
              z-index:2;
              display:block;
              background-color:#f5f5f5;
              border-radius:50%;
              text-align:center;
              line-height:45px;
              font-family: "Roboto Slab", serif;
              font-size: 18px;
              font-weight: 300;
              color:#aaa;
              &.rankIcon01{
                top:40px;
                left:40px;
                width:60px;
                height:60px;
                line-height:60px;
                font-size:24px;
                color:#fff;
                background: linear-gradient(45deg, #b58b32 0%,#dabe68 36%,#ebd896 67%,#cfb054 100%);
              }
              &.rankIcon02{
                color:#fff;
                background: linear-gradient(45deg, #818181 0%,#adaeae 36%,#e7e7e8 67%,#b7b8b7 100%);
              }
              &.rankIcon03{
                color:#fff;
                background: linear-gradient(45deg, #b87d37 0%,#d5a46a 36%,#e8c7a1 67%,#deaa6d 100%);
              }
            }
          }
          .topCatBox{
            width:100%;
            float:none;
          }
        }
      }
    }
  }

  /*
  施術方法（カテゴリ）
  -------------------------------------*/
  #PageTreatment.pageCategory {
    #Main{
      margin-bottom:160px;
      #ContBox01{
        .titelTxt{
          color:#888;
          text-align:center;
          font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
          font-size: 24px;
          font-weight: 400;
          margin:45px 0;
          letter-spacing:4.5px;
        }
        .imgTxt{
          display:table;
          width:100%;
          table-layout:fixed;
          margin-bottom:45px;
          figure,p{
            display:table-cell;
            vertical-align:top;
          }
          figure{
            width:345px;
            padding-right:25px;
            img{
              width:100%;
              height:auto;
            }
          }
        }
        .pointBox{
          border:4px solid #f1f1f1;
          padding:35px 54px;
          h4{
            font-weight:700;
            color:#ac832d;
            font-size:16px;
            border-bottom:1px solid #e7d48a;
            padding-bottom:10px;
            margin-bottom:25px;
          }
          .showBox_sp{
            font-size:16px;
            line-height:32px;
          }
          p{
            &+p{
              margin-top:20px;
            }
          }
        }

        .contSubBox{
          margin-top:90px;
          h4{
            border-top:1px solid #e7d48a;
            border-bottom:1px solid #e7d48a;
            color:#ac832d;
            font-size:28px;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            text-align:center;
            padding:25px;
          }
          .txtWrap{
            background:#f9f8f5;
            padding:25px 0;
            position:relative;
            margin-bottom:30px;
            &:after{
              content:"";
              display:block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 22px 15px 0 15px;
              border-color: #f9f8f5 transparent transparent transparent;
              position:absolute;
              bottom:-22px;
              left:0;
              right:0;
              margin:auto;
            }
            p{
              width:1000px;
              margin:0 auto;
              color:#888;
            }
          }
          .showBox_sp{
            font-size:0;
            letter-spacing:normal;
          }
          .catBox{
            width:485px;
            display:inline-block;
            vertical-align:top;
            margin-top:30px;
            &:nth-of-type(2n){
              margin-left:30px;
            }
          }
        }
      }
    }
  }

  /*
  施術方法（詳細）
  -------------------------------------*/
  #PageTreatment.pageEntry,
  #PageCase.pageEntry {
    #Main{
      margin-bottom:160px;
      #ContBox01{
        .mainContTitle{
          display:table;
          width:100%;
          table-layout:fixed;
          margin-bottom:45px;
          div{
            display:table-cell;
            vertical-align:top;
          }
          figure{
            display:table-cell;
            vertical-align:top;
            width:320px;
            img{
              width:100%;
              height:auto;
            }
          }
          h3{
            font-size:32px;
            line-height:40px;
            font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
            font-weight: 500;
            border-bottom:2px solid #f1f1f1;
            padding:25px 30px 25px 0;
            text-align:left;
          }
          h4{
            font-size:20px;
            line-height:30px;
            color:#888;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            margin:28px 30px 15px 0;
            letter-spacing:4px;
          }
          p{
            padding-right:30px;
          }
        }
        .underLink{
          ul{
            width:1000px;
            margin:0 auto;
          }
        }
        .contSubBox{
          margin-top:60px;
          border:1px solid #e5e5e5;
          border-radius:6px;
          padding:45px 60px 60px;
        }
        #FlowBox{
          ol{
            list-style:none;
            li{
              padding-bottom:30px;
              display:table;
              width:100%;
              table-layout:fixed;
              &+li{
                border-top:1px solid #f1f1f1;
                padding-top:30px;
              }
              &:last-of-type{
                padding-bottom:0;
              }
              dl{
                display:table-cell;
                vertical-align:top;
                dt{
                  color:#ac832d;
                  font-size:18px;
                  margin-bottom:2px;
                  span{
                    font-size:32px;
                    font-family: 'Roboto Slab', serif;
                    font-weight: 300;
                    font-style:italic;
                    padding-right:18px;
                  }
                }
                dd{
                  font-size:16px;
                  line-height:32px;
                }
              }
              div{
                display:table-cell;
                vertical-align:top;
                width:345px;
                padding-left:25px;
                img{
                  width:100%;
                  height:auto;
                  &+img{
                    margin-top:15px;
                  }
                }
              }
            }
          }
        }
        #AftercareBox{
          table{
            border:1px solid #e5e5e5;
            width:100%;
            td,th{
              border:1px solid #e5e5e5;
              padding:20px 15px;
              font-size:16px;
              line-height:32px;
            }
            th{
              background-color:#f5f5f5;
              width:220px;
              font-weight:500;
            }
          }
          ul{
            margin-top:20px;
            li{
              font-size:14px;
              line-height:28px;
              color:#888;
              padding-left: 2.4em;
              text-indent: -2.4em;
              span{
                padding-right:10px;
              }
            }
          }
        }
        #RecommendBox{
          ul{
            li{
              font-size:18px;
              line-height:36px;
              font-weight:700;
              background:url(../img/icon_check.png) no-repeat left top 2px;
              background-size:34px auto;
              padding-left:48px;
              &+li{
                margin-top:20px;
              }
            }
          }
        }
        #CaseBox{
          .caseList{
            font-size: 0;
            letter-spacing: normal;
            dl{
              width:410px;
              display: inline-block;
              vertical-align: top;
              //float:left;
              &+dl{
                //margin-left:58px;
              }
              &:nth-of-type(2n){
                margin-left:58px;
              }
              &:nth-of-type(n+3){
                margin-top:45px;
              }
              dt{
                font-size:16px;
                color:#fff;
                text-align:center;
                background-color:#cfad64;
                margin-bottom:30px;
                padding: 3px 10px;
              }
              dd{
                &.img{
                  &:hover{
                    cursor : pointer;
                  }

                  ul{
                    li{
                      width:190px;
                      float:left;
                      img{
                        width:100%;
                        height:auto;
                      }
                      p{
                        margin-top:10px;
                        text-align:center;
                      }
                      &+li{
                        margin-left:30px;
                        div{
                          position:relative;
                          &:before{
                            content:"";
                            display:block;
                            position:absolute;
                            top:0;
                            bottom:0;
                            margin:auto;
                            left:-20px;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 6px 0 6px 10px;
                            border-color: transparent transparent transparent #dccb87;
                          }
                        }
                      }
                    }
                  }
                }
                &.doctor{
                  margin-top:20px;
                  background-color:#f5f5f5;
                  padding:15px;
                  font-size:0;
                  letter-spacing:normal;
                  figure{
                    width:70px;
                    height:70px;
                    display:inline-block;
                    vertical-align:middle;
                    border-radius:50%;
                    overflow:hidden;
                    img{
                      width:100%;
                      height:auto;
                    }
                  }
                  ul{
                    display:inline-block;
                    vertical-align:middle;
                    width:310px;
                    padding-left:15px;
                    li{
                      font-size:14px;
                      line-height:28px;
                      color:#888;
                      letter-spacing:1.2px;
                    }
                  }
                }
                &.linkBtn{
                  margin-top:30px;
                  width: 100%;
                  height: 50px;
                  line-height: 50px;
                  a{
                    background: transparent url(../img/icon_link.png) no-repeat center right 20px;
                    background-size: 15px auto;
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
        #VoiceBox{
          .note{
            font-size:14px;
            color:#888;
            text-align:right;
          }
          >h4{
            margin: 10px 0 25px;
            border: 1px solid #cfad64;
            padding: 13px 19px;
            background-color: #cfad64 ;
            font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif!important;
            font-size: 19px;
            font-weight: bold;
            color: #fff ;
          }
        }
        #PriceBox{
          h4{
            font-size:20px;
            line-height:32px;
            color:#fff;
            padding:7px 15px;
            background-color:#cfad64;
            font-weight:700;
          }
          table{
            width:100%;
            border:1px solid #e5e5e5;
            td,th{
              border:1px solid #e5e5e5;
              font-size:16px;
              line-height:32px;
              padding:10px 15px;
            }
            th{
              color:#ac832d;
              background-color:#f5f4ed;
              width:50%;
            }
            td{
              text-align:center;
              font-weight:700;
              vertical-align:middle;
            }
          }
          .option{
            h4{
              background-color:#c4c4c4;
              color:#fff;
            }
            table{
              th{
                background-color:#f5f5f5;
                color:#222;
                font-weight:500;
              }
              td{
                font-weight:500;
              }
            }
          }
          .underCommonGuide{
            width: 100%;
            margin-top: 30px;
          }
        }
        #FaqBox{
          dl.faqList{
            display:block;
            position:relative;
            border-bottom:1px solid #f1f1f1;
            padding:30px 0;
            &:hover{
              cursor:pointer;
              transition: .4s;
              dt.faqTitle{
	              color: #c3a541;
              }
            }
            dt{
              font-size: 0;
              letter-spacing: normal;
              transition: .4s;
              div{
                display: inline-block;
                vertical-align: top;
              }
              span{
                display: inline-block;
                vertical-align: top;
                border-radius: 50%;
                width: 45px;
                height: 45px;
                text-align: center;
                font-family: "Roboto Slab", serif;
                font-size: 18px;
                font-weight: 300;
                background: linear-gradient(45deg, #b58b32 0%, #dabe68 36%, #ebd896 67%, #cfb054 100%);
                line-height: 45px;
                color: #fff;
                margin-right:15px;
              }
              p{
                width:750px;
                display: inline-block;
                vertical-align: top;
                font-size: 20px;
                line-height: 40px;
                vertical-align: top;
                letter-spacing: 0.1em;
              }
              a{
                color:#222;
                text-decoration: none;
              }
              line-height: 40px;
              color:#222222;
            }
            dt:after{
               content: "";
               position: absolute;
               top: 37px;
               right: 20px;
               display: block;
               width: 30px;
               height: 30px;
               background: url(../img/icon_minus.png) no-repeat center center/cover;
               background-size:30px 2px;
            }
            &.btnOff{
              dt:after{
                 content: "";
                 position: absolute;
                 top: 37px;
                 right: 20px;
                 display: block;
                 width: 30px;
                 height: 30px;
                 background: url(../img/icon_plus_pc.png) no-repeat center top/cover;
                 background-size:30px 30px;
              }
              dd{
                display: none;
              }
            }
            dd.faqAns{
              margin-top:5px;
              //letter-spacing:1.5px;
              transition:0.4s;
              font-size: 0;
              letter-spacing: normal;
              line-height: 28px;
              color:#222;
              div{
                display: inline-block;
                vertical-align: top;
              }
              span{
                display: inline-block;
                border-radius: 50%;
                width: 45px;
                height: 45px;
                background-color: #f5f5f5;
                line-height: 45px;
                text-align: center;
                font-family: "Roboto Slab", serif;
                font-size: 18px;
                font-weight: 300;
                background: linear-gradient(45deg, #818181 0%, #adaeae 36%, #e7e7e8 67%, #b7b8b7 100%);
                color: #fff;
                margin-left:30px;
                margin-right:15px;
              }
              p.txt{
                width:calc(100% - 90px);
                display: inline-block;
                line-height: 28px;
                font-size:16px;
                margin-top: 8px;
                letter-spacing: 0.1em;
              }
            }
          }
          dl:first-of-type{
            border-top:1px solid #f1f1f1;
          }
        }


        .voiceBlock{
          &+.voiceBlock{
            margin-top:30px;
          }
          dl{
            float:right;
            width:140px;
            dd{
              width:140px;
              height:140px;
              border-radius:50%;
              overflow:hidden;
              img{
                width:100%;
                height:auto;
              }
            }
            dt{
              font-size:14px;
              color:#888;
              text-align:center;
              margin-top:10px;
            }
          }
          div{
            float:left;
            width:690px;
            border:4px solid #f1f1f1;
            padding:0 25px 10px;
            position:relative;
            &:after{
              content:"";
              display:block;
              background:url(../img/fukidashi01.png) no-repeat top left;
              background-size:26px auto;
              width:26px;
              height:26px;
              position:absolute;
              top:55px;
              right:-26px;
            }
          }
        }
      }
      .contBoxLink{
        margin-top:80px;
        h3{
          margin-bottom:35px;
        }
        .linkBtn{
          width:250px;
          height:50px;
          line-height:50px;
          margin:45px auto 0;
          a{
            font-size:16px;
          }
        }
      }
    }
  }

  /*
  料金一覧 共通
  -------------------------------------*/
  #PagePrice{
    #Main{
      margin-bottom:150px;
      #PriceNavi{
        border-top:1px solid #f1f1f1;
        border-bottom:1px solid #f1f1f1;
        padding:15px 0;
        dl{
          display:table;
          width:100%;
          table-layout:fixed;
          dt,dd{
            display:table-cell;
            vertical-align:top;
          }
          dt{
            width:138px;
            border-right:1px solid #e7d48a;
            font-size:18px;
            color:#ac832d;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            padding-top:10px;
          }
          dd{
            padding-left:10px;
            padding-bottom: 6px;
            ul{
              position:relative;
              &:before{
                content:"";
                display:block;
                width:15px;
                height:100%;
                background-color:#fff;
                position:absolute;
                top:0;
                left:0;
              }
              li{
                font-size:16px;
                display:inline-block;
                border-left:1px solid #dbdbdb;
                margin-top:8px;
                a{
                  color:#888;
                  text-decoration:none;
                  padding:5px 15px;
                  display:block;
                  &:hover{
                    color:#c5a22b;
                  }
                }
                &.current{
                  a{
                    color:#ac832d;
                  }
                }
              }
            }
          }
        }
      }
      h3{
        margin:80px 0 50px;
      }
      .priceBlock{
        border:1px solid #e5e5e5;
        border-radius:6px;
        padding:15px 60px 15px;
        .priceInnerBox{
          padding-top:45px;
          padding-bottom:45px;
          position:relative;
          min-height: 274px;
          &+.priceInnerBox{
            border-top:1px solid #f1f1f1;
          }
          h4{
            width:680px;
            font-size:20px;
            line-height:32px;
            color:#fff;
            padding:7px 15px;
            background-color:#cfad64;
            font-weight:700;
          }
          dl{
            display:table;
            table-layout:fixed;
            width:680px;
            border:1px solid #e5e5e5;
            &+dl{
              border-top:none;
            }
            dd,dt{
              display:table-cell;
              vertical-align:top;
              font-size:16px;
              line-height:32px;
              padding:10px 15px;
            }
            dt{
              border-right:1px solid #e5e5e5;
              color:#ac832d;
              background-color:#f5f4ed;
              width:50%;
              font-weight:700;
            }
            dd{
              vertical-align:middle;
              text-align:center;
              font-weight:700;
            }
          }
          ul{
            position:absolute;
            top:45px;
            right:0;
            .linkBtn{
              width:170px;
              height:40px;
              line-height:40px;
              &.priceBtn{
                height:130px;
                line-height:1;
                margin-bottom:15px;
                a{
                  font-size:16px;
                  line-height:20px;
                  position:relative;
                  background:url(../img/arrow04.png) no-repeat center right 20px;
                  background-size:9px auto;
                  div{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%)translateX(-50%);
                  }
                }
              }
            }
          }
          .option{
            h4{
              background-color:#c4c4c4;
            }
            dl{
              dt{
                background-color:#f5f5f5;
                font-weight:500;
                color:#222;
              }
              dd{
                font-weight:500;
              }
            }
          }
        }
      }
    }
  }
  /*
  料金一覧
  -------------------------------------*/
  #PagePrice.pageIndex {
    #Main{
      .underCommonGuide{
        margin-bottom: 90px;
      }
    }
  }

  /*
  料金一覧（カテゴリ）
  -------------------------------------*/
  #PagePrice.pageCategory {
  }

  /*
  料金一覧（詳細）
  -------------------------------------*/
  #PagePrice.pageEntry {
    #Main{
      #ContBox01{
        h3{
          text-align: left;
          border-bottom: 3px solid #f1f1f1;
          padding-bottom: 18px;
          font-family: "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
          letter-spacing: 3px;
          margin: 80px 0 60px;
        }
      }
      .priceBlock{
        .priceInnerBox{
          h4,dl{
            width:100%;
          }
        }
        .underCommonGuide{
          width:100%;
          margin-bottom: 45px;
        }
      }
    }
  }

  /*
  症例写真 共通
  -------------------------------------*/
  #PageCase{
  	.iziModal{
    	box-shadow:none;
    }
    #Main{
      margin-bottom:170px;
      h3{
        margin-bottom: 50px;
        span{
          color:#ac832d;
          display:block;
          font-size:20px;
          font-family: "Roboto Slab", serif;
          font-weight: 300;
          letter-spacing:2px;
        }
      }
      #CaseNav{
        font-size:0;
        letter-spacing:normal;
        .linkBtn{
          display:inline-block;
          vertical-align:top;
          width:235px;
          height:130px;
          line-height:1;
          margin-right:20px;
          &:nth-child(4n){
            margin-right:0;
          }
          &:nth-child(n+5){
            margin-top:20px;
          }
          a{
            font-size:16px;
            line-height:23px;
            letter-spacing:1px;
            position:relative;
            background:url(../img/arrow04.png) no-repeat center right 20px;
            background-size:9px auto;
            span{
              width:100%;
              position: absolute;
              top: 50%;
              left:0;
              //left: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
      .doctorBox{
        background-color:#f5f5f5;
        padding:15px;
        display:table;
        width:100%;
        table-layout:fixed;
        figure{
          width:70px;
          display:table-cell;
          vertical-align:middle;
          img{
            width:70px;
            height:70px;
            border-radius:50%;
          }
        }
        ul{
          display:table-cell;
          vertical-align:middle;
          padding-left:15px;
          li{
            font-size:14px;
            line-height:28px;
            color:#888;
          }
        }
      }
      .viewBtn{
        width:250px;
        height:50px;
        line-height:50px;
        margin:60px auto 0;
        cursor: pointer;
        div{
          background-repeat: no-repeat;
          background-position: center right 20px;
          background-image: url(../img/icon_plus.png);
          background-size: 15px auto;
          font-size:16px;
        }
      }
      .shoureiList{
        >ul{
          font-size:0;
          letter-spacing:normal;
          >li{
            width:485px;
            display:inline-block;
            vertical-align:top;
            border:1px solid #e5e5e5;
            border-radius:6px;
            padding:45px;
            margin-top:30px;
            &:nth-child(2n){
              margin-left:30px;
            }
            h4{
              font-size:16px;
              color:#fff;
              text-align:center;
              background-color:#cfad64;
              margin-bottom:30px;
              padding: 3px 10px;
            }
            .img{
              margin-bottom:25px;
              dl{
                width:180px;
                float:left;
                dd{
                  img{
                    width:100%;
                    height:auto;
                  }
                }
                dt{
                  font-size:16px;
                  margin-top:10px;
                  text-align:center;
                }
                &+dl{
                  float:right;
                  dd{
                    position:relative;
                    &:before{
                      content:"";
                      display:block;
                      position:absolute;
                      top:0;
                      bottom:0;
                      margin:auto;
                      left:-20px;
                      width: 0;
                      height: 0;
                      border-style: solid;
                      border-width: 6px 0 6px 10px;
                      border-color: transparent transparent transparent #dccb87;
                    }
                  }
                }
              }
            }
            .linkBtn{
              width:100%;
              height:50px;
              line-height:50px;
              margin:30px auto 0;
              a{
                font-size:16px;
              }
            }
          }
        }
      }
    }
  }
  /*
  症例写真
  -------------------------------------*/
  #PageCase.pageIndex {
    #Main{
      #ContBox00{
      	border: 1px solid #e7d48a;
      	border-radius: 6px;
      	padding: 10px 30px;
      	margin-bottom: 20px;
      	a{
      		text-decoration: none;
      		font-size: 16px;
      		padding:30px 0;
      		display: block;
      		width: 100%;
      			overflow: auto;
						  position: relative;
      		dl{
      			 width: 100%;
  					  white-space: nowrap;
						  overflow: hidden;
						  text-overflow: ellipsis;
						  padding-right: 15px;
						  color: #888;
      			dt{
      				display: inline;
      				font-size: 16px;
      				padding-right: 14px;
      				border-right: 1px solid #e5e5e5;
      				vertical-align: middle;
      				color: #c5a22b;
      			}
      			dd{
      				display: inline;
      				vertical-align: middle;
      				color: #888;
      				margin-left: 14px;
      				font-size: 14px;
      				transition: .4s;
      			}
      		}
      	}
  			a:after{
           content: "";
           position: absolute;
           top: 34px;
           right: 0;
           display: block;
           width: 8px;
           height: 15px;
           background: url(../img/arrow04.png) no-repeat center top/cover;
           background-size:8px 15px;
  			}
      	a+a{
      		border-top: 1px solid #f1f1f1;
      	}
      	a:hover{
      		dl{
      			color: #c5a22b;
	      		dd{
	      		color: #c5a22b;
	      		}
      		}
      	}
      }
      #ContBox02{
        margin-top:85px;
      }
    }
  }

  /*
  症例写真（カテゴリ）
  -------------------------------------*/
  #PageCase.pageCategory {
  }

  /*
  各症例写真（詳細）
  -------------------------------------*/
  #PageCase.pageEntry {
    #Main{
      #ContBox01{
        .mainContTitle{
          margin-bottom:0;
          display:block;
          h3{
            margin-bottom:30px;
            letter-spacing: 3px;
          }
        }
        .doctorBox{
          width:410px;
        }
        .beforeAfter{
          font-size:0;
          letter-spacing:normal;
          li{
            &.iziModalHandler:hover{
              cursor:pointer;
            }
            width:470px;
            display:inline-block;
            vertical-align:top;
            margin-top:60px;
            &:nth-child(2n){
              margin-left:60px;
            }
            dl{
              width:220px;
              float:left;
              dd{
                img{
                  width:100%;
                  height:auto;
                }
              }
              dt{
                font-size:16px;
                color:#fff;
                background-color:#cfad64;
                padding:3px 5px;
                text-align:center;
              }
              &+dl{
                float:right;
                position:relative;
                &:before{
                  content:"";
                  display:block;
                  position:absolute;
                  top:0;
                  bottom:0;
                  margin:auto;
                  left:-22px;
                  width: 22px;
                  height: 17px;
                  background:url(../img/arrow09.png) no-repeat center center;
                  background-size:22px auto;
                }
              }
            }
          }
        }
        .contSubBox{
          .boxTitle{
            margin-bottom:0;
          }
        }
        #ContSubBox01{
          >h3{
            color:#888;
            font-size:28px;
          }
          .price{
            background-color:#f5f4ed;
            padding:34px 30px;
            margin-top:35px;
            dl{
              display:table;
              width:100%;
              table-layout:fixed;
              dd,dt{
                display:table-cell;
                vertical-align:top;
                line-height:32px;
              }
              dt{
                font-size:20px;
                font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                font-weight: 400;
                width:80px;
              }
              dd{
                font-size:16px;
                color:#ac832d;
              }
            }
          }
          .entryCaseBox02{
            border:3px solid #f1f1f1;
            padding:0 25px;
            margin-top:45px;
          }
        }
      }
      #ContBox02{
        margin-top:80px;
        .catBox{
          position:relative;
          width:485px;
          float:left;
          margin-top:30px;
          &:nth-of-type(even){
            margin-right:30px;
          }
        }
        .topCatBox{
          width:100%;
          float:none;
        }
      }
    }
  }

  /*
  アクセス
  -------------------------------------*/
  #PageAccess.pageIndex {
    #Container {
      .innerBasic {
        #Main {
          margin-top: 57px;
          margin-bottom: 125px;
          #ContBox01 {
            .mapArea {
              margin-top: 40px;
              #Gmap01 {
                width:100%;
                height:440px;
                iframe{
                  width: 100%;
                  height:440px;
                }
              }
              ul {
                margin-top: 20px;
                position:relative;
                z-index:1;
                li {
                  width: 200px;
                  height: 40px;
                  margin-left: auto;
                  margin-right: 0;
                  font-size: 14px;
                  color: #c5a22b;
                  letter-spacing: 1.5px;
                  a {
                    width: 100%;
                    height: 100%;
                    display: block;
                    text-decoration: none;
                    padding-left: 16px;
                    line-height: 40px;
                    background: transparent url(../img/icon_link.png) no-repeat center right 14px;
                    background-size: 15px auto;
                    text-align:left;
                    &:after {

                    }
                  }
                }
              }
            }
            p {
              text-align: center;
              letter-spacing: 1.5px;
              span {
              }
            }
            .largeBox01 {
              width: 800px;
              margin: 0 auto;
              padding-top: 116px;
              position: relative;
              margin-top: -57px;
              &:after {
                content: "";
                position: absolute;
                top: -3px;
                left: 0;
                right:0;
                margin: auto;
                display: block;
                width: 6px;
                height: 100%;
                background: #eae2c7;
                z-index: -1;
              }
              figure.clearfix {
                margin-bottom: 90px;
                img {
                  width: 455px;
                }
                p {
                  width: 315px;
                  font-size: 16px;
                  margin-top: 51px;
                  text-align: left;
                  letter-spacing: 1.5px;
                }
                &:after {
                  content: ".";
                  display: block;
                  height: 0;
                  font-size:0;
                  clear: both;
                  visibility:hidden;
                }
                &:first-of-type {
                  img {
                    float: left;
                  }
                  p {
                    float: right;
                  }
                }
                &:nth-of-type(2) {
                  img {
                    float: right;
                  }
                  p {
                    float: left;
                  }
                }
                &:nth-of-type(3) {
                  img {
                    float: left;
                  }
                  p {
                    float: right;
                  }
                }
                &:nth-of-type(4) {
                  img {
                    float: right;
                  }
                  p {
                    float: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  お知らせ 共通
  -------------------------------------*/
  #PageNews{
    .sub_news {
      float: right;
      width: 160px;
      margin-left: 40px;
      margin-top: 63px;
      h5 {
        padding-top: 9px;
        padding-bottom: 5px;
        letter-spacing: 2px;
        text-align: center;
        font-size: 16px;
        color: #ac832d;
        border-top: 1px solid #e7d48a;
        border-bottom: 1px solid #e7d48a;
        font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
        margin-bottom: 12px;
      }
      p {
        font-size: 14px;
        line-height: 24px;
        color: #888888;
        letter-spacing: 1.5px;
        margin-bottom: 25px;
      }
      ul {
        li {
          margin-top: 9px;
          border-bottom: 1px solid #f1f1f1;
          padding-bottom: 9px;
          &:first-of-type {
            margin-top: 12px;
          }
          &:last-of-type {
            border-bottom: none;
          }
          a {
            font-size: 14px;
            width: 100%;
            height: 100%;
            display: block;
            color: #888888;
            text-decoration: none;
            letter-spacing: 1.5px;
            background: transparent url(../img/arrow02.png) no-repeat center right 5px;
            background-size: 7px auto;
            &:hover {
              background: transparent url(../img/arrow04.png) no-repeat center right 5px;
              background-size: 7px auto;
              color: #c5a22b;
            }
          }
          &.current{
            a{
              color:#ac832d;
            }
          }
        }
      }
    }
    #Container{
      #Main{
        .news_MoreBtn.active >div span {
          background: transparent url(../img/icon_minus.png) no-repeat center right 14px;
          background-size: 16px auto;
        }
        .news_MoreBtn {
          width: 250px;
          height: 50px;
          margin: 0 auto;
          margin-top: 60px;
          >div {
            text-align: center;
            span {
              width: 100%;
              height: 100%;
              display: block;
              font-size: 16px;
              color: #c5a22b;
              line-height: 50px;
              background: transparent url(../img/icon_plus_pc.png) no-repeat center right 19px;
              background-size: 15px auto;
            }
          }
        }
      }
    }
  }
  /*
  お知らせ
  -------------------------------------*/
  #PageNews.pageIndex {
    #Container {
      .innerBasic {
        &:after {
          content: ".";
          display: block;
          height: 0;
          font-size:0;
          clear: both;
          visibility:hidden;
        }
        #Main {
          width: 800px;
          float: left;
          margin-top:0;
          margin-bottom: 150px;
          #ContBox01 {
            h3 {
              text-align: center;
              font-size: 32px;
              line-height: 32px;
              color: #222222;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              margin-bottom: 57px;
              margin-top: 64px;
              letter-spacing: 6px;
            }
            .news_largeBox {
              width: 100%;
              padding: 53px 60px 59px;
              border: 1px solid #e5e5e5;
              border-radius: 5px;
              margin-bottom: 15px;
              .news_smallBox01 {
                h4 {
                  font-size: 28px;
                  letter-spacing: 2px;
                  a {
                    color: #222;
                    text-decoration: none;
                    &:hover {
                      color: #c5a22b;
                    }
                  }
                }
                p {
                  font-size: 14px;
                  color: #aaaaaa;
                  border-bottom: 3px solid #f1f1f1;
                  margin-top: -3px;
                  padding-bottom: 17px;
                  span {
                    margin-left: 8px;
                    letter-spacing: 1.5px;
                  }
                }
              }
              .news_smallBox02 {
                img {
                  margin-top: 19px;
                }
                p {
                  font-size: 16px;
                  margin-top: 21px;
                  letter-spacing: 1.5px;
                  &:first-of-type {
                    margin-top: 21px;
                  }
                  &:nth-of-type(6) {

                  }
                }
                ul {
                  margin-top: 20px;
                  li {
                    width: 170px;
                    height: 40px;
                    margin-right: 0;
                    margin-left: auto;
                    a {
                      text-decoration: none;
                      line-height: 40px;
                      &:hover {
                        box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .1);
                        border-color: #e8d492;
                      }
                    }
                  }
                }
              }
            }
            dl.news_listBox {
              width: 100%;
              font-size: 0;
              letter-spacing: normal;
              padding: 24px 25px;
              border: 1px solid #e5e5e5;
              border-radius: 7px;
              margin-bottom: 15px;
              transition: .4s;
              &:hover {
                box-shadow: 0 6px 8px 0 rgba(100, 81, 35, .2);
                border: 1px solid #e8d492;
                dt,dd a {
                  color: #c5a22b;
                }
              }
              dt {
                display: inline-block;
                font-size: 14px;
                color: #aaaaaa;
                letter-spacing: 1.5px;
                vertical-align: top;
                margin-top: 6px;
              }
              dd {
                display: inline-block;
                font-size: 20px;
                margin-left: 25px;
                letter-spacing: 2px;
                width:632px;
                vertical-align: top;
                a {
                  text-decoration: none;
                  color: #222;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  お知らせ（カテゴリ）
  -------------------------------------*/
  #PageNews.pageCategory {
    #Container {
      .innerBasic {
        &:after {
          content: ".";
          display: block;
          height: 0;
          font-size:0;
          clear: both;
          visibility:hidden;
        }
        #Main {
          width: 800px;
          float: left;
          margin-top:0;
          margin-bottom: 175px;
          #ContBox01 {
            h3 {
              text-align: center;
              font-size: 32px;
              line-height: 32px;
              color: #222222;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              margin-bottom: 58px;
              margin-top: 64px;
              letter-spacing: 6px;
            }
            dl {
              width: 100%;
              font-size: 0;
              letter-spacing: normal;
              padding: 24px 25px;
              border: 1px solid #e5e5e5;
              border-radius: 7px;
              margin-bottom: 15px;
              transition: .4s;
              &:hover {
                -webkit-box-shadow: 0 6px 8px 0 rgba(100, 81, 35, .2);
                box-shadow: 0 6px 8px 0 rgba(100, 81, 35, .2);
                border: 1px solid #e8d492;
                dt,dd a {
                  color: #c5a22b;
                }
              }
              dt {
                display: inline-block;
                font-size: 14px;
                color: #aaaaaa;
                letter-spacing: 1.5px;
                vertical-align: top;
                margin-top: 6px;
              }
              dd {
                display: inline-block;
                font-size: 20px;
                margin-left: 25px;
                letter-spacing: 2px;
                width:632px;
                vertical-align: top;
                a {
                  text-decoration: none;
                  color: #222;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  お知らせ（詳細）
  -------------------------------------*/
  #PageNews.pageEntry {
    #Container {
      .innerBasic {
        &:after {
          content: ".";
          display: block;
          height: 0;
          font-size:0;
          clear: both;
          visibility:hidden;
        }
        #Main {
          width: 800px;
          float: left;
          margin-bottom:125px;
          margin-top:84px;
          #ContBox01 {
            .newsTitle{
              border-bottom:3px solid #f1f1f1;
              padding-bottom:20px;
              margin-bottom:55px;
              h3{
                font-size:32px;
                font-family: "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                font-weight: 500;
                text-align:left;
              }
              .date{
                margin-top:2px;
                li{
                  font-size:14px;
                  color:#aaa;
                }
              }
            }
            .entryBox{
              border-bottom:1px solid #f1f1f1;
              padding-bottom:20px;
            }
            .pageMove{
              margin:60px 0 0;
              ul{
                text-align:center;
                li{
                  width:150px;
                  height:50px;
                  line-height:50px;
                  display:inline-block;
                  &:first-of-type {
                    a {
                      padding-left: 9px;
                    }
                  }
                  &+li{
                    margin-left:30px;
                  }
                  &.btn{
                    a{
                      background-repeat:no-repeat;
                      background-size:7px auto;
                    }
                  }
                  &.prev{
                    a{
                      background-image:url(../img/arrow03.png);
                      background-position:left 14px center;
                    }
                  }
                  &.next{
                    a{
                      background-image:url(../img/arrow04.png);
                      background-position:right 14px center;
                    }
                  }
                }
              }
            }
          }
          #ContBox02 {
            margin-top:90px;
            border:1px solid #e5e5e5;
            border-radius:6px;
            padding:40px 60px;
            .boxTitle{
              margin-bottom:32px;
            }
            .newsList{
              width:auto;
              border-top:none;
              li{
                &:last-child{
                  border-bottom:none;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  よくある質問
  -------------------------------------*/
  #PageFaq.pageIndex {
    #Container {
      .innerBasic {
        width: 100%;
        min-width: 1060px;
        max-width: 1280px;
        #Main {
          margin-bottom:130px;
          h3{
            margin-bottom: 50px;
            span{
              color:#ac832d;
              display:block;
              font-size:20px;
              font-family: "Roboto Slab", serif;
              font-weight: 300;
              letter-spacing:2px;
            }
          }

          #ContBox01{
            padding:0 30px;
            //max-width:1280px;
            margin:59px auto 0;
            .contSubBox{
              border-top:1px solid #f1f1f1;
              &:last-of-type{
                border-bottom:1px solid #f1f1f1;
              }
              dl{
                display:table;
                width:100%;
                padding:15px 0;
                table-layout:fixed;
                dt,dd{
                  display:table-cell;
                  vertical-align:top;
                }
                dt{
                  border-right:1px solid #e7d48a;
                  width:250px;
                  color:#ac832d;
                  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                  font-weight: 400;
                  padding-top:10px;
                  font-size:18px;
                  a{
                    color:#ac832d;
                    text-decoration: none;
                    background:url(../img/arrow04.png) no-repeat center right;
                    background-size: 9px auto;
                    padding-right: 17px;
                    &:hover{
                      color:#c5a22b;
                    }
                  }
                }
                dd{
                  padding-left:10px;
                  padding-bottom:10px;
                  ul{
                    position: relative;
                    &:before{
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      display: block;
                      width: 15px;
                      height: 100%;
                      background-color: #fff;
                    }
                    li{
                      display:inline-block;
                      margin:8px 0 0;
                      border-left: 1px solid #dbdbdb;
                      a{
                        font-size:16px;
                        color:#888;
                        padding:4px 15px;
                        text-decoration:none;
                        display:block;
                        &:hover{
                          color:#c5a22b;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      #Footer,
      #FooterContact{
        .innerBasic{
          margin: 0 auto;
          width: 1000px;
          min-width:initial;
          max-width:initial;
        }
      }
    }
  }

  /*
  よくある質問（カテゴリ）
  -------------------------------------*/
  #PageFaq.pageCategory {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            padding-bottom: 90px;
            h3 {
              margin-bottom: 50px;
            }
            .contSubBox{
              border-top:1px solid #f1f1f1;
              &:last-of-type{
                border-bottom:1px solid #f1f1f1;
              }
              dl{
                display:table;
                width:100%;
                padding:15px 0;
                table-layout:fixed;
                dt,dd{
                  display:table-cell;
                  vertical-align:top;
                }
                dt{
                  border-right:1px solid #e7d48a;
                  width:138px;
                  color:#ac832d;
                  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                  font-weight: 400;
                  padding-top:10px;
                  font-size:18px;
                }
                dd{
                  padding-left:10px;
                  padding-bottom:10px;
                  ul{
                    position: relative;
                    &:before{
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      display: block;
                      width: 15px;
                      height: 100%;
                      background-color: #fff;
                    }
                    li{
                      display:inline-block;
                      margin:8px 0 0;
                      border-left: 1px solid #dbdbdb;
                      a{
                        font-size:16px;
                        color:#888;
                        padding:4px 15px;
                        text-decoration:none;
                        display:block;
                        &:hover{
                          color:#c5a22b;
                        }
                      }
                      &.current{
                        a{
                          color:#ac832d;
                        }
                      }
                    }
                  }
                }
              }
            }
            .click {
              width: 250px;
              height: 50px;
              margin: 0 auto;
              padding: 0;
              margin-bottom: 0;
              >div {
                margin: 0 auto;
                margin-top: 60px;
                width: 250px;
                height: 50px;
                text-align: center;
                span {
                  width: 100%;
                  height: 100%;
                  display: block;
                  font-size: 16px;
                  color: #c5a22b;
                  line-height: 50px;
                  background: transparent url(../img/icon_plus_pc.png) no-repeat center right 19px;
                  background-size: 15px auto;
                }
              }
            }
          }
          .contboxFaq{
            margin-bottom:90px;
            h4{
              padding: 15px 30px;
              width: 100%;
              background-color: #cfad64;
              line-height: 32px;
              font-size: 20px;
              font-weight: 700;
              color: #fff;
              letter-spacing:3px;
            }
            dl{
              display:block;
              border-bottom:1px solid #f1f1f1;
              padding:30px 0;
              &:hover{
                dt{
                  a{
                    color:#c5a22b;
                  }
                }
                dd{
                  color:#c5a22b;
                }
              }
              dt{
                //letter-spacing:1.5px;
                font-size: 0;
                letter-spacing: normal;
                div{
                  display: inline-block;
                  vertical-align: top;
                }
                span{
                  display: inline-block;
                  vertical-align: top;
                  border-radius: 50%;
                  width: 45px;
                  height: 45px;
                  text-align: center;
                  font-family: "Roboto Slab", serif;
                  font-size: 18px;
                  font-weight: 300;
                  background: linear-gradient(45deg, #b58b32 0%, #dabe68 36%, #ebd896 67%, #cfb054 100%);
                  line-height: 45px;
                  color: #fff;
                  margin-right:15px;
                }
                p{
                  width:940px;
                  display: inline-block;
                  vertical-align: top;
                  font-size: 20px;
                  line-height: 40px;
                  vertical-align: top;
                  letter-spacing: 0.1em;
                }
                a{
                  color:#222;
                  text-decoration: none;
                }
                //font-size:20px;
                line-height: 40px;
                color:#222222;
                // font-weight:bold;
              }
              dd{
                margin-top:5px;
                //letter-spacing:1.5px;
                transition:0.4s;
                font-size: 0;
                letter-spacing: normal;
                div{
                  display: inline-block;
                  vertical-align: top;
                }
                span{
                  display: inline-block;
                  border-radius: 50%;
                  width: 45px;
                  height: 45px;
                  background-color: #f5f5f5;
                  line-height: 45px;
                  text-align: center;
                  font-family: "Roboto Slab", serif;
                  font-size: 18px;
                  font-weight: 300;
                  background: linear-gradient(45deg, #818181 0%, #adaeae 36%, #e7e7e8 67%, #b7b8b7 100%);
                  color: #fff;
                  margin-left:30px;
                  margin-right:15px;
                }
                p{
                  width:910px;
                  display: inline-block;
                  line-height: 28px;
                  font-size:16px;
                  margin-top: 8px;
                  letter-spacing: 0.1em;
                }
                line-height: 28px;
                //font-size:16px;
                color:#888888;
              }
            }
            .linkBtn {
                margin: 45px auto 0;
                width: 250px;
                height: 50px;
                line-height: 50px;
                a{
                  font-size:17px;
                }
            }
          }
        }
      }
    }
  }

  /*
  よくある質問（詳細）
  -------------------------------------*/
  #PageFaq.pageEntry {
    #Container {
      .innerBasic {
        #Main {
          margin-bottom: 168px;
          .linkBtn {
            a {
              font-size: 16px;
            }
          }
          #ContBox01 {
            padding-bottom: 90px;
            h3 {
              margin-bottom: 50px;
            }
            .contSubBox{
              border-top:1px solid #f1f1f1;
              &:last-of-type{
                border-bottom:1px solid #f1f1f1;
              }
              dl{
                display:table;
                width:100%;
                padding:15px 0;
                table-layout:fixed;
                dt,dd{
                  display:table-cell;
                  vertical-align:top;
                }
                dt{
                  border-right:1px solid #e7d48a;
                  width:138px;
                  color:#ac832d;
                  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                  font-weight: 400;
                  padding-top:10px;
                  font-size:18px;
                }
                dd{
                  padding-left:10px;
                  padding-bottom:10px;
                  ul{
                    position: relative;
                    &:before{
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      display: block;
                      width: 15px;
                      height: 100%;
                      background-color: #fff;
                    }
                    li{
                      display:inline-block;
                      margin:8px 0 0;
                      border-left: 1px solid #dbdbdb;
                      a{
                        font-size:16px;
                        color:#888;
                        padding:4px 15px;
                        text-decoration:none;
                        display:block;
                        &:hover{
                          color:#c5a22b;
                        }
                      }
                      &.current{
                        a{
                          color:#ac832d;
                        }
                      }
                    }
                  }
                }
              }
            }
            .click {
              width: 250px;
              height: 50px;
              margin: 0 auto;
              padding: 0;
              margin-bottom: 0;
              >div {
                margin: 0 auto;
                margin-top: 60px;
                width: 250px;
                height: 50px;
                text-align: center;
                span {
                  width: 100%;
                  height: 100%;
                  display: block;
                  font-size: 16px;
                  color: #c5a22b;
                  line-height: 50px;
                  background: transparent url(../img/icon_plus_pc.png) no-repeat center right 19px;
                  background-size: 15px auto;
                }
              }
            }
          }
          .contboxFaq{
            margin-bottom:90px;
            h4{
              padding: 15px 30px;
              width: 100%;
              background-color: #cfad64;
              line-height: 32px;
              font-size: 20px;
              font-weight: 700;
              color: #fff;
            }
            dl{
              display:block;
              border-bottom:1px solid #f1f1f1;
              padding:30px 0;
              dt{
                font-size: 0;
                letter-spacing: normal;
                span{
                  display: inline-block;
                  border-radius: 50%;
                  width: 45px;
                  height: 45px;
                  text-align: center;
                  font-family: "Roboto Slab", serif;
                  font-size: 18px;
                  font-weight: 300;
                  background: linear-gradient(45deg, #b58b32 0%, #dabe68 36%, #ebd896 67%, #cfb054 100%);
                  line-height: 45px;
                  color: #fff;
                  margin-right:15px;
                }
                div{
                  display: inline-block;
                  vertical-align: top;
                }
                p{
                  display: inline-block;
                  vertical-align: top;
                  width: 940px;
                  font-size:20px;
                  line-height: 40px;
                  letter-spacing: 0.1em;
                }
                //font-size:20px;
                line-height: 40px;
                color:#222222;
                // font-weight:bold;
              }
              dd{
                font-size: 0;
                letter-spacing: normal;
                .icon{
                  display: inline-block;
                  vertical-align: top;
                span{
                  display: inline-block;
                  border-radius: 50%;
                  width: 45px;
                  height: 45px;
                  background-color: #f5f5f5;
                  line-height: 45px;
                  text-align: center;
                  font-family: "Roboto Slab", serif;
                  font-size: 18px;
                  font-weight: 300;
                  background: linear-gradient(45deg, #818181 0%, #adaeae 36%, #e7e7e8 67%, #b7b8b7 100%);
                  color: #fff;
                  margin-left:30px;
                  margin-right:15px;
                }
                }
                .entryWrapBox{
                  display: inline-block;
                  vertical-align: top;
                  width: 910px;
                  font-size:16px;
                  line-height: 32px;
                  margin-top: 8px;
                  letter-spacing: 0.1em;
                }
                //margin-top: 5px;
                //font-size:16px;
                line-height: 32px;
              }
            }
            .linkBtn {
                margin: 45px auto 0;
                width: 250px;
                height: 50px;
                line-height: 50px;
            }
          }
          #ContBox02 {
            margin-top: 82px;
            h3 {
              letter-spacing: 6px;
            }
            .catBlock {
              margin-top: 5px;
              .showBox_sp {
                .catBox {
                  &:nth-of-type(even) {
                    margin-left: 24px;
                  }
                }
              }
            }
          }
          #ContBox03 {
            margin-top: 77px;
          }
          #ContBox04 {
            margin-top: 82px;
            margin-bottom: 168px;
          }
          ul.blogList {  //関連するコラム、質問の中身：ul,li
            margin-top: 34px;
            padding-bottom: 0;
            li {
              padding-bottom: 33px;
              dl {
                dt {
                  padding-top: 5px;
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  #PageFaq.pageEntry .catBox {
    display: inline-block;
    margin-top: 30px;
    width: 485px;
    vertical-align: top;
  }
  #PageFaq.pageEntry .linkBtn {
    margin: 45px auto 0;
    width: 250px;
    height: 50px;
    line-height: 50px;
  }

  /*
  コラム
  -------------------------------------*/
  #PageColumn.pageIndex {
    #Container {
      #Main{
        #ContBox02{
          margin-top:48px;
        }
        #ContBox03 {
          margin-bottom: 112px;
          &:after {
            content: ".";
            display: block;
            height: 0;
            font-size:0;
            clear: both;
            visibility:hidden;
          }
          h3 {
            font-family: 'Roboto Slab', serif;
            font-weight: 300;
            font-size: 36px;
            color: #ac832d;
            margin-top: 76px;
            margin-bottom: 58px;
            letter-spacing: 3.5px;
            span {
              font-size: 20px;
              color: #222222;
              display: block;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              letter-spacing: 3.5px;
              margin-top: -4px;
            }
          }
          >div {
            width: 250px;
            height: 550px;
            float: left;
            text-align: center;
            border-left: 1px solid #f1f1f1;
            img {
              width: 185px;
            }
            h4 {
              width: 160px;
              margin: 0 auto;
              color: #ac832d;
              font-size: 18px;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              padding-top: 5px;
              padding-bottom: 5px;
              border-top: 1px solid #e7d48a;
              border-bottom: 1px solid #e7d48a;
              margin-bottom: 37px;
            }
            ul {
              li {
                width: 190px;
                margin: 0 auto;
                text-align: left;
                margin-top: 8px;
                border-bottom: 1px solid #f1f1f1;
                padding-bottom: 7px;
                &:last-child{
                  border-bottom:none;
                }
                a {
                  width: 100%;
                  height: 100%;
                  line-height: 24px;
                  display: block;
                  font-size: 16px;
                  color: #888888;
                  text-decoration: none;
                  letter-spacing: 3px;
                  background: transparent url(../img/arrow02.png) no-repeat center right 5px;
                  background-size: 7px auto;
                  span {
                    width: 160px;
                  }
                  &:hover{
                    background: transparent url(../img/arrow04.png) no-repeat center right 5px;
                    background-size: 7px auto;
                    color: #c5a22b;
                  }
                }
              }
            }
            &:first-of-type {

            }
            &:nth-of-type(2) {

            }
            &:nth-of-type(3) {

            }
            &:nth-of-type(4) {
              border-right: 1px solid #f1f1f1;
            }
          }
        }
      }
      .innerBasic {
        #Main {
          #ContBox02 {
            margin-top: 60px;
            border: 1px solid #e5e5e5;
            border-radius: 7px;
            padding: 30px;
            >div {
              clear: both;
              border-bottom: 1px solid #f1f1f1;
              padding-bottom: 30px;
              padding-top: 30px;
              img {
                width: 160px;
                float: left;
                margin-right: 30px;
              }
              h5 {
                display: inline-block;
                font-size: 20px;
                margin-top: 5px;
                letter-spacing: 2px;
                a {
                  text-decoration: none;
                  color: #222;
                }
              }
              p {
                display: inline-block;
                font-size: 14px;
                color: #888888;
                letter-spacing: 1px;
                &:nth-of-type(2) {
                  margin-top: -1px;
                  span {
                    margin-left: 7px;
                    letter-spacing: 1.5px;
                  }
                }
              }
              &:first-of-type {
                padding-top: 0;
              }
              &:last-of-type {
                padding-bottom: 0;
                border: none;
              }
            }
          }
        }
      }
    }
  }

  /*
  コラム（カテゴリ）
  -------------------------------------*/
  #PageColumn.pageCategory {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            margin-bottom: 140px;
            h3 {
              margin-top: 57px;
              letter-spacing: 6px;
              margin-bottom: 49px;
            }
            >div.largeBox {
              clear: both;
              border: 1px solid #e5e5e5;
              border-radius: 7px;
              padding-bottom: 30px;
              padding-top: 30px;
              padding: 30px;
              margin-bottom: 30px;
              overflow: hidden;
              transition: .4s;
              &:hover {
                -webkit-box-shadow: 0 6px 8px 0 rgba(100, 81, 35, .2);
                box-shadow: 0 6px 8px 0 rgba(100, 81, 35, .2);
                border: 1px solid #e8d492;
                h5 {
                  a {
                   color: #c5a22b;
                 }
               }
               p {
                color: #c5a22b;
                &:nth-of-type(2) {
                  color: #c5a22b;
                }
              }
            }
            img {
              width: 160px;
              float: left;
              margin-right: 30px;
              height: auto;
            }
            h5 {
              display: inline-block;
              font-size: 20px;
              margin-top: 5px;
              letter-spacing: 2px;
              a {
                text-decoration: none;
                color: #222;
              }
            }
            p {
              display: inline-block;
              font-size: 14px;
              color: #888888;
              letter-spacing: 1px;
              &:nth-of-type(2) {
                margin-top: -1px;
                color: #aaa;
                span {
                  margin-left: 7px;
                  letter-spacing: 1.5px;
                }
              }
            }
            &:first-of-type {
            }
            &:last-of-type {
              //padding-bottom: 0;
              //border: none;
            }
          }
          .click.active >div span {
            background: transparent url(../img/icon_minus.png) no-repeat center right 14px;
            background-size: 16px auto;
          }
          .click {
            border: none;
            width: 250px;
            padding: 0;
            margin: 60px auto 0;
            >div {
              border: 1px solid #e7d48a;
              border-radius: 7px;
              width: 250px;
              height: 50px;
              span {
                display: block;
                width: 100%;
                height: 100%;
                background: transparent url(../img/icon_plus.png) no-repeat center right 19px;
                background-size: 15px auto;
                line-height: 50px;
                text-align: center;
                font-size: 16px;
                color: #c5a22b;
              }
            }
          }
          .showBox {
            >div.shourei {

            }
          }
        }
      }
    }
  }
}

  /*
  コラム（詳細）
  -------------------------------------*/
  #PageColumn.pageEntry {
    #Container {
      .innerBasic {
        #Main {
          margin-bottom: 168px;
          #ContBox01 {
            margin-bottom: 50px;
            margin-top: 89px;

            >div.h3_box {
              margin-bottom: 50px;
              h3 {
                text-align: left;
                border-bottom: 3px solid #f1f1f1;
                font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                font-weight: 500;
                letter-spacing: 3px;
                padding-bottom: 23px;
                span {
                  color: #aaaaaa;
                  font-size: 14px;
                  display: block;
                  letter-spacing: 1.5px;
                }
              }
              p {
                font-size: 14px;
                margin-top: 25px;
                letter-spacing: 1.5px;
                span {
                  margin-left: 26px;
                  position: relative;
                  &:before {
                    content: "";
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    background: url(../img/icon_time.png) no-repeat;
                    background-position: left top;
                    background-size: 16px auto;
                    position: absolute;
                    top: 0;
                    left: -21px;
                  }
                }
              }
            }
            .faq_box {
              width: 100%;
              padding: 60px 60px 24px;
              border: 1px solid #e5e5e5;
              border-radius: 7px;
              .entryFaqBox01 {

              }
            }
          }
          #ContBox02 {
            margin-top: 82px;
            h3 {
              letter-spacing: 6px;
            }
            .catBlock {
              margin-top: 5px;
              .showBox_sp {
                .catBox {
                  &:nth-of-type(even) {
                    margin-left: 24px;
                  }
                }
              }
            }
          }
          #ContBox03 {
            margin-top: 77px;
          }
          #ContBox04 {
            margin-top: 82px;
            margin-bottom: 168px;
          }
          ul.blogList {  //関連するコラム、質問の中身：ul,li
            margin-top: 34px;
            padding-bottom: 0;
            li {
              padding-bottom: 33px;
              &:hover {
                dl {
                  dt {
                    a {
                      color: #c5a22b;
                    }
                  }
                }
                p {
                  color: #c5a22b;
                }
              }
              dl {
                dt {
                  padding-top: 5px;
                  margin-bottom: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  #PageColumn.pageEntry .catBox {
    display: inline-block;
    margin-top: 30px;
    width: 485px;
    vertical-align: top;
  }
  #PageColumn.pageEntry .linkBtn {
    margin: 45px auto 0;
    width: 250px;
    height: 50px;
    line-height: 50px;
  }

  /*
  カウンセリング予約
  -------------------------------------*/
  #PageContact.pageIndex {
    #Container {
      .innerBasic {
        width: 100%;
        #Main {
          #ContBox01 {
            width: 1000px;
            margin: 0 auto;
            h3 {
              margin-bottom: 55px;
            }
            .btnForForeigners{
              a{
                color: #c5a22b;
                border: 1px solid #e7d48a;
                border-radius: 7px;
                text-align: center;
                font-size: 20px;
                padding: 9px 0;
                width: 295px;
                margin: 0 auto 40px;
                background-image: url(../img/icon_link.png);
                background-size: 15px auto;
                background-position: right 14px center;
                background-repeat: no-repeat;

                display: block;
                text-decoration: none;
                &:hover{
                  box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .1);
                  border: 1px solid #e8d492;
                }
              }
              span{
                display: block;
              }
              b{
                font-family: "Noto Sans SC", sans-serif;
                font-optical-sizing: auto;
                font-weight: 300;
                font-style: normal;
              }
            }
            .contact_calenderBlock {
              //非表示
              display: none;

              .innerBasic {
                .left {
                  margin-top: 70px;
                  dl.contactBox {
                    dt {
                    }
                    dd {
                      &:first-of-type.tel {
                        width: 100%;
                        letter-spacing: 5px;
                        margin-top: 12px;
                        margin-left: 83px;
                      }
                      &:nth-of-type(2) {
                        display: block;
                        width: 100%;
                        font-size: 16px;
                        color: #888888;
                        text-align: center;
                        margin-top: 10px;
                        letter-spacing: 2px;
                        span {
                          display:inline-block;
                        }
                      }
                      &:nth-of-type(3) {
                        width: 100%;
                        color: #222222;
                        font-size: 14px;
                        margin-top: 19px;
                        line-height: 27px;
                      }
                    }
                  }
                  ul.clearfix {
                    li.mailLink {
                      width: 100%;
                      height: 50px;
                      a {
                        text-align: center;
                        background-position: left 149px center;
                        background-size: 27px;
                        >div {
                          top: 50%;
                          left:48px;
                          right: 0;
                          margin: 0 auto;
                          letter-spacing: 2px;
                          //background-image: url(../img/arrow01.png.png);
                          position: relative;
                          margin-left: -48px;
                          &:after {
                            content: "";
                            display: inline-block;
                            width: 180px;
                            height: 180px;
                            background: url(../img/arrow01.png) no-repeat;
                            background-position: left top;
                            background-size: 17px auto;
                            position: absolute;
                            top: 4px;
                            right: -101px;
                          }
                        }
                      }
                    }
                  }
                }
                .right {
                  margin-top: 40px;
                  >div.cal_wrapper {
                    .googlecal {
                      iframe {

                      }
                    }
                  }
                }
              }
            }
            >dl {
              border: 5px solid #f1f1f1;
              padding: 37px 55px 31px;
              margin-top: 24px;
              letter-spacing: 2px;
              dt {
                font-size: 16px;
                color: #ac832d;
                font-weight: 700;
                border-bottom: 1px solid #e7d48a;
                padding-bottom: 9px;
              }
              dd {
                margin-top: 24px;
                font-size: 14px;
                line-height: 29px;
                color: #888888;
                letter-spacing: 1.5px;
                span {
                  display: block;
                  margin-top: 17px;
                }
              }
            }
          }
          #ContBox02 {
            width: 1000px;
            margin: 0 auto;
            margin-top: 81px;
            h3 {

            }
            p {
              text-align: center;
              margin-top: 42px;
              letter-spacing: 1.5px;
              a {
              }
              span {
                display: block;
              }
            }
            form {
              margin-top: 50px;
              >dl {  //共通設定
                border-top: 1px solid #f1f1f1;
                padding-bottom: 14px;
                font-size: 0;
                letter-spacing: normal;
                dd.require {
                  width: 40px;
                  display: inline-block;
                  vertical-align: top;
                  color: #fefefe;
                  font-size: 12px;
                  background-color: #e42f2f;
                  text-align: center;
                  height: 20px;
                  line-height: 20px;
                  margin-top: 30px;
                }
                dt {
                  width: 200px;
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 30px;
                  line-height: 20px;
                  font-size: 16px;
                  color: #222222;
                  padding-left: 10px;
                  letter-spacing: 2px;
                }
                dd:nth-of-type(2) {
                  display: inline-block;
                  font-size: 16px;
                  color: #222;
                  width: 750px;
                  padding-top: 15px;
                  padding-left: 10px;
                  >div.radio {
                    display: block;
                    margin-bottom: 12px;
                    label {
                      margin-left: 4px;
                      letter-spacing: 1.5px;
                    }
                    span {
                      width: 40px;
                      height: 40px;
                      background: transparent url(../img/radio02.png) no-repeat scroll left center;
                      background-size: 40px 40px;
                      input {
                        font-size: 16px;
                        width: 40px;
                        height: 40px;
                      }
                    }
                    span.checked {
                      background: transparent url(../img/radio01.png) no-repeat scroll left center;
                      background-size: 40px 40px;
                    }
                  }
                  >div.cautionText {

                  }
                  >input {
                    font-size: 16px;
                    border-radius: 5px;
                    width: 300px;
                    height: 50px;
                    background-color: #eee;
                    font-size: 16px;
                    color: #222;
                    padding-left: 17px;
                    letter-spacing: 1.5px;
                    &:focus{
                      border: 1px solid #e8d492;
                      background-color: #fff;
                    }
                  }
                  >span {
                    letter-spacing: -1px;
                    line-height: 14px;
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    color: #888;
                  }
                  input:-webkit-input-placeholder{
                    color: #888;
                  }
                  input::-webkit-input-placeholder{
                    color: #888;
                  }
                  input:-ms-input-placeholder{
                    color: #888;
                  }
                  textarea:-webkit-input-placeholder{
                    color: #888;
                  }
                  textarea::-webkit-input-placeholder{
                    color: #888;
                  }
                  textarea:-ms-input-placeholder{
                    color: #888;
                  }
                }
                &:first-of-type {  //予約の内容
                  padding-bottom: 26px;
                  dd:nth-of-type(2) {
                    padding-top: 20px;
                    >div.radio {
                      &:nth-of-type(3) {
                        margin-bottom: 15px;
                      }
                    }
                  }
                }
                &:nth-of-type(2) {  //お名前
                  dd {
                    &:nth-of-type(2) {
                      input {
                      }
                    }
                  }
                }
                &:nth-of-type(3) {  //フリガナ

                }
                &:nth-of-type(4) {  //メールアドレス
                  dd {
                    &:nth-of-type(2) {
                      input {
                        width: 500px;
                      }
                    }
                  }
                }
                &:nth-of-type(5) {  //確認用メールアドレス
                  dd:nth-of-type(2) {
                    input {
                      width: 500px;
                    }
                    span {
                      display: block;
                      margin-top: 9px;
                      margin-bottom: 6px;
                      font-size: 14px;
                      color: #888888;
                      letter-spacing: 1.5px;
                    }
                  }
                }
                &:nth-of-type(6) {  //電話番号

                }
                &:nth-of-type(7) {  //性別
                  padding-bottom: 6px;
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 23px;
                      >div.radio {
                        display: inline-block;
                        input {
                          span {
                          }
                        }
                      }
                      >div {
                        &:nth-of-type(3) {
                          margin-left: 23px;
                        }
                      }
                      >span {
                        width: 250px;
                        display: inline-block;
                        height: auto;
                        background: none;
                        vertical-align: top;
                        margin-top: 12px;
                        margin-left: 23px;
                      }
                    }
                  }
                }
                &:nth-of-type(8) {  //来院歴
                  padding-bottom: 4px;
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 23px;
                      >div.radio {
                        display: inline-block;
                        input {
                          span {
                          }
                        }
                        &:nth-of-type(2) {

                        }
                      }
                      >div {
                        &:nth-of-type(3) {
                          margin-left: 27px;
                        }
                      }
                      >span {
                        width: 250px;
                        display: inline-block;
                        height: auto;
                        background: none;
                        vertical-align: top;
                        margin-top: 12px;
                        margin-left: 26px;
                      }
                    }
                  }
                }
                &:nth-of-type(9) {  //ご希望の予約日
                  padding-bottom: 28px;
                  &:after {
                    content: ".";
                    display: block;
                    height: 0;
                    font-size:0;
                    clear: both;
                    visibility:hidden;
                  }
                  dd {
                    &:nth-of-type(2) {
                      display: block;
                      width: 200px;
                      font-size: 14px;
                      line-height: 24px;
                      color: #e42f2f;
                      margin-top: 6px;
                      padding-left: 1.5em;
                      text-indent: -1.5em;
                      letter-spacing: .7px;
                    }
                    &:nth-of-type(3) {
                      width: 750px;
                      display: inline-block;
                      float: right;
                      margin-top: -124px;
                      .contact_calenderBlock {
                        width: 100%;
                        .innerBasic {
                          width: 100%;
                          .left {
                            width: 300px;
                            >dl {
                              >dd {
                                &:nth-of-type(2) {
                                  padding-top: 6px;
                                  padding-left: 1px;
                                  width: 100%;
                                  &:after {
                                    content: ".";
                                    display: block;
                                    height: 0;
                                    font-size:0;
                                    clear: both;
                                    visibility:hidden;
                                  }
                                  >input {
                                    width: 290px;
                                  }
                                  .selectorBox {  //時
                                    float: left;
                                    select {
                                      border-radius: 5px;
                                      padding-left: 17px;
                                      width: 70px;
                                      height: 50px;
                                      background-color: #eee;
                                      letter-spacing: 1.5px;
                                      font-size: 16px;
                                      font-size: 16px;
                                      color: #222;
                                      margin-left: 20px;
                                      margin-top: 10px;
                                      background: url(../img/arrow07.png) no-repeat;
                                      background-size: 10px auto;
                                      background-position: right 15px center;
                                      &:focus{
                                        border: 1px solid #e8d492;
                                        background-color: #fff;
                                      }
                                    }
                                    span {
                                      margin-left: 6px;
                                      margin-right: 17px;
                                      vertical-align: middle;
                                      position: relative;
                                      top: 7px;
                                      font-size: 16px;
                                      color: #222222;
                                    }
                                    &:nth-of-type(2) {  //分
                                      float: right;
                                      span {

                                      }
                                    }
                                  }
                                }
                              }
                              &:first-of-type {  //第1希望日時
                                border-top: none;
                              }
                              &:nth-of-type(2) {  //第2希望日時

                              }
                              &:nth-of-type(3) {  //第3希望日時
                                dd {
                                  &:first-of-type {
                                    display: inline-block;
                                    margin-top: 30px;
                                    width: 40px;
                                    height: 20px;
                                    background-color: #b8b8b8;
                                    line-height: 20px;
                                    text-align: center;
                                    vertical-align: top;
                                    font-size: 12px;
                                    color: #fefefe;
                                  }
                                }
                              }
                            }
                          }
                          .right {
                            width: 420px;
                            margin-top: 20px;
                            .cal_wrapper {
                              width: 100%;
                              .googlecal {
                                width: 100%;
                                iframe {
                                  width: 420px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &:last-of-type {  //ご相談内容
                  border-bottom: 1px solid #f1f1f1;
                  padding-bottom: 6px;
                  >dd {
                    &:nth-of-type(2) {
                      width: 750px;
                      textarea {
                        border-radius: 5px;
                        padding-top: 13px;
                        padding-left: 16px;
                        width: 750px;
                        height: 200px;
                        background-color: #eee;
                        letter-spacing: 1.5px;
                        font-size: 16px;
                        color: #222;
                        resize: none;
                        &:focus{
                          border: 1px solid #e8d492;
                          background-color: #fff;
                        }
                      }
                    }
                  }
                }
              }
              p.parent_agree {
                color: #e42f2f;
                font-size: 14px;
                line-height: 24px;
                margin-top: 25px;
                span {
                  a {
                    color: #e42f2f;
                    position: relative;
                    &:after {
                      content: "";
                      display: inline-block;
                      width: 20px;
                      height: 20px;
                      background: url(../img/img_pdf.png) no-repeat;
                      background-position: right center;
                      background-size: 20px auto;
                      position: absolute;
                      top: 0px;
                      right: -24px;
                    }
                  }
                }
              }
              p.minorLink{
                margin:25px 0;
                text-align: right;
                a{
                  font-size: 16px;
                  background: url(../img/icon_link.png) no-repeat right center;
                  background-size: 15px auto;
                  padding-right: 24px;
                }
              }
              >div.confirmBtn {
                ul {
                  margin: 25px auto 120px;
                  width: 340px;
                  height: 80px;
                  li {
                    width: 100%;
                    width: 100%;
                    input {
                      border-radius: 7px;
                      background-color: #d1ae39;
                      display: block;
                      width: 100%;
                      height: 100%;
                      background: transparent url(../img/arrow05.png) no-repeat center right 32px;
                      background-size: 11px auto;
                      line-height: 80px;
                      text-align: center;
                      letter-spacing: 2px;
                      font-size: 20px;
                      color: #fff;
                      margin:0 auto;
                      transition: .4s;
                      &:hover{
                        box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .2);
                        background-color: #e2b623;
                      }
                    }
                  }
                }
              }
            }
          }
          #ContBox03 {
            // width: 100vw;
            background-color: #f5f5f5;
            padding-top: 120px;
            padding-bottom: 140px;
            .privacy_BigBox {
              width: 800px;
              text-align: left;
              margin: 0 auto;
              .privacy_Box01 {
                h3 {
                  font-size: 32px;
                  line-height: 32px;
                  margin-bottom: 50px;
                }
                >p {
                  font-size: 16px;
                  text-align: center;
                  letter-spacing: 1.5px;
                  margin-bottom: 68px;
                  span {
                    display: block;
                  }
                }
              }
              .privacy_Box02 {
                h4 {
                  font-size: 20px;
                  border-bottom: 2px solid #e0e0e0;
                  margin-top: 43px;
                  line-height: 41px;
                  letter-spacing: 2px;
                }
                >p {
                  font-size: 16px;
                  margin-top: 22px;
                  letter-spacing: 1.5px;
                }
                ul {
                  margin-top: -4px;
                  li {
                    margin-top: 7px;
                    letter-spacing: 1.5px;
                  }
                }
                >dl {
                  margin-top: 25px;
                  dt {
                  }
                  dd {
                    &:first-of-type {
                      margin-top: 7px;
                    }
                    &:nth-of-type(2) {
                      margin-top: 7px;
                      span {

                      }
                    }
                    &:last-of-type {
                      margin-top: 7px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  カウンセリング予約（入力内容のご確認）
  -------------------------------------*/
  #PageContact.pageConfirm {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            margin-top: 58px;
            margin-bottom: 150px;
            h3 {
              font-size: 32px;
              margin-bottom: 59px;
              span {
                display: block;
                font-size: 20px;
                margin-top: 4px;
                letter-spacing: 4px;
              }
            }
            p {
              font-size: 16px;
              text-align: center;
              letter-spacing: 1.5px;
              span {
                display: block;
              }
            }
            form{
              >dl {  //全体設定
                border-top: 1px solid #f1f1f1;
                padding-bottom: 25px;
                font-size: 0;
                letter-spacing: normal;
                >dt {
                  width: 200px;
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 30px;
                  font-size: 16px;
                  color: #222222;
                  padding-left: 10px;
                  letter-spacing: 2px;
                }
                dd.require {
                  width: 40px;
                  display: inline-block;
                  vertical-align: top;
                  color: #fefefe;
                  font-size: 12px;
                  background-color: #e42f2f;
                  text-align: center;
                  height: 20px;
                  line-height: 20px;
                  margin-top: 30px;
                }
                >dd {
                  &:nth-of-type(2) {
                    display: inline-block;
                    font-size: 16px;
                    color: #222;
                    width: 750px;
                    padding-top: 28px;
                    padding-left: 10px;
                    letter-spacing: 1.5px;
                  }
                }
                &:first-of-type {  //予約の内容
                  margin-top: 49px;
                }
                &:nth-of-type(2) {  //お名前

                }
                &:nth-of-type(3) {  //フリガナ

                }
                &:nth-of-type(4) {  //メールアドレス

                }
                &:nth-of-type(5) {  //電話番号

                }
                &:nth-of-type(6) {  //性別

                }
                &:nth-of-type(7) {  //来院歴

                }
                &:nth-of-type(8) {  //ご希望の予約日
                  padding-bottom: 37px;
                  &:after {
                    content: ".";
                    display: block;
                    height: 0;
                    font-size:0;
                    clear: both;
                    visibility:hidden;
                  }
                  >dd {
                    &:nth-of-type(2) {
                      display: block;
                      width: 200px;
                      font-size: 14px;
                      line-height: 24px;
                      color: #e42f2f;
                      margin-top: 21px;
                      padding-left: 1.5em;
                      text-indent: -1.5em;
                      letter-spacing: .7px;
                      padding-top: 0;
                    }
                    &:nth-of-type(3) {
                      float: left;
                      margin-top: -123px;
                      margin-left: 250px;
                      >dl {
                        margin-bottom: -13px;
                        >dt {
                          font-size: 16px;
                          color: #222;
                          display: inline-block;
                          vertical-align: top;
                          margin-top: 29px;
                          margin-left: 11px;
                          letter-spacing: 1.5px;
                        }
                        >dd {
                          &:nth-of-type(2) {
                            font-size: 16px;
                            display: inline-block;
                            vertical-align: top;
                            margin-top: 29px;
                            margin-left: 60px;
                            letter-spacing: 1.5px;
                          }
                        }
                        &:nth-of-type(2) {

                        }
                        &:nth-of-type(3) {
                          dd {
                            &:first-of-type {
                              width: 40px;
                              display: inline-block;
                              vertical-align: top;
                              text-align: center;
                              height: 20px;
                              line-height: 20px;
                              margin-top: 29px;
                              font-size: 16px;
                              color: #fefefe;
                              background-color: #b8b8b8;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(9) {  //ご相談内容
                  border-bottom: 1px solid #f1f1f1;
                }
              }
              >div {
                >ul.sendBtn {  //送信ボタン
                  margin: 60px auto 30px;
                  width: 340px;
                  height: 80px;
                  >li {
                    width: 100%;
                    input {
                      display: block;
                      background-color: #d1ae39;
                      border-radius: 7px;
                      width: 100%;
                      height: 100%;
                      background: transparent url(../img/arrow05.png) no-repeat center right 32px;
                      background-size: 11px auto;
                      line-height: 80px;
                      text-align: center;
                      letter-spacing: 2px;
                      font-size: 20px;
                      color: #fff;
                      transition: .4s;
                      &:hover{
                        box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .2);
                        background-color: #e2b623;
                      }
                    }
                  }
                }
              }
              >ul.backBtn {  //戻るボタン
                >li {
                  margin: 0 auto;
                  width: 150px;
                  height: 40px;
                  >input {
                    display: block;
                    border: 1px solid #e5e5e5;
                    border-radius: 7px;
                    width: 100%;
                    height: 100%;
                    line-height: 40px;
                    text-align: center;
                    text-decoration: none;
                    font-size: 14px;
                    color: #888;
                    transition:0.4s;
                    &:hover{
                      box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .1);
                      border: 1px solid #cfcfcf;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  カウンセリング予約（送信完了）
  -------------------------------------*/
  #PageContact.pageThanks {
    #Container {
      .innerBasic {
        #Main {
          margin-bottom: -31px;
          #ContBox01 {
            h3 {
              margin-bottom: 57px;
              span {
                display: block;
                font-size: 20px;
                margin-top: 7px;
                letter-spacing: 4px;
              }
            }
            p {
              font-size: 16px;
              text-align: center;
              letter-spacing: 1.5px;
              &:nth-of-type(2) {
                margin-top: 22px;
                span {
                  display: block;
                }
              }
            }
            ul {
              margin-top: 50px;
              li {
                margin: 0 auto;
                width: 250px;
                height: 50px;
                text-align: center;
                a {
                  display: block;
                  width: 100%;
                  height: 100%;
                  line-height: 50px;
                  text-decoration: none;
                  font-size: 16px;
                  color: #c5a22b;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  簡単画像診断
  -------------------------------------*/
  #PageDiagnostic-imaging.pageIndex {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            h3 {

            }
            >p {
              text-align: center;
              margin-top: 45px;
              letter-spacing: 1.5px;
              a {
                &:hover {
                  text-decoration: none;
                }
              }
              span {
                display: block;
                &:first-of-type {
                  display: inline-block;
                }
                &:nth-of-type(2) {

                }
              }
            }
            form {
              margin-top: 50px;
              >dl {
                border-top: 1px solid #f1f1f1;
                padding-bottom: 15px;
                font-size: 0;
                letter-spacing: normal;
                >dt {
                  width: 200px;
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 30px;
                  font-size: 16px;
                  color: #222222;
                  padding-left: 10px;
                  letter-spacing: 2px;
                  line-height:20px;
                }
                >dd {
                  &:first-of-type {
                    width: 40px;
                    display: inline-block;
                    vertical-align: top;
                    color: #fefefe;
                    font-size: 12px;
                    background-color: #e42f2f;
                    text-align: center;
                    height: 20px;
                    line-height: 20px;
                    margin-top: 30px;
                  }
                  &:nth-of-type(2) {  //画像添付
                    display: inline-block;
                    width:760px;
                    padding-top: 15px;
                    input {  //inputタグ全体設定
                      padding-left: 16px;
                      margin-left: 10px;
                      letter-spacing: 2px;
                      background-color: #eeeeee;
                      color:#222;
                      &:focus{
                        border: 1px solid #e8d492;
                        background-color: #fff;
                      }
                    }
                    div.label_div {            //ファイル選択
                      table-layout: fixed;
                      position: relative;
                      display: table;
                      width: 100%;
                      margin-bottom:10px;
                      padding-left: 10px;
                      .mail_label{
                        display: table-cell;
                        border: 1px solid #ebdfaf;
                        border-radius: 7px;
                        width: 130px;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        vertical-align: middle;
                        font-size: 14px;
                        font-weight: 500;
                        cursor: pointer;
                        transition:0.4s;
                        color:#c5a22b;
                        &:hover{
                          box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .1);
                          border: 1px solid #e8d492;
                        }
                        input{
                          display:none;
                        }
                      }
                      .statusBox{
                        overflow: hidden;
                        display: table-cell;
                        padding: 0 13px;
                        width: 545px;
                        vertical-align: middle;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        font-size: 16px;
                      }
                      .reset_button{
                        position: absolute;
                        top: 2px;
                        right: 0;
                        border: 1px solid #dfdfdf;
                        border-radius: 15px;
                        color: #888888;
                        font-size: 12px;
                        width: 73px;
                        height: 25px;
                        line-height: 25px;
                        background: url(../img/image-X.png) no-repeat left 12px top 7px;
                        background-size: 10px auto;
                        transition:0.4s;
                        padding-left:19px;
                        &:hover{
                          box-shadow: 1px 2px 4px 1px #f3f1ee;
                          border: 1px solid #d3d3d3;
                        }
                      }
                      &+p {
                        color: #888888;
                        font-size: 14px;
                        line-height: 14px;
                        padding-left: 9px;
                        letter-spacing: 1.5px;
                        margin-top: 11px;
                      }
                    }
                  }
                }
                &:first-of-type {
                  padding-bottom: 20px;
                  position: relative;
                }
                &:nth-of-type(2) { //お名前
                  >dt {

                  }
                  >dd {
                    &:nth-of-type(2) {
                      padding-top: 15px;
                      .cautionText {

                      }
                      input {
                        width: 300px;
                        border-radius: 5px;
                        height: 50px;
                        font-size: 16px;
                      }
                    }
                  }
                }
                &:nth-of-type(3) { //フリガナ
                  >dt {

                  }
                  >dd {
                    &:nth-of-type(2) {
                      padding-top: 15px;
                      .cautionText {

                      }
                      input {
                        width: 300px;
                        border-radius: 5px;
                        height: 50px;
                        font-size: 16px;
                      }
                    }
                  }
                }
                &:nth-of-type(4) { //メールアドレス
                  >dt {

                  }
                  >dd {
                    &:nth-of-type(2) {
                      padding-top: 15px;
                      .cautionText {

                      }
                      input {
                        width: 500px;
                        border-radius: 5px;
                        height: 50px;
                        font-size: 16px;
                      }
                    }
                  }
                }
                &:nth-of-type(5) {  //確認メール
                  padding-bottom: 18px;
                  >dt {

                  }
                  >dd {
                    &:nth-of-type(2) {
                      padding-top: 15px;
                      dl {
                        dd {
                          &:first-of-type {
                            .cautionText {

                            }
                            input {
                              width: 500px;
                              border-radius: 5px;
                              height: 50px;
                              font-size: 16px;
                            }
                          }
                          &:nth-of-type(2) {
                            display: block;
                            font-size: 14px;
                            color: #888888;
                            line-height: 14px;
                            margin-top: 7px;
                            padding-left: 10px;
                            letter-spacing: 1.5px;
                          }
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(6) {  //ご相談内容
                  border-bottom: 1px solid #f1f1f1;
                  >dt {

                  }
                  >dd {
                    &:nth-of-type(2) {
                      padding-top: 15px;
                      .cautionText {

                      }
                      textarea {
                        width: 750px;
                        background-color: #eeeeee;
                        border-radius: 5px;
                        height: 200px;
                        font-size: 16px;
                        color: #222;
                        resize: none;
                        margin-left: 10px;
                        padding-left: 16px;
                        letter-spacing: 1.5px;
                        padding-top: 13px;
                        &:focus{
                          border: 1px solid #e8d492;
                          background-color: #fff;
                        }
                      }
                    }
                  }
                }
                input:-webkit-input-placeholder{
                  color: #888;
                }
                input::-webkit-input-placeholder{
                  color: #888;
                }
                input:-ms-input-placeholder{
                  color: #888;
                }
                textarea:-webkit-input-placeholder{
                  color: #888;
                }
                textarea::-webkit-input-placeholder{
                  color: #888;
                }
                textarea:-ms-input-placeholder{
                  color: #888;
                }
              }
              >div {
                ul {
                  margin: 60px auto 151px;
                  li {
                    width: 100%;
                    width: 100%;
                    input {
                      margin:0 auto;
                      display: block;
                      background-color: #d1ae39;
                      border-radius: 7px;
                      width: 340px;
                      height: 80px;
                      background: transparent url(../img/arrow05.png) no-repeat center right 32px;
                      background-size: 11px auto;
                      line-height: 80px;
                      text-align: center;
                      font-size: 20px;
                      color: #fff;
                      letter-spacing: 2px;
                      transition: .4s;
                      &:hover{
                        box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .2);
                        background-color: #e2b623;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  簡単画像診断（入力内容のご確認）
  -------------------------------------*/
  #PageDiagnostic-imaging.pageConfirm {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            h3 {
              span {
                display: block;
                font-size: 20px;
                margin-top: 7px;
                letter-spacing: 4px;
              }
            }
            p {
              font-size: 16px;
              text-align: center;
              margin-top: 58px;
              letter-spacing: 1.5px;
              span {
                display: block;

              }
            }
            >form {
              margin-top: 50px;
              >dl {
                border-top: 1px solid #f1f1f1;
                padding-bottom: 25px;
                font-size: 0;
                letter-spacing: normal;
                >dt {
                  width: 200px;
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 30px;
                  font-size: 16px;
                  color: #222222;
                  padding-left: 10px;
                  letter-spacing: 2px;
                  line-height:20px;
                }
                >dd {
                  &:first-of-type {
                    width: 40px;
                    display: inline-block;
                    vertical-align: top;
                    color: #fefefe;
                    font-size: 12px;
                    background-color: #e42f2f;
                    text-align: center;
                    height: 20px;
                    line-height: 20px;
                    margin-top: 30px;
                  }
                  &:nth-of-type(2) {
                    display: inline-block;
                    font-size: 16px;
                    color: #222;
                    padding-top: 28px;
                    padding-left: 10px;
                    letter-spacing: 1.5px;
                  }
                }
                &:first-of-type {  //画像添付
                  padding-bottom: 14px;
                  >dt {

                  }
                  >dd {
                    padding-top: 0;
                    &:nth-of-type(2) {  //選択した画像名
                      >dl {
                        padding-top: 24px;
                        >dd {
                          &:first-of-type {

                          }
                          &:nth-of-type(2) {
                            margin-top: 16px;
                          }
                          &.note {
                            margin-top: 13px;
                            font-size: 14px;
                            color: #888888;

                          }
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(2) {  //お名前

                }
                &:nth-of-type(3) {  //フリガナ

                }
                &:nth-of-type(4) {  //メールアドレス

                }
                &:nth-of-type(5) {  //ご相談内容
                  border-bottom: 1px solid #f1f1f1;

                }
              }
              >div {
                ul {
                  margin: 62px auto 30px;
                  li {
                    width: 100%;
                    width: 100%;
                    &.confirm{
                      input {
                        margin:0 auto;
                        display: block;
                        background-color: #d1ae39;
                        border-radius: 7px;
                        width: 340px;
                        height: 80px;
                        background: transparent url(../img/arrow05.png) no-repeat center right 32px;
                        background-size: 11px auto;
                        line-height: 80px;
                        text-align: center;
                        font-size: 20px;
                        color: #fff;
                        letter-spacing: 2px;
                        transition: .4s;
                        &:hover{
                          box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .2);
                          background-color: #e2b623;
                        }
                      }
                    }
                  }
                }
              }
              >ul {
                margin-bottom: 93px;
                li {
                  &.reset{
                    input{
                      width: 150px;
                      height: 40px;
                      border: 1px solid #e5e5e5;
                      border-radius: 7px;
                      margin: 0 auto;
                      display: block;
                      text-align: center;
                      line-height: 40px;
                      font-size: 14px;
                      color: #888888;
                      text-decoration: none;
                      transition:0.4s;
                      &:hover{
                        box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .1);
                        border: 1px solid #cfcfcf;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  簡単画像診断（送信完了）
  -------------------------------------*/
  #PageDiagnostic-imaging.pageThanks {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            margin-bottom: -30px;
            h3 {
              text-align: center;
              font-size: 32px;
              letter-spacing: 5px;
              margin-bottom: 66px;
              span {
                display: block;
                font-size: 20px;
                margin-top: 7px;
                letter-spacing: 4px;
              }
            }
            p {
              text-align: center;
              font-size: 16px;
              letter-spacing: 1.5px;
              line-height: 16px;
              &:nth-of-type(2) {
                margin-top: 36px;
                span {
                  display: block;
                  margin-top: 17px;
                }
              }
            }
            ul {
              margin-top: 58px;
              li {
                width: 250px;
                height: 50px;
                margin: 0 auto;
                text-align: center;
                a {
                  display: block;
                  width: 100%;
                  height: 100%;
                  line-height: 50px;
                  font-size: 16px;
                  color: #c5a22b;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  動画解説
  -------------------------------------*/
	#PageMovie {
		#MainImg{
			#MainImgInner{
				background-image: url(../img/lower_mainimg12.jpg);
			}
		}
		#Container {
			#Main{
				margin-bottom: 144px;
				#MovieNav{
					margin: 64px auto 90px;
					border: 1px solid #e5e5e5;
					border-radius: 6px;
					width: 1000px;
					height: 50px;
					line-height: 50px;
					ul{
						letter-spacing: normal;
						font-size: 0;
						li{
							position: relative;
							display: inline-block;
							width: calc(100% / 4);
							text-align: center;
							font-size: 14px;
							&.current{
								a{
									color: #ac832d;
								}
								span::after{
									content: "";
									position: absolute;
									bottom: 2px;
									left: 0;
									display: block;
									width: 100%;
									height: 2px;
									background-color: #e7d48a;
								}
							}
							&+ li:before{
								content: "";
								position: absolute;
								top: 0;
								bottom: 0;
								left: 0;
								display: block;
								margin: auto;
								width: 1px;
								height: 30px;
								background-color: #f1f1f1;
							}
							a{
								display: block;
								width: 100%;
								height: 100%;
								text-decoration: none;
								letter-spacing: 1px;
								color: #888;
								&:hover {
									color: #c5a22b;
								}
							}
							span{
								position: relative;
								display: inline-block;
							}
						}
					}
				}
				h3{
					margin-top: -6px;
					margin-bottom: 50px;
				}
				.movieiList{
					#MovieBlock{
						&::after{
							content:"";
							display:block;
							clear:both;
						}
						li{
							float: left;
							margin-right: 1px;
							margin-bottom: 50px;
							width: 249px;
							&:nth-of-type(4n){
								margin-right: 0;
							}
							&:nth-of-type(4n+1){
								clear: both;
							}
							dl{
								dt{
									cursor: pointer;
									position: relative;
									margin-bottom: 2px;
									width: 249px;
									height: 139px;
									background-repeat: no-repeat;
									background-position: center center;
									background-size: cover;
									&::before{
										content: "";
										position: absolute;
										top: 0;
										left: 0;
										width: 100%;
										height: 100%;
										background-repeat: no-repeat;
										background-position: center center;
										background-image: url("../img/icon_movie.png");
										background-size: 46px auto;
										background-color: rgba(0, 0, 0, .05);
									}
								}
								dd{
									padding-right: 30px;
									overflow: hidden;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 2;
									line-height: 20px;
									font-family: helvetica,"arial black",arial,sans-serif;
									color: #888;
									&:hover{
										color: #c5a22b;
									}
									a{
										text-decoration: none;
										font-family: "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
										font-size: 12px;
										color: #888;
										&:hover{
											color: #c5a22b;
										}
									}
								}
							}
						}
					}
				}
				.viewBtn {
					margin: 0 auto;
					width: 250px;
					height: 50px;
					line-height: 50px;
					cursor: pointer;
					div{
						background-repeat: no-repeat;
						background-position: center right 20px;
						background-image: url(../img/icon_plus.png);
						background-size: 15px auto;
						font-size: 16px;
					}
				}
			}
		}
		#cover {
			display:none;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height:100%;
			background-color: rgba(0,0,0,0.3);
			z-index: 99999;
		}
		#coverVideo {
			display:none;
			position: fixed;
			left: 0;
			top: 0;
			box-sizing: border-box;
			padding: 60px;
			width: 100%;
			height:100%;
			z-index: 99999;
			text-align: center;
			&:before {
				content: '';
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}
		}
		#coverVideoClose {
			position: absolute;
			top: -95px;
			right: -94px;
			width: 34px;
			height: auto;
			z-index: 9999999;
			cursor: pointer;
		}
		#coverVideoWrapContent {
          border-radius: 6px;
		  padding: 60px;
		  width: 100%;
		  max-width: 1000px;
		  background-color: #fff;
		  position: relative;
		  display: inline-block;
		  vertical-align: middle;
		  margin: 0 auto;
		}
		#coverVideoContent {
			position: relative;
			margin: auto;
			padding-top: 56.25%;
			width: 100%;
			height: 0;
			iframe{
				position:absolute;
				display: block;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

  /*
  動画解説（トップページ）
  -------------------------------------*/
  #PageMovie.pageIndex {
    #Container {
      #Main{
        #ContBox02{
		  }
		}
	  }
	}

  /*
  動画解説（カテゴリ）
  -------------------------------------*/
  #PageMovie.pageCategory {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {	
			}
		  }
		}
	  }
	}

  /*
  動画解説（詳細）
  -------------------------------------*/
  #PageMovie.pageEntry {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
			  .mainContTitle{
				  h3{
					margin-top: -9px;
					margin-bottom: 60px;
					letter-spacing: 3px;
					border-bottom: 3px solid #f1f1f1;
					padding: 0 0 21px 0;
					line-height: 48px;
					text-align: left;
					font-family: "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
					font-size: 32px;
					font-weight: 500;
				  }
			  }
			  #ContSubBox01{
				  border-radius: 6px;
				  border: 1px solid #e5e5e5;
				  padding: 59px;
				  .movieBox{
					position: relative;
					margin: 0 auto 50px;
					padding-top: 56.25%;
					width: 100%;
					height: 0;
					iframe{
						position:absolute;
						display: block;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				  }
			  }
			}
		  }
		}
	  }
	}
}
@media print,
screen and (max-width: 1000px) {
  /*
  動画解説
  -------------------------------------*/
	#PageMovie {
		#coverVideoClose {
			right: -59px;
		}
	}
}
@media print,
screen and (max-width: 767px) {
  /*
  共通
  -------------------------------------*/
  #Main{
    .concernBlock{
      margin:0 auto;
      h3,h2{
        font-size:24px;
        span{
          color:#ac832d;
          display:block;
          font-size:14px;
          font-family: "Roboto Slab", serif;
          font-weight: 300;
          letter-spacing:2px;
          margin-bottom:4px;
        }
      }
      .contSubBox{
        figure{
          img{
            width:100%;
            height:auto;
          }
        }
        h4{
          position:relative;
          width:160px;
          height:40px;
          line-height:40px;
          text-align:center;
          margin:-20px auto 0;
          font-size:18px;
          color:#ac832d;
          background-color:#fff;
          border-top:1px solid #e7d48a;
          border-bottom:1px solid #e7d48a;
          font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
          font-weight: 400;
        }
        ul{
          margin:15px 0 35px;
          padding:0 15px;
          li{
            &+li{
              border-top:1px solid #f1f1f1;
            }
            a{
              display:block;
              font-size:16px;
              color:#888;
              text-decoration:none;
              line-height:20px;
              padding:10px 0;
              background-image:url(../img/arrow02.png);
              background-repeat:no-repeat;
              background-size:7px auto;
              background-position:center right 5px;
            }
          }
        }
        &:last-of-type{
          ul{
            border:none;
          }
        }
      }
    }
    .catBox{
      border:1px solid #e5e5e5;
      border-radius:6px;
      position:relative;
      padding:30px 30px 22px;
      .imgTitle{
        display:table;
        table-layout:fixed;
        //margin:0 auto;
        figure,dl{
          display:table-cell;
          vertical-align:top;
        }
      }
      figure{
        width:135px;
        img{
          width:100%;
          height:auto;
        }
      }
      dl{
        padding-left:15px;
        dt{
          font-size:20px;
          line-height:30px;
          margin-bottom:5px;
          a{
            color:#222;
            text-decoration:none;
          }
        }
      }
      p{
        font-size:14px;
        line-height:24px;
        color:#888;
        margin-top:10px;
        &.price{
          color:#aaa;
          margin-top:0;
        }
      }
      &.topCatBox{
        figure{
          width:100%;
        }
        .txtTitle{
          div{
            color:#888;
            font-size:16px;
            line-height:28px;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            border-bottom:3px solid #f1f1f1;
            padding:25px 0 8px;
            margin-bottom:15px;
            letter-spacing: 3px;
          }
          dl{
            padding:0;
            dt{
              font-size:24px;
              margin-right:10px;
            }
          }
        }
      }
    }
    .typeList{
      margin-bottom:8px;
      li{
        display:inline-block;
        margin-top:5px;
        margin-right:5px;
        color:#ac832d;
        font-size:12px;
        padding:0 5px 0 20px;
        height:20px;
        //height:20px;
        //line-height:20px;
        line-height:18px;
        border:1px solid #e9dcb9;
        background-color:#fbf6e3;
        background-repeat:no-repeat;
        background-position:center left 5px;
        letter-spacing:0;
        &.make{
          background-image:url(../img/treatment_icon_make.png);
          background-size:13px auto;
        }
        &.pain{
          background-image:url(../img/treatment_icon_smile.png);
          background-size:12px auto;
        }
        &.introduction{
          background-image:url(../img/treatment_icon_people.png);
          background-size:14px auto;
        }
        &.monitor{
          background-image:url(../img/treatment_icon_monitor.png);
          background-size:12px auto;
        }
        &.lank01,&.lank02,&.lank03,&.rank01,&.rank02,&.rank03{
          background-image:url(../img/treatment_icon_crown.png);
          background-size:13px auto;
        }
        &.staff{
          background-image:url(../img/treatment_icon_staff.png);
          background-size:9px auto;
        }
        &.cost{
          background-image:url(../img/treatment_icon_cost.png);
          background-size:11px auto;
        }
        &.heart{
          background-image:url(../img/treatment_icon_heart.png);
          background-size:12px auto;
        }
        &.time{
          background-image:url(../img/treatment_icon_time.png);
          background-size:12px auto;
        }
        &.repeat{
          background-image:url(../img/treatment_icon_repeat.png);
          background-size:12px auto;
        }
      }
    }
    .underLink{
      background-color:#f9f8f5;
      padding:15px 15px 25px;
      li{
        display:inline-block;
        height:40px;
        line-height:40px;
        margin-right:4px;
        margin-top:10px;
        a{
          padding:0 15px;
          background-color:#fff;
          &:before{
            content:"";
            display:inline-block;
            width:13px;
            height:40px;
            vertical-align:top;
            background-image:url(../img/arrow01.png);
            background-size:13px auto;
            background-repeat:no-repeat;
            background-position:center left;
            margin-right:8px;
          }
        }
      }
    }
    .boxTitle{
      position:relative;
      border-top:1px solid #e5e5e5;
      padding:60px 15px 0;
      margin-bottom:40px;
      h3{
        font-size:28px;
        line-height:40px;
        text-align:left;
        position:relative;
        span{
          font-family: 'Roboto Slab', serif;
          font-weight: 300;
          color:#ac832d;
          font-size:18px;
          display:block;
          letter-spacing:1.5px;
        }
      }
    }
    .newsList{
      margin:25px auto 0;
      border-top:1px solid #dbdbdb;
      li{
        border-bottom:1px solid #f1f1f1;
        a{
          display:block;
          width:100%;
          padding:10px 0;
          text-decoration:none;
          dl{
            dt{
              font-size:16px;
              line-height:28px;
              color:#222;
            }
            dd{
              font-size:14px;
              line-height:28px;
              color:#aaa;
              width:105px;
            }
          }
        }
      }
    }
    .columnSliderWrap{
      margin-top:37px;
      .bx-wrapper{
        position: relative;
        .bx-viewport{
        }
        .bx-prev,.bx-next{
          display:block;
          position:absolute;
          top:15%;
          margin:auto;
          width:60px;
          height:60px;
          text-indent:-9999px;
          text-decoration:none;
          border-radius:50%;
          background-color:#fff;
          border:1px solid #f0e8c8;
          box-shadow:0px 0px 10px 0px rgba(0,0,0,0.05);
          background-repeat:no-repeat;
          background-size:9px auto;
          background-position:center center;
          &:hover{
            border:1px solid #e8d492;
            box-shadow:0px 0px 20px 0px rgba(0,0,0,0.1);
          }
        }
        .bx-prev{
          background-image:url(../img/arrow03.png);
          left:-10px;
        }
        .bx-next{
          background-image:url(../img/arrow04.png);
          right:-10px;
        }
        .bx-pager{
          margin-top:10px;
          text-align:center;
          .bx-pager-item{
            display:inline-block;
            vertical-align:top;
            width:12px;
            height:12px;
            &+.bx-pager-item{
              margin-left:18px;
            }
            a{
              display:block;
              width:100%;
              height:100%;
              border-radius:50%;
              text-indent:-9999px;
              background-color:#dadada;
              &.active{
                background-color:#dccb87;
              }
            }
          }
        }
      }
    }
    .columnSlider{
      .columnSliderBox{
        border:1px solid #e5e5e5;
        border-radius:6px;
        position:relative;
        padding:30px 30px 22px;
        background-color:#fff;
        margin:0 30px 0 0;
        figure{
          width:100%;
          img{
            width:100%;
            height:auto;
          }
        }
        .txtTitle{
          dl{
            margin: 22px 0 4px;
            dt{
              font-size:24px;
              line-height:35px;
              margin-top:6px;
              a{
                text-decoration:none;
                color:#222;
              }
            }
            dd{
              font-size:20px;
              font-family: "Roboto Slab", serif;
              font-weight: 300;
              color:#ac832d;
            }
          }
        }
        p{
          font-size:14px;
          line-height:24px;
          color:#888;
          &.price{
            color:#aaa;
          }
        }
      }
    }
    .underCommonGuide{
      border:4px solid #f1f1f1;
      width:auto;
      margin:0 15px;
      padding:35px 15px;
      &+.underCommonGuide{
        margin-top:30px;
      }
      dl{
        &+dl{
          margin-top:35px;
        }
        dt{
          font-size: 16px;
          line-height: 28px;
          font-weight: 700;
          color:#ac832d;
          border-bottom:1px solid #e7d48a;
          margin-bottom:27px;
          padding-bottom:15px;
          letter-spacing: 0.1em;
        }
        dd{
          font-size: 16px;
          line-height: 28px;
          img{
            max-width: 100%;
            height: auto;
          }
          a{
            padding-right: 24px;
            background: url(../img/icon_link.png) no-repeat right center;
            background-size: 15px auto;
            font-size: 16px;
          }
          &.minorLink{
            text-align: right;
            a{
              background:none;
              padding-right: 0;
            }
          }
        }
      }
    }
  }
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #TopNavImg{
      width:100%;
      margin:0 auto;
      #GlobalNav{
        display:none;
      }
/*      #TopMainImg{
        position:relative;
        .mainImgSlider{
          li{
            position:relative;
            img{
              width:100%;
              height:auto;
            }
            .mainTxt{
              padding-left:45px;
              padding-top:25px;
              text-align:left;
              font-size:21px;
              color:#ac832d;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              line-height:30px;
              letter-spacing:8px;
              position:relative;
              &:before{
                content:"";
                display:block;
                width:72px;
                height:84px;
                background-image:url(../img/slide_logo.png);
                background-repeat:no-repeat;
                background-position:top left;
                background-size:72px auto;
                position:absolute;
                left:15px;
                top:-8px;
              }
              span{
                position:relative;
              }
            }
          }
        }
        .bx-wrapper{
          position:relative;
          .bx-viewport{
            overflow:visible!important;
          }
          .topLink{
            display:none;
          }
          .bx-pager{
            z-index:100;
            position:absolute;
            right:15px;
            bottom:145px;
            text-align:left;
            .bx-pager-item{
              display:inline-block;
              vertical-align:top;
              width:35px;
              height:2px;
              &+.bx-pager-item{
                margin-left:4px;
              }
              a{
                display:block;
                width:100%;
                height:100%;
                text-indent:-9999px;
                background-color:#e6e6e6;
                &.active{
                  background-color:#e7d48a;
                }
              }
            }
          }
        }
        .topSpLink{
          width:285px;
          height:40px;
          line-height:40px;
          z-index:55;
          margin:25px auto 0;
          a{
            display:block;
            width:100%;
            height:100%;
            background: linear-gradient(to right, #a3781d 0%,#c8a743 36%,#dcc77f 67%,#c9a536 100%);
            border-radius:40px;
            text-align:center;
            position:relative;
            font-size:14px;
            text-decoration:none;
            color:#fff;
            position:relative;
            &:after{
              content:"";
              display:inline-block;
              width:7px;
              height:40px;
              position:absolute;
              top:0;
              right:15px;
              background-image:url(../img/arrow05.png);
              background-repeat:no-repeat;
              background-size:7px auto;
              background-position:center right;
            }
          }
        }
      }
*/    }
      #TopMainImg{
      width:100%;
      margin:0 auto;
		  .video_item{
			  width: 100%;
		  }
		}
    #Main{
      margin-top:40px;
      .h3{
        span{
          color:#ac832d;
          display:block;
          font-size:20px;
          font-family: "Roboto Slab", serif;
          font-weight: 300;
          letter-spacing:2px;
        }
      }
      .subTitle{
        font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
        font-weight: 400;
        font-size:14px;
        color:#888;
        text-align:center;
        margin-bottom:13px;
        letter-spacing: 2px;
      }
      .note{
        color:#888;
        margin-top:20px;
        letter-spacing:3.5px;
      }
      .linkBtn{
        width:250px;
        height:50px;
        line-height:50px;
        margin:35px auto 0;
        a{
          font-size:16px;
          background-color:#fff;
        }
      }
      #ContBox01{
        margin-top: 55px;
        .contSubBox{
          border-bottom:none;
        }
        h2{
          margin-bottom:40px;
          span{
            font-size:14px;
          }
        }
      }
      #ContBox02{
        margin-top:75px;
        background:url(../img/top_mission.jpg) no-repeat center center;
        background-size:cover;
        padding:50px 15px 60px;
        h2{
          span{
            font-size:26px;
          }
        }
        p{
          text-align:center;
          font-size:18px;
          line-height:35px;
          font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
          font-weight: 400;
          color:#fff;
          margin-top:28px;
          letter-spacing:3px;
          text-shadow:0px 0px 10px rgba(0,0,0,0.6);
          &+p{
            margin-top:35px;
          }
        }
      }
      #ContBox03{
        margin-top:60px;
        padding:0 15px;
        h2,h2 span{
          font-size:24px;
          line-height:1;
          span{
            margin-top:10px;
            letter-spacing: 5px;
          }
        }
        .topCatBlock{
          margin-top:20px;
          overflow:hidden;
          .catBox{
            position:relative;
            margin-top:15px;
            .rankIcon{
              position:absolute;
              top:18px;
              left:18px;
              width:45px;
              height:45px;
              z-index:2;
              display:block;
              background-color:#f5f5f5;
              border-radius:50%;
              text-align:center;
              line-height:45px;
              font-family: "Roboto Slab", serif;
              font-size: 18px;
              font-weight: 300;
              color:#aaa;
              &.rankIcon01{
                width:60px;
                height:60px;
                line-height:60px;
                font-size:24px;
                color:#fff;
                background: linear-gradient(45deg, #b58b32 0%,#dabe68 36%,#ebd896 67%,#cfb054 100%);
              }
              &.rankIcon02{
                color:#fff;
                background: linear-gradient(45deg, #818181 0%,#adaeae 36%,#e7e7e8 67%,#b7b8b7 100%);
              }
              &.rankIcon03{
                color:#fff;
                background: linear-gradient(45deg, #b87d37 0%,#d5a46a 36%,#e8c7a1 67%,#deaa6d 100%);
              }
            }
          }
          .topCatBox{
            width:100%;
            float:none;
          }
        }
        .linkBtn {
          margin: 30px auto 0;
          width: 130px;
          height: 40px;
          line-height: 40px;
          a{
            font-size: 14px;
          }
        }
      }
      #ContBox04{
        margin-top:35px;
        padding:0 30px;
        h2{
          font-size:14px;
          span{
            font-size:26px;
          }
        }
        .linkBtn{
          width:130px;
          height:40px;
          line-height:40px;
          margin:30px auto 0;
          a{
            font-size:14px;
          }
        }
      }
      #ContBox05{
        padding:54px 15px 70px;
        margin-top:62px;
        background-color:#f7f6f1;
        background:url(../img/clum_bg_ue.png) no-repeat center top,url(../img/clum_bg_shita.png) no-repeat center bottom;
        background-size:contain,contain;
        h2{
          font-size:24px;
        }
        .blogList{
          background-color:#fff;
          margin-top:30px;
        }
      }
      #ContBox06{
        margin-top: 50px;
        h2{
          font-size: 14px;
          margin-bottom: 25px;
          span {
            font-size: 27px;
          }
        }
        .bnrBlogList{
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          padding: 0 15px;
          li{
            width: calc( (100% / 2) - 8px );
            margin-right: 16px;
            margin-bottom: 15px;
            &:nth-of-type(even){
              margin-right: 0;
            }
            a{
              display: block;
            }
            figure{
              width: 100%;
              height: auto;
              img{
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }
    }
  }

  /*
  はじめての方へ
  -------------------------------------*/
  #PageWelcome.pageIndex {
    #Main{
      .innerBasic{
        margin:0 15px;
      }
      margin-bottom:84px;
      #ContBox01{
        margin-top:75px;
        margin-bottom:40px;
        dl{
          color:#888;
          font-size:20px;
          line-height: 36px;
          font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
          font-weight: 400;
          text-align:center;
          margin:42px 0 36px;
          letter-spacing:2px;
        }
        p+p{
          margin-top:20px;
        }
        .contSubBox{
          padding:0 15px;
          margin:0 auto;
          h4{
            font-size:28px;
            color:#ac832d;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            border-bottom:3px solid #f1f1f1;
            padding-bottom:15px;
            line-height:40px;
            margin-bottom:25px;
            letter-spacing:4px;
          }
          img{
            width:100%;
            height:auto;
          }
        }
        #ContSubBox01{
          div{
            padding-top:25px;
            text-align:right;
            p{
              margin-bottom:20px;
            }
            img{
              margin-right:-15px;
              width:73%;
              height:auto;
            }
          }
        }
        #ContSubBox02{
          margin-top:38px;
          .txt{
            margin:0 auto;
            h4,p{
              margin-left:auto;
            }
          }
          .img{
            margin:37px -15px 0;
            position:relative;
            img{
              width:100%;
              height:auto;
            }
            figure{
              &:first-of-type{
                width:67%;
              }
              &:nth-of-type(2){
                padding-top:45px;
                width:83%;
                margin-left:auto;
              }
            }
          }
        }
        #ContSubBox03{
          margin-top:38px;
          padding:0 15px 0 0;
          h4{
          }
          .txt{
            padding-left:15px;
          }
          .img{
            margin-top:24px;
            width:100%;
            display:table;
            table-layout:fixed;
            figure{
              display:table-cell;
              vertical-align:top;
              &:first-of-type{
                width:74%;
                padding-right:38px;
                padding-top:25px;
              }
              &:nth-of-type(2){
                width:26%;
              }
            }
          }
        }
        #ContSubBox04{
          margin-top:38px;
        }
      }
      #FirstNav{
        border:1px solid #e5e5e5;
        margin-top:60px;
        border-radius:6px;
        ul{
          font-size:0;
          letter-spacing:normal;
          li{
            width:50%;
            height:62px;
            line-height:24px;
            display:inline-block;
            font-size:16px;
            text-align:center;
            position:relative;
            padding-top:8px;
            border-bottom:1px solid #e5e5e5;
            &:nth-child(odd){
              border-right:1px solid #e5e5e5;
            }
            &:last-child,&:nth-last-child(2):nth-child(odd){
              border-bottom:none;
            }
            a{
              display:block;
              width:100%;
              height:100%;
              color:#888;
              text-decoration:none;
              letter-spacing:0.1px;
            }
            span{
              position:relative;
              display:inline-block;
              padding-bottom:7px;
              &:after{
                content:"";
                display:none;
                width:100%;
                height:2px;
                background-color:#e7d48a;
                position:absolute;
                bottom:0;
                left:0;
              }
            }
          }
        }
      }
    }
  }

  /*
  遠方からお越しのお客様へ
  -------------------------------------*/
  #PageDistant{
    #Main{
      .innerBasic{
        margin:0 15px;
      }
      #FirstNav{
        border:1px solid #e5e5e5;
        margin-top:60px;
        border-radius:6px;
        ul{
          font-size:0;
          letter-spacing:normal;
          li{
            width:50%;
            height:62px;
            line-height:24px;
            display:inline-block;
            font-size:16px;
            text-align:center;
            position:relative;
            padding-top:8px;
            border-bottom:1px solid #e5e5e5;
            &:nth-child(odd){
              border-right:1px solid #e5e5e5;
            }
            &:last-child,&:nth-last-child(2):nth-child(odd){
              border-bottom:none;
            }
            a{
              display:block;
              width:100%;
              height:100%;
              color:#888;
              text-decoration:none;
              letter-spacing:0.1px;
            }
            span{
              position:relative;
              display:inline-block;
              padding-bottom:7px;
              &:after{
                content:"";
                display:none;
                width:100%;
                height:2px;
                background-color:#e7d48a;
                position:absolute;
                bottom:0;
                left:0;
              }
            }
          }
        }
      }
    }
  }
  #PageWelcome.pageIndex #Main #FirstNav ul li:first-child,
  #PageDistant.pageIndex #Main #FirstNav ul li:nth-child(2){
    a{
      color:#ac832d;
    }
    span:after{
      display:block;
    }
  }
  // ここから本文
  #PageDistant.pageIndex {
    #Main{
      .contBox{
        h3{
          font-size:28px;
          margin-top:68px;
          margin-bottom:42px;
          letter-spacing:0.2em;
          text-align:center;
        }
      }
      #ContBox01{
        .txt{
          margin:0 auto 38px;
          p{
            font-size:16px;
            line-height:28px;
            letter-spacing:0.1em;
          }
          p+p{
            margin-top:20px;
          }
        }
      }
      #ContBox02{
        border:5px solid #f1f1f1;
        padding:32px 15px;
        margin-bottom:82px;
        dt{
          margin-bottom:30px;
          border-bottom:1px solid #e7d48a;
          padding-bottom:18px;
          line-height:28px;
          letter-spacing:0.1em;
          font-size:16px;
          font-weight:700;
          color:#ac832d;
        }
        img{
          width:100%;
        }
        dd.part00{
          line-height:32px;
          font-size:16px;
          margin-top:0;
          letter-spacing:0.05em;
          padding:20px 0 23px 40px;
          border-bottom:1px solid #f1f1f1;
          &.part01{background-image:url(../img/enpou/icon_form.gif)}
          &.part02{background-image:url(../img/enpou/icon_hotel.gif)}
          &.part03{background-image:url(../img/enpou/icon_travel.gif)}
          background-repeat:no-repeat;
          background-position:-14px 2px;
          background-size:55px;
          &.part02{
            letter-spacing:0.1em;
          }
        }
        // dd.part00+dd.part00{
        //   margin-top:0px;
        // }
        p{
          font-size:16px;
          line-height:32px;
          letter-spacing:0.05em;
          margin-top:20px;
        }
      }
    }
  }




  /*
  クリニック メディア 共通
  -------------------------------------*/
  #PageMedia,
  #PageAbout{
    #Main{
      #ClinicNav{
        border:1px solid #e5e5e5;
        margin:0 15px;
        border-radius:6px;
        ul{
          font-size:0;
          letter-spacing:normal;
          li{
            width:50%;
            height:40px;
            line-height:40px;
            display:inline-block;
            font-size:14px;
            text-align:center;
            position:relative;
            border-bottom:1px solid #e5e5e5;
            &:nth-child(odd){
              border-right:1px solid #e5e5e5;
            }
            //ドクター紹介表示のためコメントアウト
            // &:last-child,&:nth-last-child(2):nth-child(odd){
            //   border-bottom:none;
            // }

            /********************************/
            //ドクター紹介非表示のため調整
            &:first-of-type{
              border-right:1px solid #e5e5e5;
            }
            &:nth-child(even){
              border-right:1px solid #e5e5e5;
            }
            &:nth-last-child(2):nth-child(odd){
              border-bottom:1px solid #e5e5e5;
            }
            /********************************/
            
            a{
              display:block;
              width:100%;
              height:100%;
              color:#888;
              text-decoration:none;
              letter-spacing:1px;
            }
            span{
              position:relative;
              display:inline-block;
              &:after{
                content:"";
                display:none;
                width:100%;
                height:2px;
                background-color:#e7d48a;
                position:absolute;
                bottom:0;
                left:0;
              }
            }
          }
        }
      }
    }
  }
  #PageAbout.pageIndex #Main #ClinicNav ul li:first-child,
  #PageAbout.pageDoctorIndex #Main #ClinicNav ul li:nth-child(2),
  #PageAbout.pageStaffIndex #Main #ClinicNav ul li:nth-child(3),
  #PageAbout.pageClinicIndex #Main #ClinicNav ul li:nth-child(4),
  #PageAbout.pageRecruitIndex #Main #ClinicNav ul li:nth-child(5),
  #PageMedia #Main #ClinicNav ul li:nth-child(6){
    a{
      color:#ac832d;
    }
    span:after{
      display:block;
    }
  }
  /*
  クリニックについて
  -------------------------------------*/
  #PageAbout.pageIndex {
    #Main{
      margin-bottom:60px;
      .contBox{
        h3{
          margin-top:70px;
          margin-bottom:37px;
        }
      }
      #ContBox01{
        padding:0 15px;
        ol{
          border-top:1px solid #e7d48a;
          border-bottom:1px solid #e7d48a;
          margin:0 auto;
          list-style: none;
          background-image:url(../img/logo.png);
          background-repeat:no-repeat;
          background-size:98px auto;
          background-position:top 35px center;
          padding:178px 15px 30px;
          li{
            color:#ac832d;
            font-size:20px;
            line-height:30px;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            letter-spacing:3.5px;
            padding-left:2em;
            text-indent:-2em;
            position:relative;
            &+li{
              margin-top:20px;
            }
            span{
              //position:absolute;
              //top:0;
              //left:0;
              font-family: 'Roboto Slab', serif;
              font-weight: 300;
              font-size:32px;
              font-style: italic;
              padding-right:8px;
            }
            &:first-child{
              padding-left:2.7em;
              text-indent:-2.5em;
              span{
                left:5px;
              }
            }
          }
        }
      }
      #ContBox02{
        .titleTmg{
          position:relative;
          text-align:center;
          overflow:hidden;
          img{
            height:auto;
            width:100%;
          }
          h4{
            color:#888;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            font-size:31px;
            letter-spacing:12px;
            text-align:center;
            display:inline-block;
            margin-top:35px;
            span{
              &.span_h4{
                font-family: 'Roboto Slab', serif;
                font-weight: 300;
                display:block;
                position:relative;
                padding-left:45px;
                font-size:18px;
                letter-spacing:1.8px;
                margin-left:78px;
                margin-top:10px;
                &:before{
                  content:"";
                  display:block;
                  height:1px;
                  width:38px;
                  background-color:#888;
                  position:absolute;
                  top:0;
                  bottom:0;
                  left:0;
                  margin:auto;
                }
              }
            }
          }
        }
        .txt{
          margin:35px 15px 0;
          p+p{
            margin-top:20px;
          }
        }
      }
      #ContBox03{
        table{
          margin:0 auto;
          width:100%;
          td,th{
            display:block;
            font-size:16px;
            line-height:28px;
            padding:12px 15px 20px;
          }
          th{
            border-top:1px solid #e5e5e5;
            border-bottom:1px solid #e5e5e5;
            background-color:#f5f5f5;
            padding:10px 15px;
          }
        }
      }
    }
  }

  /*
  ドクター紹介
  -------------------------------------*/
  #PageAbout.pageDoctorIndex {
    #Main{
      margin-bottom: 90px;
      h3{
        margin:66px 0 37px;
      }
      img{
        width:100%;
      }
      #ContBox01{
        padding:0 15px;
        // 19/04/11谷岡作業ここから
        .contLinkBox{
          background-color: #f9f8f5;
          margin-bottom: 60px;
          padding: 30px 0;
          ul{
            margin: 0 15px;
            &::after{
              content: "";
              display:block;
              clear: both;
            }
            li{
              margin: auto;
              margin-bottom: 15px;
              border-radius: 5px;
              border: 1px solid #e7d48a;
              background-color: #fff;
              width: 100%;
              height: 89px;
              &:last-of-type{
                margin-bottom: 0;
              }
              a{
                text-decoration: none;
                dl{
                  padding-top: 20px;
                  padding-bottom: 16px;
                  padding-left: 98px;
                  position: relative;
                  &:before{
                    content: "";
                    display: block;
                    position: absolute;
                    left: 15px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 70px;
                    height: 70px;
                  }
                  &::after{
                    content: "";
                    display: block;
                    background: url(../img/contents/arrow01.png) 0 0 / 16px auto no-repeat;
                    width: 16px;
                    height: 8px;
                    position: absolute;
                    right: 15px;
                    bottom: 0;
                    top: 0;
                    margin: auto;
                  }
                  dt{
                    color: #c5a22b;
                    font-size: 16px;
                    margin-bottom: 3px;
                  }
                  dd{
                    color: #c5a22b;
                    font-size: 16px;
                  }
                }
              }
            }
            .list01 a dl::before{
              background:  url(../img/contents/doctor01_02.png) 0 0 / 70px 70px no-repeat;
            }
            .list02 a dl::before{
              background:  url(../img/contents/doctor03_02.png) 0 0 / 70px 70px no-repeat;
            }
            .list03 a dl::before{
              background:  url(../img/contents/doctor06.png) 0 0 / 70px 70px no-repeat;
            }
          }
        }
        // ここまで
        .contSubBox{
          border:1px solid #e5e5e5;
          border-radius:6px;
          padding:42px 30px;
          &+.contSubBox{
            margin-top:30px;
          }
          .topProfile{
            position:relative;
            text-align:center;
            .nameImg{
              display:table;
              margin:0 auto;
              figure{
                width:140px;
                display:table-cell;
                vertical-align:top;
                img{
                  width:100%;
                  height:auto;
                }
              }
              div{
                padding-top:26px;
                display:table-cell;
                vertical-align:top;
                text-align:left;
                padding-left: 12px;
                //width:130px;
                p,h4{
                  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                  font-weight: 400;
                }
                p{
                  font-size:16px;
                  color:#888;
                }
                >span{
                  color:#888;
                  font-size:14px;
                }
                h4{
                  font-size:20px;
                  margin: 3px 0;
                  letter-spacing: 2px;
                  span{
                    display:block;
                    color:#b58b32;
                    font-size:10px;
                    font-family: 'Roboto Slab', serif;
                    font-weight: 300;
                    letter-spacing: 1px;
                  }
                }
              }
            }
            >dl{
              margin-top:27px;
              text-align:left;
              >dt{
                display:inline-block;
                font-size:14px;
                font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                font-weight: 400;
                border-bottom:1px solid #222;
                padding-bottom:5px;
                margin-bottom:24px;
              }
              >dd{
                dl{
                  width:100%;
                  display:table;
                  &+dl{
                    margin-top:7px;
                  }
                  dt,dd{
                    display:table-cell;
                    vertical-align:top;
                    font-size:14px;
                    line-height:24px;
                    ul{
                      li+li{
                        margin-top:10px;
                      }
                    }
                  }
                  dt{
                    width:65px;
                  }
                }
              }
            }
          }
          .videoBox{
            margin-top: 30px;
            position: relative;
            width: 100%;
            padding-top: 56.25%;
            iframe{
              position: absolute;
              top: 0;
              right: 0;
              width: 100%!important;
              height: 100%!important;
            }
          }
          .bottomProfile{
            margin-top:24px;
            .right{
              width:250px;
              text-align:right;
              margin:0 auto;
              img{
                width:100%;
                height:auto;
              }
            }
            .clickTitle_sp{
              width:220px;
              height:40px;
              line-height:40px;
              border:1px solid #e7d48a;
              border-radius:6px;
              text-align:center;
              font-size:14px;
              color:#c5a22b;
              background-image:url(../img/icon_plus.png);
              background-repeat:no-repeat;
              background-size:15px auto;
              background-position:center right 14px;
              margin:30px auto 0;
              span{
                display:none;
              }
              &:after{
                content:"メッセージを見る";
              }
              &.open{
                background-image:url(../img/icon_minus.png);
                &:after{
                  content:"閉じる";
                }
              }
            }
            .showBox_sp{
              margin-top:25px;
              display:none;
              p{
                &+p{
                  margin-top:20px;
                }
              }
              img{
                width:100%;
                height:auto;
                margin:24px 0;
              }
            }
          }
        }
      }
    }
  }
  /*
  メディアの皆様へ
  -------------------------------------*/
  #PageMedia.pageMediaIndex {
    #Main{
      margin-bottom:60px;
      // margin-top:62px;
      h3{
        margin:65px 0 44px;
        padding:0 15px;
      }
      #ContBox01{
        .innerBasic{
          .txt{
            padding: 0 15px;
          }
          p{
            letter-spacing: 1.2px;
            &+p{
              margin-top:20px;
            }
          }
          p.txtCap{
            letter-spacing: 1.5px;
              span{
                color:#888;
                font-size: 14px;
              }
            }
        }
      }
      #ContBox02 {
        margin: -5px auto 0;
        p {
          margin-top: 47px;
          padding: 0 15px;
          text-align: left;
          letter-spacing: 1.2px;
          a {
          }
          span {
            display: inline;
          }
        }
        form {
          margin-top: 36px;
          >dl {  //共通設定
            border-top: 1px solid #f1f1f1;
            padding: 0 12px 28px;
            font-size: 0;
            letter-spacing: normal;

            dd.any{
              display: inline-block;
              margin-top: 30px;
              width: 40px;
              height: 20px;
              background-color: #b8b8b8;
              line-height: 20px;
              text-align: center;
              vertical-align: top;
              font-size: 12px;
              color: #fefefe;
            }
            dd.require {
              width: 40px;
              display: inline-block;
              vertical-align: top;
              color: #fefefe;
              font-size: 12px;
              background-color: #e42f2f;
              text-align: center;
              height: 20px;
              line-height: 20px;
              margin-top: 30px;
            }
            dt {
              display: inline-block;
              vertical-align: top;
              margin-top: 30px;
              line-height: 20px;
              font-size: 16px;
              color: #222222;
              padding-left: 10px;
              letter-spacing: 2px;
            }
            dd:nth-of-type(2) {
              font-size: 16px;
              color: #222;
              padding-top: 17px;
              >div.check {
                display: inline-block;
                margin-bottom:15px;
                width:105px;
                &:last-of-type{
                  margin-right:0;
                }
                label {
                  margin-left: 4px;
                  letter-spacing: 1.5px;
                }
                span {
                  width: 38px;
                  height: 38px;
                  background: transparent url(../img/checkbox02.png) no-repeat scroll left center;
                  background-size: 38px 38px;
                  input {
                    font-size: 16px;
                    width: 38px;
                    height: 38px;
                  }
                }
                span.checked {
                  background: transparent url(../img/checkbox01.png) no-repeat scroll left center;
                  background-size: 38px 38px;
                }
              }
              >div.cautionText {

              }
              >input {
                font-size: 16px;
                border-radius: 5px;
                width: 100%;
                height: 50px;
                background-color: #eee;
                font-size: 16px;
                color: #222;
                padding-left: 17px;
                letter-spacing: 1.5px;
                &:focus{
                  border: 1px solid #e8d492;
                  background-color: #fff;
                }
              }
              >span {
                display:block;
                margin-top: -2px;
                line-height: 14px;
                letter-spacing: 1.5px;
                font-size: 14px;
                color: #888;
              }
              input:-webkit-input-placeholder{
                color: #888;
              }
              input::-webkit-input-placeholder{
                color: #888;
              }
              input:-ms-input-placeholder{
                color: #888;
              }
              textarea:-webkit-input-placeholder{
                color: #888;
              }
              textarea::-webkit-input-placeholder{
                color: #888;
              }
              textarea:-ms-input-placeholder{
                color: #888;
              }
            }
            &:first-of-type {  //媒体の種類
            }
            &:nth-of-type(2),  //媒体名
            &:nth-of-type(3),  //媒体URL
            &:nth-of-type(4),  //御社名
            &:nth-of-type(9) {  //メールアドレス
              dd {
                &:nth-of-type(2) {
                  input {
                    width: 100%;
                  }
                }
              }
            }
            &:nth-of-type(10) {  //確認用メールアドレス
              dd:nth-of-type(2) {
                input {
                  width: 100%;
                }
                span {
                  display: block;
                  margin-top: 9px;
                  margin-bottom: 6px;
                  font-size: 14px;
                  color: #888888;
                  letter-spacing: 1.5px;
                }
              }
            }
            &:nth-of-type(11) {  //電話番号
            }
            &:last-of-type {  //取材内容
              border-bottom: 1px solid #f1f1f1;
              padding-bottom: 6px;
              >dd {
                &:nth-of-type(2) {
                  width: 100%;
                  textarea {
                    border-radius: 5px;
                    padding-top: 13px;
                    padding-left: 16px;
                    width: 100%;
                    height: 250px;
                    background-color: #eee;
                    line-height:28px;
                    letter-spacing: 1.5px;
                    font-size: 16px;
                    color: #222;
                    resize: none;
                    &:focus{
                      border: 1px solid #e8d492;
                      background-color: #fff;
                    }
                  }
                }
              }
            }
          }
          p.parent_agree {
            color: #e42f2f;
            font-size: 14px;
            line-height: 24px;
            margin-top: 25px;
            span {
              a {
                color: #e42f2f;
                position: relative;
                &:after {
                  content: "";
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  background: url(../img/img_pdf.png) no-repeat;
                  background-position: right center;
                  background-size: 20px auto;
                  position: absolute;
                  top: 0px;
                  right: -24px;
                }
              }
            }
          }
          p.minorLink{
            margin:25px 0;
            text-align: right;
            a{
              font-size: 16px;
              background: url(../img/icon_link.png) no-repeat right center;
              background-size: 15px auto;
              padding-right: 24px;
            }
          }
          >div.confirmBtn {
            ul {
              margin: 56px auto 88px;
              width: 285px;
              height: 80px;
              li {
                width: 100%;
                border-radius: 7px;
                background-color: #d1ae39;
                input {
                  display: block;
                  width: 100%;
                  height: 100%;
                  background: transparent url(../img/arrow05.png) no-repeat center right 15px;
                  background-size: 11px auto;
                  line-height: 80px;
                  text-align: center;
                  letter-spacing: 0;
                  font-size: 20px;
                  color: #fff;
                  margin:0 auto;
                  transition: .4s;
                  &:hover{
                    box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .2);
                    background-color: #e2b623;
                  }
                }
              }
            }
          }
        }
      }

    }
  }
  /*
  取材申し込みフォーム（入力内容のご確認）
  -------------------------------------*/
  #PageMedia.pageMediaConfirm {
    #Container {
      .innerBasic {
        #Main {
          margin-top: 53px;
          #ContBox01 {
            margin-bottom: 86px;
            h3 {
              font-size: 28px;
              margin-bottom: 59px;
              padding: 0 15px;
              span {
                display: block;
                font-size: 18px;
                margin-top:0;
                letter-spacing: 4px;
              }
            }
            p {
              font-size: 16px;
              text-align: left;
              letter-spacing: 1.5px;
              padding: 0 15px;
              span {
              }
            }
            form{
              >dl {  //全体設定
                border-top: 1px solid #f1f1f1;
                font-size: 0;
                letter-spacing: normal;
                padding: 0 15px 25px;
                >dt {
                  width: 200px;
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 28px;
                  font-size: 16px;
                  color: #222222;
                  padding-left: 10px;
                  letter-spacing: 2px;
                }
                dd.require {
                  width: 40px;
                  display: inline-block;
                  vertical-align: top;
                  color: #fefefe;
                  font-size: 12px;
                  background-color: #e42f2f;
                  text-align: center;
                  height: 20px;
                  line-height: 20px;
                  margin-top: 30px;
                }
                dd.any{
                  display: inline-block;
                  margin-top: 30px;
                  width: 40px;
                  height: 20px;
                  background-color: #b8b8b8;
                  line-height: 20px;
                  text-align: center;
                  vertical-align: top;
                  font-size: 12px;
                  color: #fefefe;
                }
                >dd {
                  &:nth-of-type(2) {
                    font-size: 16px;
                    color: #222;
                    padding-top: 9px;
                    letter-spacing: 1.5px;
                    word-wrap: break-word;
                  }
                }
                &:first-of-type {  //媒体の種類
                  margin-top: 36px;
                }

                &:last-of-type {  //取材内容
                  border-bottom: 1px solid #f1f1f1;
                  padding-bottom: 28px;
                  dd:nth-of-type(2) {
                    padding-top:2px;
                    letter-spacing: 2.5px;
                    line-height: 33px;
                  }
                }
              }
              >div {
                >ul.sendBtn {  //送信ボタン
                  margin: 39px auto 30px;
                  width: 285px;
                  height: 80px;
                  >li {
                    border-radius: 7px;
                    width: 100%;
                    background-color: #d1ae39;
                    input {
                      display: block;
                      width: 100%;
                      height: 100%;
                      background: transparent url(../img/arrow05.png) no-repeat center right 15px;
                      background-size: 11px auto;
                      line-height: 80px;
                      text-align: center;
                      letter-spacing: 2px;
                      font-size: 18px;
                      color: #fff;
                    }
                  }
                }
              }
              >ul.backBtn {  //戻るボタン
                >li {
                  margin: 0 auto;
                  border: 1px solid #e5e5e5;
                  border-radius: 7px;
                  width: 150px;
                  height: 40px;
                  >input {
                    display: block;
                    width: 100%;
                    height: 100%;
                    line-height: 40px;
                    text-align: center;
                    text-decoration: none;
                    font-size: 14px;
                    color: #888;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*
  取材申し込みフォーム（送信完了）
  -------------------------------------*/
  #PageMedia.pageMediaThanks {
    #Container {
      .innerBasic {
        padding: 0 15px;
        #Main {
          margin-bottom: -31px;
          #ContBox01 {
            h3 {
              margin-bottom: 48px;
              span {
                display: block;
                font-size: 18px;
                margin-top: -2px;
                letter-spacing: 4px;
              }
            }
            p {
              font-size: 16px;
              text-align: left;
              letter-spacing: 1.5px;
              &:nth-of-type(2) {
                margin-top: 22px;
                span {
                }
              }
            }
            ul {
              margin-top: 50px;
              li {
                margin: 0 auto;
                width: 250px;
                height: 50px;
                text-align: center;
                a {
                  display: block;
                  width: 100%;
                  height: 100%;
                  line-height: 50px;
                  text-decoration: none;
                  font-size: 16px;
                  color: #c5a22b;
                }
              }
            }
          }
        }
      }
    }
  }
  /*
  スタッフ紹介
  -------------------------------------*/
  #PageAbout.pageStaffIndex {
    #Main{
      margin-bottom:155px;
      h3{
        margin:65px 0 36px;
      }
      .clickTitle_sp{
        width:220px;
        height:40px;
        line-height:40px;
        border:1px solid #e7d48a;
        border-radius:6px;
        text-align:center;
        font-size:14px;
        color:#c5a22b;
        background-image:url(../img/icon_plus.png);
        background-repeat:no-repeat;
        background-size:15px auto;
        background-position:center right 14px;
        margin:20px auto 20px;
        span{
          display:none;
        }
        &:after{
          content:"メッセージを見る";
        }
        &.open{
          background-image:url(../img/icon_minus.png);
          &:after{
            content:"閉じる";
          }
        }
      }
      .showBox_sp{
        display:none;
      }
      #ContBox01{
        .boxImage {
          img {
            width: 100%;
          }
        }
        .innerBasic{
          img{
            width:100%;
            height:auto;
          }
          >div{
            margin:40px 15px 0;
          }
          h4{
            color:#ac832d;
            font-size:25px;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            position:relative;
            letter-spacing:10px;
            margin-bottom:30px;
            padding-left:15px;
            line-height:35px;
            &:before{
              content:"";
              display:block;
              width:71px;
              height:83px;
              background:url(../img/slide_logo.png) no-repeat top left;
              background-size:71px auto;
              position:absolute;
              left:0;
              top:-45px;
            }
          }
          p{
            &+p{
              margin-top:20px;
            }
          }
          .clickTitle_sp{
            &:after{
              content:"続きを見る";
            }
            &.open{
              &:after{
                content:"閉じる";
              }
            }
          }
          .showBox_sp{
            ul{
              margin:30px 0;
              li{
                width:33%;
                float:left;
                padding-left:1px;
              }
            }
            figure{
              margin:30px 0;
            }
          }
        }
      }
      #ContBox02{
      	padding:0 15px;
        .messageBox{
          border:1px solid #e5e5e5;
          border-radius:6px;
          padding:30px 30px 0;
          p+p{
            margin-top:20px;
          }
          .clearfix{
            padding-bottom:40px;
            &+.clearfix{
              border-top:1px solid #f1f1f1;
              padding-top:28px;
            }
            .staffName{
              position:relative;
              text-align:center;
              img{
                width:100%;
                height:auto;
              }
              figure{
                width:155px;
                display:inline-block;
                vertical-align:middle;
              }
              dl{
                display:inline-block;
                vertical-align:middle;
                font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                font-weight: 400;
                text-align:left;
                margin-left:10px;
                dt{
                  font-size:14px;
                  color:#888;
                }
                dd{
                  font-size:20px;
                  span{
                    color:#b58b32;
                    font-family: 'Roboto Slab', serif;
                    font-weight: 300;
                    font-size:10px;
                    padding-left:8px;
                  }
                }
              }
            }
            .showBox_sp{
            	margin-top:25px;
            }
          }
        }
      }
      #ContBox03{
      	padding:0 15px;
        .galleryBox{
          border:1px solid #e5e5e5;
          border-radius:6px;
          padding:30px 30px 45px;
          .gallerySlider{
            img{
              width:100%;
              height:auto;
            }
          }
          .bx-wrapper{
            position:relative;
            padding-bottom:75px;
            .bx-controls-direction{
              position:absolute;
              text-align:center;
              left:0;
              right:0;
              margin:auto;
              bottom:0;
            }
            .bx-prev,.bx-next{
            	display:inline-block;
              margin:auto;
              width:60px;
              height:60px;
              text-indent:-9999px;
              text-decoration:none;
              border-radius:50%;
              background-color:#fff;
              border:1px solid #f0e8c8;
              //box-shadow:0px 0px 10px 0px rgba(0,0,0,0.05);
              background-repeat:no-repeat;
              background-size:9px auto;
              background-position:center center;
            }
            .bx-prev{
              background-image:url(../img/arrow03.png);
            }
            .bx-next{
              background-image:url(../img/arrow04.png);
              margin-left:20px;
            }
            .bx-pager{
              margin-top:15px;
              text-align:center;
              .bx-pager-item{
                display:inline-block;
                vertical-align:top;
                width:9px;
                height:9px;
                &+.bx-pager-item{
                  margin-left:12px;
                }
                a{
                  display:block;
                  width:100%;
                  height:100%;
                  border-radius:50%;
                  text-indent:-9999px;
                  background-color:#dadada;
                  &.active{
                    background-color:#dccb87;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  施設案内
  -------------------------------------*/
  #PageAbout.pageClinicIndex {
    #Main{
    	margin-bottom:64px;
      h3{
        margin:65px 0 36px;
      }
      img{
        width:100%;
        height:auto;
      }
      .facilityBlock{
        .facilityBox{
          &+.facilityBox{
            padding-top:30px;
          }
          figure{
          }
          div{
            margin:30px 15px 0;
            padding-bottom:25px;
            position:relative;
            border-bottom:1px solid #f1f1f1;
            min-height:104px;
            img{
              width:125px;
              position:absolute;
              top:0;
            }
          }
          &:nth-of-type(odd){
            figure{
              padding-right:30px;
            }
            div{
              padding-left:125px;
              img{
                left:-15px;
              }
            }
          }
          &:nth-of-type(even){
            figure{
              padding-left:30px;
            }
            div{
              padding-right:125px;
              img{
                right:-15px;
              }
            }
          }
          &:last-of-type{
            div{
              border:none;
            }
          }
        }
      }
    }
  }

  /*
  採用情報
  -------------------------------------*/
  #PageAbout.pageRecruitIndex {
    #Main{
      margin-bottom: 130px;
      .clickTitle_sp{
        width:220px;
        height:40px;
        line-height:40px;
        border:1px solid #e7d48a;
        border-radius:6px;
        text-align:center;
        font-size:14px;
        color:#c5a22b;
        background-image:url(../img/icon_plus.png);
        background-repeat:no-repeat;
        background-size:15px auto;
        background-position:center right 14px;
        margin:15px auto 0;
        span{
          display:none;
        }
        &:after{
          content:"メッセージを見る";
        }
        &.open{
          background-image:url(../img/icon_minus.png);
          &:after{
            content:"閉じる";
          }
        }
      }
      .showBox_sp{
        display:none;
      }
      .contBox{
        h3{
          margin-bottom:30px;
        }
        margin:75px 0 50px;
      }
      #ContBox01{
        img{
          width:100%;
          height:auto;
        }
        #ContSubBox01{
          figure{
          }
          .innerBasic{
            position:relative;
            margin-top:-44px;
            background:#fff;
            padding-top:50px;
            text-align:center;
            margin:0 15px;
            h4{
              font-size:25px;
              line-height:35px;
              color:#ac832d;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              letter-spacing:10px;
              margin:32px 0;
            }
            p{
            	letter-spacing: 1.2px;
              &+p{
                margin-top:20px;
              }
            }
          }
        }
        #ContSubBox02{
          margin-top:68px;
          position:relative;
          .innerBasic{
            padding:0 15px;
            dl{
            	font-size:16px;
            	line-height:28px;
              position:absolute;
              width:46%;
              top:55px;
              right:15px;
              dt{
                margin-bottom:20px;
              }
            }
          }
          .clickTitle_sp{
          	margin:30px auto 0;
            &:after{
              content:"続きを見る";
            }
            &.open{
              &:after{
                content:"閉じる";
              }
            }
          }
          .showBox_sp{
            p{
              &+p{
                margin-top:20px;
              }
            }
          }
        }
      }
      #ContBox02{
        padding:0 15px;
        .div_border{
          border:1px solid #e5e5e5;
          border-radius:6px;
          padding:30px 30px 0;
          .clearfix{
            position:relative;
            margin-bottom:35px;
            &+.clearfix{
              border-top:1px solid#f1f1f1;
              padding-top:30px;
            }
            .nameImg{
              position:relative;
              text-align:center;
              figure{
                width:140px;
                display:inline-block;
                vertical-align:middle;
                img{
                  width:100%;
                  height:auto;
                }
              }
              dl{
                display:inline-block;
                vertical-align:middle;
                font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                font-weight: 400;
                margin-left:25px;
                text-align:left;
                dt{
                  color:#888;
                  font-size:14px;
                }
                dd{
                  font-size:20px;
                  span{
                    font-size:10px;
                    color:#b58b32;
                    font-family: "Roboto Slab", serif;
                    font-weight: 300;
                    padding-left:8px;
                  }
                }
              }
            }
            .showBox_sp{
              margin-top:25px;
            }
            p+p{
              margin-top:20px;
            }
          }
        }
      }
      #ContBox03{
        .innerBasic{
          >div{
            .click_sp{
              border-top:1px solid #f1f1f1;
              padding:20px 40px 15px 15px;
              color:#c5a22b;
              font-size:16px;
              background-image:url(../img/icon_plus.png);
              background-repeat:no-repeat;
              background-size:15px auto;
              background-position:center right 20px;
              &.open{
                background-image:url(../img/icon_minus.png);
              }
            }
            &:last-of-type{
              border-bottom:1px solid #f1f1f1;
            }
          }
        }
        table{
          width:100%;
          tr{
            width:100%;
          }
          th,td{
            display:block;
            font-size:16px;
            line-height:28px;
            padding: 10px 15px 22px;
          }
          th{
            border-top:1px solid #e5e5e5;
            border-bottom:1px solid #e5e5e5;
            background-color:#f5f5f5;
            padding:12px 15px;
            font-weight:500;
          }
        }
        .linkBtn{
          margin:90px 15px 0;
          height:100px;
          line-height:100px;
          a{
            color:#c3a541;
            font-size:18px;
            background:url(../img/arrow06.png) no-repeat center right 14px;
            background-size:11px auto;
            &:before{
              content:"";
              display:inline-block;
              width:31px;
              height:100px;
              vertical-align:top;
              background:url(../img/icon_form.png) no-repeat center left;
              background-size:31px auto;
              margin-right:7px;
            }
          }
        }
      }
    }
  }

  /*
  採用応募フォーム
  -------------------------------------*/
  #PageAbout.pageRecruitEntryIndex,
  #PageAbout.pageRecruitEntryConfirm,
  #PageAbout.pageRecruitEntryThanks {
    #Main{
      h3{
        margin-bottom:42px;
        span{
          display:block;
          font-size:18px;
        }
      }
      #ContBox01{
        >p{
          padding:0 15px;
        }
      }
      form {
        margin-top: 40px;
        dl{
          border-top: 1px solid #f1f1f1;
          padding:30px 15px;
          position:relative;
          &:last-of-type{
            border-bottom: 1px solid #f1f1f1;
          }
          dt,dd{
            font-size:16px;
          }
          dt{
            padding-left:50px;
            line-height:20px;
          }
          dd{
            &:first-of-type {
              width:40px;
              position:absolute;
              top:30px;
              left:15px;
              span{
                width: 40px;
                display: inline-block;
                vertical-align: top;
                color: #fefefe;
                font-size: 12px;
                background-color: #e42f2f;
                text-align: center;
                height: 20px;
                line-height: 20px;
              }
            }
            &:last-of-type{
              padding:15px 0 0;
              input,textarea,select{
              	width:100%;
                background-color: #eeeeee;
                border-radius: 5px;
                height: 50px;
                padding:0 16px;
                &:focus{
                  border:1px solid #e8d492;
                  background-color:#fff;
                }
                &.calendar{
                  background-image:url(../img/icon_calendar.png);
                  background-repeat:no-repeat;
                  background-size:20px auto;
                  background-position:center right 10px;
                }
              }
              input{
                //overflow: hidden;
                //white-space: nowrap;
                //text-overflow: ellipsis;
              }
              textarea{
                height:250px;
                padding-top:10px;
                resize: vertical;
              }
              select{
                background-image:url(../img/arrow07.png);
                background-repeat:no-repeat;
                background-size:11px auto;
                background-position:center right 15px;
              }
              select::-ms-expand {
                display: none;
              }
              .radio{
                span{
                  width: 39px;
                  height: 39px;
                  margin-right: 5px;
                  background: transparent url(../img/radio02.png) no-repeat scroll left center;
                  background-size:39px auto;
                  line-height: 24px;
                  &.checked{
                    background: transparent url(../img/radio01.png) no-repeat scroll left center;
                    background-size:39px auto;
                  }
                  input{
                    width:39px;
                    height:39px;
                  }
                }
              }
              label{
                &+.radio{
                  margin-left: 24px;
                }
                &.comment{
                  padding-left:22px;
                }
              }
              .comment{
                font-size:14px;
                color:#888;
                display:block;
                margin-top:10px;
              }
              input:-webkit-input-placeholder{
                color: #888;
              }
              input::-webkit-input-placeholder{
                color: #888;
              }
              input:-ms-input-placeholder{
                color: #888;
              }
              textarea:-webkit-input-placeholder{
                color: #888;
              }
              textarea::-webkit-input-placeholder{
                color: #888;
              }
              textarea:-ms-input-placeholder{
                color: #888;
              }
              #text4,#text8,#text10{
                width:170px;
              }
              #text11{
                width:175px;
              }
              #text8{
                margin-bottom:10px;
              }
            }
          }
          &:nth-of-type(9){
            dd{
              &:last-of-type{
                padding:25px 0;
              }
            }
          }
        }
        ul {
          margin: 45px auto 85px;
          li {
            &.confirm{
              input {
                margin: 0 auto;
                width: 285px;
                height: 80px;
                background-color: #d1ae39;
                border-radius: 7px;
                display: block;
                background: transparent url(../img/arrow05.png) no-repeat center right 12px;
                background-size: 11px auto;
                line-height: 80px;
                text-align: center;
                font-size: 18px;
                color: #fff;
                letter-spacing: 2px;
                transition: 0.4s;
              }
            }
            &.reset{
              input {
                margin:30px auto 0;
                width: 150px;
                height: 40px;
                background-color: #fff;
                border:1px solid #e5e5e5;
                border-radius: 7px;
                display: block;
                text-align: center;
                font-size: 14px;
                color: #888;
                letter-spacing: 2px;
                transition: 0.4s;
              }
            }
          }
        }
      }
    }
  }

  /*
  採用応募フォーム（入力内容のご確認）
  -------------------------------------*/
  #PageAbout.pageRecruitEntryConfirm {
    #Main{
      form{
        dl{
        }
      }
    }
  }

  /*
  採用応募フォーム（送信完了）
  -------------------------------------*/
  #PageAbout.pageRecruitEntryThanks {
    #Main{
      p{
        &+p{
          margin-top:20px;
        }
      }
      .linkBtn{
        margin:50px auto 0;
        width:250px;
        height:50px;
        line-height:50px;
        a{
          font-size:16px;
        }
      }
    }
  }

  /*
  施術方法
  -------------------------------------*/
  #PageTreatment.pageIndex {
    #Main{
      margin-bottom:130px;
      min-height:auto;
      h3{
        padding:25px 45px;
        text-align:center;
        border-top:1px solid #f1f1f1;
        background-image:url(../img/icon_plus.png);
        background-repeat:no-repeat;
        background-size:25px auto;
        background-position:center right 15px;
        font-size:24px;
        span{
          color:#ac832d;
          display:block;
          font-size:14px;
          font-family: "Roboto Slab", serif;
          font-weight: 300;
          letter-spacing:2px;
          margin-bottom:4px;
        }
      }
      .open{
        h3{
          background-image:url(../img/icon_minus.png);
        }
      }
      .contBox{
        &:last-of-type{
          h3{
            border-bottom:1px solid #f1f1f1;
          }
          .open{
            h3{
              border-bottom:none;
            }
          }
        }
      }
      .showBox_sp{
        margin-bottom:64px;
        margin-top:10px;
        display:none;
      }
      #ContBox02{
        .contSubBox{
          border-bottom:1px solid #f1f1f1;
          margin:0 15px 40px;
          &:last-of-type{
            border-bottom:none;
          }
          dl{
            dt{
              font-size:18px;
              color:#ac832d;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              padding-bottom:25px;
              border-bottom:1px solid #e7d48a;
            }
            dd{
              padding:7px 0 35px;
              margin-left: -15px;
              ul{
                position:relative;
                &:before{
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: block;
                  width: 15px;
                  height: 100%;
                  background-color: #fff;
                }
                li{
                  display:inline-block;
                  margin:10px 0 0;
                  border-left: 1px solid #dbdbdb;
                  a{
                    font-size:16px;
                    color:#888;
                    display: block;
                    padding: 3px 15px;
                  }
                }
              }
            }
          }
        }
      }
      #ContBox03{
        .click_sp{
        }
        .showBox_sp{
          margin-bottom: 75px;
        }
        h3{
          margin-bottom: -9px;
          padding-bottom: 8px;
          background-position: top calc(50% + 25px) right 15px;
        }
        h4{
          font-size: 24px;
          font-family: "Roboto Slab", serif;
          font-weight: 300;
          color: #ac832d;
          text-align: center;
          letter-spacing: 0.2em;
          padding-bottom: 24px;
        }
        .note {
          margin-top: 10px;
          text-align: left;
          letter-spacing: 3px;
          color: #888;
          padding: 0 15px;
        }
        .topCatBlock{
          padding: 0 15px;
          margin-top:20px;
          overflow:hidden;
          .catBox{
            position:relative;
            margin-top:15px;
            .rankIcon{
              position:absolute;
              top:18px;
              left:18px;
              width:45px;
              height:45px;
              z-index:2;
              display:block;
              background-color:#f5f5f5;
              border-radius:50%;
              text-align:center;
              line-height:45px;
              font-family: "Roboto Slab", serif;
              font-size: 18px;
              font-weight: 300;
              color:#aaa;
              &.rankIcon01{
                width:60px;
                height:60px;
                line-height:60px;
                font-size:24px;
                color:#fff;
                background: linear-gradient(45deg, #b58b32 0%,#dabe68 36%,#ebd896 67%,#cfb054 100%);
              }
              &.rankIcon02{
                color:#fff;
                background: linear-gradient(45deg, #818181 0%,#adaeae 36%,#e7e7e8 67%,#b7b8b7 100%);
              }
              &.rankIcon03{
                color:#fff;
                background: linear-gradient(45deg, #b87d37 0%,#d5a46a 36%,#e8c7a1 67%,#deaa6d 100%);
              }
            }
          }
          .topCatBox{
            width:100%;
            float:none;
          }
        }
      }
    }
  }

  /*
  施術方法（カテゴリ）
  -------------------------------------*/
  #PageTreatment.pageCategory {
    #Main{
      margin-bottom:160px;
      #ContBox01{
        h3{
          text-align: left;
          padding:0 15px;
        }
        .titelTxt{
          padding:0 15px;
          color:#888;
          text-align:left;
          font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
          font-size: 24px;
          line-height:40px;
          font-weight: 400;
          margin:0 0 20px;
          letter-spacing:4.5px;
        }
        .imgTxt{
          margin-bottom:22px;
          padding:0 15px;
          figure{
            margin-bottom:25px;
            img{
              width:100%;
              height:auto;
            }
          }
        }
        .pointBox{
          display: none;
          margin:0 15px;
          border:4px solid #f3ebcc;
          .click_sp_point{
            padding:20px 15px;
          }
          h4{
            color:#c5a22b;
            font-size:16px;
            background-image:url(../img/icon_plus.png);
            background-repeat:no-repeat;
            background-size:15px auto;
            background-position:top 3px right;
          }
          &.open{
            border:4px solid #f1f1f1;
            .click_sp_point{
              padding:20px 15px 0;
            }
            h4{
              padding-bottom:18px;
              border-bottom:1px solid #e7d48a;
              background-image:url(../img/icon_minus.png);
              background-position:top 10px right;
            }
          }
          .showBox_sp{
            padding:25px 15px 30px;
            display:none;
            font-size:16px;
            line-height:28px;
          }
          p{
            &+p{
              margin-top:20px;
            }
          }
        }
        .contSubBox{
          margin-top:75px;
          h4{
            border-top:1px solid #e7d48a;
            border-bottom:1px solid #e7d48a;
            color:#ac832d;
            font-size:24px;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            text-align:center;
            padding:20px 15px;
          }
          .txtWrap{
            background:#f9f8f5;
            padding:25px 15px;
            position:relative;
            margin-bottom:30px;
            &:after{
              content:"";
              display:block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 22px 15px 0 15px;
              border-color: #f9f8f5 transparent transparent transparent;
              position:absolute;
              bottom:-22px;
              left:0;
              right:0;
              margin:auto;
            }
            p{
              margin:0 auto;
              color:#888;
            }
          }
          .clickTitle_sp{
            margin: 30px auto 0;
            border: 1px solid #e7d48a;
            border-radius: 6px;
            width: 220px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: center right 14px;
            background-image: url(../img/icon_plus.png);
            background-size: 15px auto;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            color: #c5a22b;
            span{
              display:none;
            }
            &:after{
              content:"施術項目を見る";
            }
            &.open{
              background-image: url(../img/icon_minus.png);
              &:after{
                content:"閉じる";
              }
            }
          }
          .showBox_sp{
            margin-top:30px;
            padding:0 15px;
            display:none;
          }
          .catBox{
            margin-top:15px;
            padding: 20px 30px 22px;
            .imgTitle{
              >figure{
                display: none;
              }
            }
            dl{
              padding-left:0;
              dd{
                display: table;
                width:100%;
                table-layout: fixed;
                figure{
                  width: 80px;
                }
                ul{
                  display: table-cell;
                  vertical-align: top;
                  padding-left:15px;
                }
              }
            }
            p{
              font-size: 12px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  /*
  施術方法（詳細）
  -------------------------------------*/
  #PageTreatment.pageEntry,
  #PageCase.pageEntry {
    #Main{
      margin-bottom:160px;
      img[src$=".gif"]{
        width:100%!important;
        height:auto;
      }
      #ContBox01{
        .mainContTitle{
          padding:0 15px;
          margin-bottom:60px;
          figure{
            margin-top:20px;
            img{
              width:100%;
              height:auto;
            }
          }
          h3{
            font-size:28px;
            line-height:38px;
            font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
            font-weight: 500;
            border-bottom:2px solid #f1f1f1;
            padding-bottom:10px;
            text-align:left;
            letter-spacing: 2px;
          }
          h4{
            font-size:20px;
            line-height:36px;
            color:#888;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            margin:20px 0 15px;
            letter-spacing:2px;
          }
          p{
          }
        }
        .contSubBox{
          margin-top:70px;
          border-top:1px solid #e5e5e5;
          padding:3px 0 0;
        }
        #FlowBox{
          ol{
            list-style:none;
            padding:0 15px;
            li{
              padding-bottom:30px;
              &+li{
                border-top:1px solid #f1f1f1;
                padding-top:15px;
              }
              &:last-of-type{
                padding-bottom:0;
              }
              dl{
                dt{
                  color:#ac832d;
                  font-size:18px;
                  margin-bottom:12px;
                  font-weight:700;
                  span{
                    font-size:32px;
                    font-family: 'Roboto Slab', serif;
                    font-weight: 300;
                    font-style:italic;
                    padding-right:18px;
                  }
                }
                dd{
                  font-size:16px;
                  line-height:28px;
                }
              }
              div{
                margin-top:25px;
                img{
                  width:100%;
                  height:auto;
                  &+img{
                    margin-top:15px;
                  }
                }
              }
            }
          }
        }
        #AftercareBox{
          table{
            width:100%;
            td,th{
              display:block;
              width:100%;
              padding:10px 15px 25px;
              font-size:16px;
              line-height:28px;
            }
            th{
              border-top:1px solid #e5e5e5;
              border-bottom:1px solid #e5e5e5;
              background-color:#f5f5f5;
              font-weight:500;
              padding:10px 15px;
            }
          }
          ul{
            margin-top:6px;
            padding:0 15px;
            li{
              font-size:14px;
              line-height:28px;
              color:#888;
              span{
                padding-right:10px;
              }
            }
          }
        }
        #RecommendBox{
          ul{
            padding:0 15px;
            li{
              font-size:18px;
              line-height:36px;
              font-weight:700;
              background:url(../img/icon_check.png) no-repeat left top 2px;
              background-size:34px auto;
              padding-left:48px;
              &+li{
                margin-top:20px;
              }
            }
          }
        }
        #CaseBox{
          .caseList{
            padding:0 15px;
            dl{
              &+dl{
                margin-top:45px;
              }
              dt{
                font-size:16px;
                color:#fff;
                text-align:center;
                background-color:#cfad64;
                margin-bottom:20px;
                padding: 3px 10px;
              }
              dd{
                &.img{
                  ul{
                    li{
                      width:41%;
                      float:left;
                      img{
                        width:100%;
                        height:auto;
                      }
                      p{
                        margin-top:6px;
                        text-align:center;
                      }
                      &+li{
                        margin-left:18%;
                        div{
                          position:relative;
                          &:before{
                            content:"";
                            display:block;
                            position:absolute;
                            top:0;
                            bottom:0;
                            margin:auto;
                            left:-25%;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 6px 0 6px 10px;
                            border-color: transparent transparent transparent #dccb87;
                          }
                        }
                      }
                    }
                  }
                }
                &.doctor{
                  margin-top:12px;
                  background-color:#f5f5f5;
                  padding:15px;
                  display:table;
                  width:100%;
                  table-layout:fixed;
                  figure{
                    width:70px;
                    display:table-cell;
                    vertical-align:middle;
                    img{
                      width:70px;
                      height:70px;
                      border-radius:50%;
                    }
                  }
                  ul{
                    display:table-cell;
                    vertical-align:middle;
                    padding-left:15px;
                    li{
                      font-size:14px;
                      line-height:28px;
                      color:#888;
                      letter-spacing:1.2px;
                    }
                  }
                }
                &.linkBtn{
                  margin-top:15px;
                  width: 100%;
                  height: 50px;
                  line-height: 50px;
                  a{
                    background: transparent url(../img/icon_link.png) no-repeat center right 20px;
                    background-size: 15px auto;
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
        #VoiceBox{
          .note{
            font-size:14px;
            color:#888;
            text-align:right;
            padding:0 15px;
          }
          >h4{
            margin: 10px 0 25px;
            border: 1px solid #cfad64;
            padding: 13px 19px;
            background-color: #cfad64 ;
            font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif!important;
            font-size: 19px;
            font-weight: bold;
            color: #fff ;
          }
        }
        #PriceBox{
          h4{
            font-size:20px;
            line-height:32px;
            color:#fff;
            padding:15px 15px;
            background-color:#cfad64;
            font-weight:700;
          }
          table{
            width:100%;
            border-bottom:1px solid #e5e5e5;
            td,th{
              //border:1px solid #e5e5e5;
              font-size:16px;
              line-height:32px;
              padding:8px 15px;
              width: 100%;
              display: block;
            }
            th{
              border-top:1px solid #e5e5e5;
              border-bottom:1px solid #e5e5e5;
              color:#ac832d;
              background-color:#f5f4ed;
              //width:56%;
              border-left:none;
            }
            td{
              text-align:left;
              border-right:none;
              font-weight:700;
              vertical-align:middle;
            }
          }
          .option{
            h4{
              background-color:#c4c4c4;
              color:#fff;
            }
            table{
              th{
                background-color:#f5f5f5;
                color:#222;
                font-weight:500;
              }
              td{
                font-weight:500;
              }
            }
          }
          .underCommonGuide{
            margin-top: 40px;
          }
        }

        #FaqBox {
          dl.faqList {
            display: block;
            position: relative;
            border-bottom: 1px solid #f1f1f1;
            margin: 0 15px;
            padding: 29px 35px 29px 0;
            dt {
              display: table;
              width: 100%;
              div {
                display: table-cell;
                width: 45px;
                vertical-align: top;
              }
              span {
                display: inline-block;
                vertical-align: top;
                border-radius: 50%;
                text-align: center;
                font-family: "Roboto Slab", serif;
                font-weight: 300;
                color: #fff;
                margin-right: 3px;
                width: 34px;
                height: 34px;
                background: linear-gradient(45deg, #b58b32 0%, #dabe68 36%, #ebd896 67%, #cfb054 100%);
                line-height: 34px;
                font-size: 15px;
              }
              p {
                vertical-align: top;
                font-size: 20px;
                letter-spacing: 0.1em;
                display: table-cell;
                padding-top: 3px;
                line-height: 34px;
                font-weight: 500;
                color: #222;
              }
            }
            dt:after {
              content: "";
              position: absolute;
              top: 50px;
              right: 0;
              display: block;
              width: 25px;
              height: 25px;
              background: url(../img/icon_minus.png) no-repeat center center/cover;
              background-size: 25px 2px;
            }
            &.btnOff {
              dt:after {
                content: "";
                position: absolute;
                top: 50px;
                right: 0;
                display: block;
                width: 25px;
                height: 25px;
                background: url(../img/icon_plus.png) no-repeat center top/cover;
                background-size: 25px 25px;
              }
              dd.faqAns {
                display: none;
              }
            }
            dd.faqAns {
              display: table;
              margin-top: 15px;
              width: 100%;
              letter-spacing: normal;
              color: #222;
              div {
                display: table-cell;
                padding-top: 2px;
                padding-right: 8px;
                vertical-align: top;
                width: 45px;
              }
              span {
                display: inline-block;
                border-radius: 50%;
                width: 34px;
                height: 34px;
                background-color: #f5f5f5;
                line-height: 34px;
                text-align: center;
                font-family: "Roboto Slab", serif;
                font-size: 15px;
                font-weight: 300;
                background: linear-gradient(45deg, #818181 0%, #adaeae 36%, #e7e7e8 67%, #b7b8b7 100%);
                color: #fff;
                vertical-align: top;
              }
              p.txt {
                display: table-cell;
                padding-top: 6px;
                line-height: 28px;
                vertical-align: top;
                font-size: 16px;
              }
            }
          }

          dl:first-of-type {
            border-top: 1px solid #f1f1f1;
          }
        }

        .voiceBlock{
          padding:0 15px;
          &+.voiceBlock{
            margin-top:25px;
          }
          dl{
            display:table;
            width:100%;
            table-layout:fixed;
            dd{
              width:70px;
              display:table-cell;
              vertical-align:middle;
              img{
                width:70px;
                height:70px;
                border-radius:50%;
              }
            }
            dt{
              display:table-cell;
              vertical-align:middle;
              font-size:14px;
              color:#888;
              padding-left:15px;
            }
          }
          div{
            margin-top:30px;
            border:4px solid #f1f1f1;
            padding:0 25px 10px;
            position:relative;
            &:after{
              content:"";
              display:block;
              background:url(../img/fukidashi02.png) no-repeat top left;
              background-size:32px auto;
              width:32px;
              height:25px;
              position:absolute;
              top:-25px;
              left:15px;
            }
          }
        }
      }
      .contBoxLink{
        margin-top:60px;
        padding:0 15px;
        h3{
          margin-bottom:25px;
        }
        .linkBtn{
          width:250px;
          height:50px;
          line-height:50px;
          margin:25px auto 0;
          a{
            font-size:16px;
          }
        }
      }
    }
  }

  /*
  料金一覧 共通
  -------------------------------------*/
  #PagePrice{
    #Main{
      margin-bottom:90px;
      #PriceNavi{
        padding:0 15px;
        dl{
          dt{
            border-bottom:1px solid #e7d48a;
            font-size:18px;
            color:#ac832d;
            font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
            font-weight: 400;
            background-image:url(../img/icon_plus.png);
            background-repeat:no-repeat;
            background-size:15px auto;
            background-position: top 6px right 5px;
            padding-bottom: 22px;
            margin-bottom: 12px;
            &.open{
              background-image:url(../img/icon_minus.png);
              background-position: top 13px right 5px;
            }
          }
          dd{
            border-bottom:1px solid #f1f1f1;
            padding-bottom: 34px;
            ul{
              position:relative;
              margin-left:-15px;
              &:before{
                content:"";
                display:block;
                width:15px;
                height:100%;
                background-color:#fff;
                position:absolute;
                top:0;
                left:0;
              }
              li{
                font-size:16px;
                display:inline-block;
                border-left:1px solid #dbdbdb;
                margin-top:7px;
                a{
                  color:#888;
                  padding:5px 15px;
                  display:block;
                }
                &.current{
                  a{
                    color:#ac832d;
                    text-decoration:none;
                  }
                }
              }
            }
          }
        }
        .showBox_sp{
          display:none;
        }
      }
      h3{
        margin:70px 15px 40px;
      }
      .priceBlock{
        .priceInnerBox{
          position:relative;
          &+.priceInnerBox{
            border-top:1px solid #fff;
          }
          h4{
            font-size:20px;
            line-height:32px;
            color:#fff;
            padding:14px 15px;
            background-color:#cfad64;
            font-weight:700;
            background-image:url(../img/icon_plus02.png);
            background-repeat:no-repeat;
            background-size:15px auto;
            background-position:center right 20px;
          }
          .open h4{
            background-image:url(../img/icon_minus02.png);
          }
          dl{
            //display:table;
            table-layout:fixed;
            width:100%;
            border-top:1px solid #e5e5e5;
            border-bottom:1px solid #e5e5e5;
            &+dl{
              border-top:none;
            }
            dd,dt{
              //display:table-cell;
              width: 100%;
              vertical-align:top;
              font-size:16px;
              line-height:28px;
              padding:12px 15px;
            }
            dt{
              border-bottom:1px solid #e5e5e5;
              color:#ac832d;
              background-color:#f5f4ed;
              //width:56%;
              font-weight:700;
            }
            dd{
              text-align:left;
              font-weight:700;
              vertical-align:middle;
            }
          }
          .showBox_sp{
            margin-bottom:45px;
            display:none;
          }
          ul{
            margin:30px 0 0;
            .linkBtn{
              margin:0 auto;
              width:150px;
              height:40px;
              line-height:40px;
              &.priceBtn{
                width:250px;
                height:60px;
                line-height:60px;
                margin-bottom:15px;
                a{
                  font-size:16px;
                  position:relative;
                  background:url(../img/arrow04.png) no-repeat center right 15px;
                  background-size:9px auto;
                }
              }
            }
          }
          .option{
            h4{
              background-color:#c4c4c4;
            }
            dl{
              dt{
                background-color:#f5f5f5;
                font-weight:500;
                color:#222;
              }
              dd{
                font-weight:500;
              }
            }
          }
        }
      }
    }
  }
  /*
  料金一覧
  -------------------------------------*/
  #PagePrice.pageIndex {
    #Main{
      .underCommonGuide{
        margin-bottom: 65px;
      }
    }
  }

  /*
  料金一覧（カテゴリ）
  -------------------------------------*/
  #PagePrice.pageCategory {
  }

  /*
  料金一覧（詳細）
  -------------------------------------*/
  #PagePrice.pageEntry {
    #Main{
      #ContBox01{
        h3{
          text-align: left;
          border-bottom: 3px solid #f1f1f1;
          padding-bottom: 6px;
          font-family: "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
          letter-spacing: 3px;
          margin: 70px 15px 45px;
        }
      }
      .priceBlock{
        .priceInnerBox{
          h4{
            background-image:none;
          }
        }
        .underCommonGuide{
          margin-top: 45px;
        }
      }
      #ContBox02{
        padding:0 15px;
      }
    }
  }

  /*
  症例写真 共通
  -------------------------------------*/
  #PageCase{
  	.iziModal{
    	box-shadow:none;
    }
    #Main{
      margin-bottom:85px;
      h3{
        margin-bottom: 40px;
        font-size:24px;
        span{
          color:#ac832d;
          display:block;
          font-size:14px;
          font-family: "Roboto Slab", serif;
          font-weight: 300;
          letter-spacing:1.5px;
          margin-bottom:4px;
        }
      }
      #CaseNav{
        padding:0 15px;
        .linkBtn{
          width:100%;
          line-height:1;
          &+li{
            margin-top:15px;
          }
          a{
            display:table;
            padding:18px 30px 18px 20px;
            font-size:16px;
            line-height:23px;
            position:relative;
            background:url(../img/arrow04.png) no-repeat center right 15px;
            background-size:9px auto;
            letter-spacing:1px;
            span{
              width:100%;
              display:table-cell;
              vertical-align:middle;
            }
          }
        }
      }
      .doctorBox{
        background-color:#f5f5f5;
        padding:15px;
        display:table;
        width:100%;
        table-layout:fixed;
        figure{
          width:70px;
          display:table-cell;
          vertical-align:middle;
          img{
            width:70px;
            height:70px;
            border-radius:50%;
          }
        }
        ul{
          display:table-cell;
          vertical-align:middle;
          padding-left:15px;
          li{
            font-size:14px;
            line-height:28px;
            color:#888;
          }
        }
      }
      .viewBtn{
        width:220px;
        height:50px;
        line-height:50px;
        margin:25px auto 0;
        cursor: pointer;
        div{
          background-repeat: no-repeat;
          background-position: center right 20px;
          background-image: url(../img/icon_plus.png);
          background-size: 15px auto;
          font-size: 16px;
        }
      }
      .shoureiList{
        padding:0 15px;
        >ul{
          >li{
            border:1px solid #e5e5e5;
            border-radius:6px;
            padding:30px;
            margin-top:15px;
            h4{
              font-size:16px;
              line-height:24px;
              color:#fff;
              text-align:center;
              background-color:#cfad64;
              margin-bottom:20px;
              padding: 3px 10px;
            }
            .img{
              margin-bottom:15px;
              dl{
                width:43%;
                float:left;
                dd{
                  img{
                    width:100%;
                    height:auto;
                  }
                }
                dt{
                  font-size:16px;
                  margin-top:10px;
                  text-align:center;
                }
                &+dl{
                  float:right;
                  dd{
                    position:relative;
                    &:before{
                      content:"";
                      display:block;
                      position:absolute;
                      top:0;
                      bottom:0;
                      margin:auto;
                      left:-19%;
                      width: 0;
                      height: 0;
                      border-style: solid;
                      border-width: 6px 0 6px 10px;
                      border-color: transparent transparent transparent #dccb87;
                    }
                  }
                }
              }
            }
            .linkBtn{
              width:100%;
              height:50px;
              line-height:50px;
              margin:18px auto 0;
              a{
                font-size:16px;
              }
            }
          }
        }
      }
    }
  }
  /*
  症例写真
  -------------------------------------*/
  #PageCase.pageIndex {
    #Main {
      #ContBox00 {
        border: 1px solid #e7d48a;
        border-radius: 6px;
        padding: 0 30px;
        margin: 0 15px 15px;

        a {
          text-decoration: none;
          font-size: 16px;
          padding: 25px 0;
          display: block;
          width: 100%;
          overflow: auto;
          color: #888;

          dl {
            width: 100%;

            dt {
              display: block;
              font-size: 16px;
              padding-right: 26px;
              margin-bottom: 5px;
              color: #c5a22b;
            }

            dd {
              display: block;
              color: #888;
              position: relative;
              padding-right: 26px;
              font-size: 14px;
            }

            dd:after {
              content: "";
              position: absolute;
              top: -5px;
              right: 0;
              display: block;
              width: 8px;
              height: 15px;
              background: url(../img/arrow04.png) no-repeat center top/cover;
              background-size: 8px 15px;
            }
          }
        }

        a+a {
          border-top: 1px solid #f1f1f1;
        }
      }

      #ContBox02 {
        margin-top: 70px;
      }
    }
  }


  /*
  症例写真（カテゴリ）
  -------------------------------------*/
  #PageCase.pageCategory {
  }

  /*
  各症例写真（詳細）
  -------------------------------------*/
  #PageCase.pageEntry {
    #Main{
      #ContBox01{
        .mainContTitle{
          margin-bottom:0;
          display:block;
          padding:0 15px;
          h3{
            margin-bottom:30px;
            letter-spacing: 3px;
          }
        }
        .doctorWrap{
          padding:0 15px;
          margin-bottom: 46px;
        }
        .beforeAfter{
          padding:0 15px;
          li{
            margin-top:30px;
            dl{
              width:42%;
              float:left;
              dd{
                img{
                  width:100%;
                  height:auto;
                }
              }
              dt{
                font-size:16px;
                color:#fff;
                background-color:#cfad64;
                padding:3px 5px;
                text-align:center;
              }
              &+dl{
                float:right;
                dd{
                  position:relative;
                  &:before{
                    content:"";
                    display:block;
                    position:absolute;
                    top:0;
                    bottom:0;
                    margin:auto;
                    left:-22%;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 0 6px 10px;
                    border-color: transparent transparent transparent #dccb87;
                  }
                }
              }
            }
          }
        }
        .contSubBox{
          .boxTitle{
            margin-bottom:0;
            h3{
              margin-bottom:22px;
            }
          }
        }
        #ContSubBox01{
          margin:40px 15px 0;
          padding-top:50px;
          >h3{
            color:#888;
            font-size:28px;
          }
          .price{
            background-color:#f5f4ed;
            padding:34px 15px;
            margin:65px -15px 45px;
            dl{
              dt{
                font-size:20px;
                font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                font-weight: 400;
                width:80px;
              }
              dd{
                font-size:16px;
                color:#ac832d;
                line-height:28px;
              }
            }
          }
          .entryCaseBox02{
            border:3px solid #f3ebcc;
            margin-top:45px;
            .click_sp_point{
              background-image:url(../img/icon_plus.png);
              background-repeat:no-repeat;
              background-size:15px auto;
              background-position:top 24px right 15px;
              padding:20px 15px 0 15px!important;
              h3{
                color:#c5a22b!important;
                font-weight:500!important;
                font-size:16px!important;
                margin:0!important;
                line-height:28px!important;
                padding:0 0 15px 0!important;
                letter-spacing:1px!important;
                border:none!important;
              }
            }
            &.open{
              border:3px solid #f1f1f1;
              .click_sp_point{
                background-image:url(../img/icon_minus.png);
                background-position:top 33px right 15px;
                h3{
                  border-bottom:1px solid #e7d48a!important;
                }
              }
            }
            .showBox_sp{
              padding:15px;
              display:none;
            }
          }
        }
      }
      #ContBox02{
        margin-top:80px;
        padding:0 15px;
        h3{
          font-size:28px;
        }
        .catBox{
          position:relative;
          margin-top:15px;
        }
        .topCatBox{
          width:100%;
          float:none;
        }
      }
    }
  }

  /*
  アクセス
  -------------------------------------*/
  #PageAccess.pageIndex {
    #Container {
      .innerBasic {
        #Main {
          margin-top: 57px;
          margin-bottom: 125px;
          #ContBox01 {
            .mapArea {
              margin-top: 40px;
              #Gmap01 {
                width:100%;
                height:220px;
                iframe{
                  width: 100%;
                  height:220px;
                }
              }
              ul {
                // margin-top: 20px;
                padding: 0 15px;
                padding-top: 20px;
                padding-bottom: 60px;
                position: relative;
                &:after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 30px;
                  margin: auto;
                  display: block;
                  width: 4px;
                  height: 100%;
                  background: #eae2c7;
                  z-index: -1;
                }
                li {
                  width: 200px;
                  height: 40px;
                  margin-left: auto;
                  margin-right: 0;
                  font-size: 14px;
                  color: #c5a22b;
                  letter-spacing: 1.5px;
                  a {
                    width: 100%;
                    height: 100%;
                    display: block;
                    text-decoration: none;
                    padding-left: 16px;
                    line-height: 40px;
                    background: transparent url(../img/icon_link.png) no-repeat center right 14px;
                    background-size: 15px auto;
                    text-align:left;
                    &:after {

                    }
                  }
                }
              }
            }
            p {
              text-align: center;
              letter-spacing: 1.5px;
              span {
                display: block;
              }
            }
            .largeBox01 {
              width: 100%;
              padding: 0 15px;
              margin: 0 auto;
              // padding-top: 116px;
              // position: relative;
              // margin-top: -57px;
              figure.clearfix {
                img {
                  width: 100%;
                }
                p {
                  width: 100%;
                  font-size: 16px;
                  // margin-top: 25px;
                  padding-bottom: 53px;
                  padding-top: 23px;
                  text-align: left;
                  letter-spacing: 1.5px;
                }
                &:after {
                  content: ".";
                  display: block;
                  height: 0;
                  font-size:0;
                  clear: both;
                  visibility:hidden;
                }
                &:first-of-type {
                  img {

                  }
                  p {
                    padding-left: 43px;
                    float: right;
                    position: relative;
                    &:after {
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 15px;
                      margin: auto;
                      display: block;
                      width: 4px;
                      height: 100%;
                      background: #eae2c7;
                      z-index: -1;
                    }
                  }
                }
                &:nth-of-type(2) {
                  img {

                  }
                  p {
                    padding-left: 43px;
                    float: right;
                    position: relative;
                    &:after {
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 15px;
                      margin: auto;
                      display: block;
                      width: 4px;
                      height: 100%;
                      background: #eae2c7;
                      z-index: -1;
                    }
                  }
                }
                &:nth-of-type(3) {
                  img {

                  }
                  p {
                    padding-left: 43px;
                    float: right;
                    position: relative;
                    &:after {
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 15px;
                      margin: auto;
                      display: block;
                      width: 4px;
                      height: 100%;
                      background: #eae2c7;
                      z-index: -1;
                    }
                  }
                }
                &:nth-of-type(4) {
                  img {

                  }
                  p {
                    float: right;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

 /*
  お知らせ 共通
  -------------------------------------*/
  #PageNews{
    .sub_news {
      width: 100%;
      margin-top: 47px;
      h5 {
        margin-bottom:12px;
        padding-top: 14px;
        padding-bottom: 10px;
        letter-spacing: 2px;
        text-align: left;
        font-size: 16px;
        color: #ac832d;
        border-top: 1px solid #e7d48a;
        border-bottom: 1px solid #e7d48a;
        font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
      }
      .click_sp{
        h5{
          margin-bottom:0;
          background: transparent url(../img/icon_plus.png) no-repeat center right 5px;
          background-size: 15px auto;
        }
        &.open{
          h5 {
            background: transparent url(../img/icon_minus.png) no-repeat center right 5px;
            background-size: 15px auto;
          }
        }
      }
      .showBox_sp{
        display:none;
      }
      >div h5 {
        margin-top: 21px;
      }
      p {
        font-size: 14px;
        line-height: 24px;
        color: #888888;
        letter-spacing: 1.5px;
      }
      ul {
        li {
          margin-top: 9px;
          border-bottom: 1px solid #f1f1f1;
          padding-bottom: 9px;
          &:first-of-type {
            margin-top: 12px;
          }
          &:last-of-type {
            border-bottom: none;
          }
          a {
            font-size: 14px;
            width: 100%;
            height: 100%;
            display: block;
            color: #888888;
            text-decoration: none;
            letter-spacing: 1.5px;
            background: transparent url(../img/arrow02.png) no-repeat center right 5px;
            background-size: 7px auto;
          }
          &.current{
            a{
              color:#ac832d;
            }
          }
        }
      }
    }
    #Main{
      .news_MoreBtn {
        margin: 0 auto;
        margin-top: 30px;
        width: 220px;
        height: 50px;
        >div {
          text-align: center;
          span {
            width: 100%;
            height: 100%;
            display: block;
            font-size: 16px;
            color: #c5a22b;
            line-height: 50px;
            background: transparent url(../img/icon_plus.png) no-repeat center right 14px;
            background-size: 15px auto;
          }
        }
      }
      .news_MoreBtn.active >div span {
        background: transparent url(../img/icon_minus.png) no-repeat center right 14px;
        background-size: 16px auto;
      }
    }
  }
  /*
  お知らせ
  -------------------------------------*/
  #PageNews.pageIndex {
    #Container {
      .innerBasic {
        padding: 0 15px;
        #Main {
          width: 100%;
          margin-bottom: 83px;
          #ContBox01 {
            h3 {
              text-align: center;
              font-size: 24px;
              line-height: 32px;
              color: #222222;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              margin-bottom: 39px;
              margin-top: 57px;
              letter-spacing: 4.5px;
            }
            .news_largeBox {
              width: 100%;
              padding: 38px 30px 29px;
              border: 1px solid #e5e5e5;
              border-radius: 5px;
              margin-bottom: 16px;
              .news_smallBox01 {
                h4 {
                  font-size: 24px;
                  letter-spacing: 2px;
                  margin-bottom: 3px;
                  a {
                    color: #222;
                    text-decoration: none;
                    &:hover {
                      color: #c5a22b;
                    }
                  }
                }
                p {
                  font-size: 14px;
                  color: #aaaaaa;
                  border-bottom: 3px solid #f1f1f1;
                  margin-top: -3px;
                  padding-bottom: 21px;
                  span {
                    margin-left: 8px;
                    letter-spacing: 1.5px;
                  }
                }
              }
              .news_smallBox02 {
                img {
                  width:100%;
                  margin-top: 22px;
                }
                p {
                  font-size: 16px;
                  margin-top: 21px;
                  letter-spacing: 1.3px;
                  &:first-of-type {
                    margin-top: 25px;
                  }
                  &:nth-of-type(4) {
                    margin-top: 25px;
                  }
                }
                ul {
                  margin-top: 20px;
                  li {
                    width: 170px;
                    height: 40px;
                    margin-right: 0;
                    margin-left: auto;
                    a {
                      text-decoration: none;
                      font-size: 14px;
                      line-height: 40px;
                      &:hover {
                        box-shadow: 0 4px 6px 0 rgba(100, 81, 35, .1);
                        border-color: #e8d492;
                      }
                    }
                  }
                }
              }
            }
            dl.news_listBox {
              width: 100%;
              border: 1px solid #e5e5e5;
              border-radius: 5px;
              padding: 23px 15px;
              margin-bottom: 16px;
              dt {
                font-size: 14px;
                color: #aaaaaa;
                letter-spacing: 1.5px;
              }
              dd {
                margin-top: 9px;
                a {
                  font-size: 20px;
                  color: #222222;
                  text-decoration: none;
                  letter-spacing: 1.5px;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  お知らせ（カテゴリ）
  -------------------------------------*/
  #PageNews.pageCategory {
    #Container {
      .innerBasic {
        padding: 0 15px;
        #Main {
          width: 100%;
          margin-bottom: 89px;
          #ContBox01 {
            h3 {
              text-align: center;
              font-size: 24px;
              line-height: 32px;
              color: #222222;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              margin-bottom: 39px;
              margin-top: 72px;
              letter-spacing: 4.5px;
            }
            dl {
              width: 100%;
              border: 1px solid #e5e5e5;
              border-radius: 5px;
              padding: 23px 15px;
              margin-bottom: 16px;
              dt {
                font-size: 14px;
                color: #aaaaaa;
                letter-spacing: 1.5px;
              }
              dd {
                margin-top: 9px;
                a {
                  font-size: 20px;
                  color: #222222;
                  text-decoration: none;
                  letter-spacing: 1.5px;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
  /*
  お知らせ（詳細）
  -------------------------------------*/
  #PageNews.pageEntry {
    #Container{
      .sub_news{
        padding:0 15px;
      }
      #Main {
        margin-bottom:80px;
        margin-top:55px;
        #ContBox01 {
          padding:0 15px;
          .newsTitle{
            border-bottom:3px solid #f1f1f1;
            padding-bottom:25px;
            margin-bottom:40px;
            h3{
              font-size:28px;
              font-family: "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
              font-weight: 500;
              text-align:left;
            }
            .date{
              margin-top:2px;
              li{
                font-size:14px;
                color:#aaa;
              }
            }
          }
          .entryBox{
            border-bottom:1px solid #f1f1f1;
            padding-bottom:20px;
          }
          .pageMove{
            margin:45px 0 0;
            ul{
              text-align:center;
              li{
                width:130px;
                height:40px;
                line-height:40px;
                display:inline-block;
                &:first-of-type {
                  a {
                    padding-left: 10px;
                  }
                }
                &+li{
                  margin-left:15px;
                }
                &.btn{
                  a{
                    background-repeat:no-repeat;
                    background-size:7px auto;
                  }
                }
                &.prev{
                  a{
                    background-image:url(../img/arrow03.png);
                    background-position:left 14px center;
                  }
                }
                &.next{
                  a{
                    background-image:url(../img/arrow04.png);
                    background-position:right 14px center;
                  }
                }
              }
            }
          }
        }
        #ContBox02 {
          margin-top:75px;
          border-top: 1px solid #e5e5e5;
          padding: 3px 0 0;
          .boxTitle{
            margin-bottom:16px;
          }
          .newsList{
            width:auto;
            border-top:none;
            padding:0 15px;
            margin-top:0;
            li{
              &:last-child{
                border-bottom:none;
              }
            }
          }
        }
      }
    }
  }

  /*
  よくある質問
  -------------------------------------*/
  #PageFaq.pageIndex {
    #Container {
      .innerBasic {
        #Main {
          margin-top:30px;
          margin-bottom:130px;
          min-height:auto;
          h3{
            padding:25px 45px 36px;
            text-align:center;
            border-top:1px solid #f1f1f1;
            font-size:24px;
            span{
              color:#ac832d;
              display:block;
              font-size:14px;
              font-family: "Roboto Slab", serif;
              font-weight: 300;
              letter-spacing:2px;
              margin-bottom:4px;
            }
          }

          #ContBox01{
            .contSubBox{
              border-bottom:1px solid #f1f1f1;
              margin:0 15px 40px;
              &:last-of-type{
                border-bottom:none;
              }
              dl{
                dt{
                  font-size:18px;
                  color:#ac832d;
                  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                  font-weight: 400;
                  padding-bottom:25px;
                  border-bottom:1px solid #e7d48a;
                  &:after{
                    //content:"";
                    //width:8px;
                    //height:16px;
                    //padding-left: 12px;
                    //display: inline-block;
                    //background-repeat: no-repeat;
                    //background-position: center center;
                    //background-image: url(../img/arrow10.png);
                    //background-size: 8px auto;
                  }
                  a{
                    color:#ac832d;
                    text-decoration: none;
                    background:url(../img/arrow04.png) no-repeat center right;
                    background-size: 9px auto;
                    padding-right: 17px;
                  }
                }
                dd{
                  padding:7px 0 35px;
                  margin-left: -15px;
                  ul{
                    position:relative;
                    &:before{
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      display: block;
                      width: 15px;
                      height: 100%;
                      background-color: #fff;
                    }
                    li{
                      display:inline-block;
                      margin:10px 0 0;
                      border-left: 1px solid #dbdbdb;
                      a{
                        font-size:16px;
                        color:#888;
                        display: block;
                        padding: 3px 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  よくある質問（カテゴリ）
  -------------------------------------*/
  #PageFaq.pageCategory {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01{
            .contSubBox{
              border-bottom:1px solid #f1f1f1;
              margin:30px 15px 78px;
              &:last-of-type{
                border-bottom:none;
              }
              /*dl{
                dt{
                  font-size:18px;
                  color:#ac832d;
                  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                  font-weight: 400;
                  padding-bottom:25px;
                  border-bottom:1px solid #e7d48a;
                }
                dd{
                  padding:13px 0 35px;
                  //margin-left: -15px;
                  ul{
                    position:relative;
                    &:before{
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      display: block;
                      width: 15px;
                      height: 100%;
                      background-color: #fff;
                    }
                    li{
                      display:inline-block;
                      margin:10px 0 0;
                      border-left: 1px solid #dbdbdb;
                      a{
                        font-size:16px;
                        color:#888;
                        display: block;
                        padding: 3px 15px;
                      }
                    }
                  }
                }
              }*/
              dl{
                dt{
                  border-bottom:1px solid #e7d48a;
                  font-size:18px;
                  color:#ac832d;
                  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                  font-weight: 400;
                  background-image:url(../img/icon_plus.png);
                  background-repeat:no-repeat;
                  background-size:15px auto;
                  background-position: top 6px right 5px;
                  padding-bottom: 22px;
                  margin-bottom: 12px;
                  &.open{
                    background-image:url(../img/icon_minus.png);
                    background-position: top 13px right 5px;
                  }
                }
                dd{
                  border-bottom:1px solid #f1f1f1;
                  padding-bottom: 34px;
                  ul{
                    position:relative;
                    margin-left:-15px;
                    &:before{
                      content:"";
                      display:block;
                      width:15px;
                      height:100%;
                      background-color:#fff;
                      position:absolute;
                      top:0;
                      left:0;
                    }
                    li{
                      font-size:16px;
                      display:inline-block;
                      border-left:1px solid #dbdbdb;
                      margin-top:7px;
                      a{
                        color:#888;
                        padding:5px 15px;
                        display:block;
                      }
                      &.current{
                        a{
                          color:#ac832d;
                          text-decoration:none;
                        }
                      }
                    }
                  }
                }
              }
              .showBox_sp{
                //display:none;
              }
            }
          }
          .contboxFaq{
            margin-bottom:75px;
            h4{
              padding: 15px 18px;
              width: 100%;
              background-color: #cfad64;
              line-height: 32px;
              font-size: 20px;
              font-weight: 700;
              color: #fff;
            }
            dl{
              //width:100%;
              margin:0 15px;
              display:block;
              border-bottom:1px solid #f1f1f1;
              padding:29px 0;
              dt{
                margin-bottom:10px;
                width: 100%;
                display: table;
                div{
                  display: table-cell;
                  vertical-align: top;
                  width: 45px;
                }
                span{
                  vertical-align:top;
                  display: inline-block;
                  border-radius: 50%;
                  width: 35px;
                  height: 35px;
                  text-align: center;
                  font-family: "Roboto Slab", serif;
                  font-size: 15px;
                  font-weight: 300;
                  background: linear-gradient(45deg, #b58b32 0%, #dabe68 36%, #ebd896 67%, #cfb054 100%);
                  line-height: 35px;
                  color: #fff;
                  margin-right:3px;
                }
                p{
                font-size:20px;
                padding-top:3px;
                line-height: 30px;
                color:#222222;
                font-weight:500;
                display: table-cell;
                  vertical-align: top;
                  //width:80%;
                  //display:inline-block;

                }
                a{
                  color:#222;
                  text-decoration: none;
                }
              }
              dd{

                color:#888888;
                display: table;
                width: 100%;
                margin-top:15px;
                div{
                  vertical-align:top;
                  display: table-cell;
                  width: 73px;
                  padding-left:30px;
                  padding-right:8px;
                  padding-top:2px;
                }
                span{
                  vertical-align:top;
                  display: inline-block;
                  border-radius: 50%;
                  width: 35px;
                  height: 35px;
                  background-color: #f5f5f5;
                  line-height: 35px;
                  text-align: center;
                  font-family: "Roboto Slab", serif;
                  font-size: 15px;
                  font-weight: 300;
                  background: linear-gradient(45deg, #818181 0%, #adaeae 36%, #e7e7e8 67%, #b7b8b7 100%);
                  color: #fff;
                  //margin-top:2px;
                  //margin-left:30px;
                  //margin-right:8px;
                }
                p{
                  //width:77%;
                  display: table-cell;
                  vertical-align: top;
                  //display:inline-block;
                  padding-top:6px;
                  font-size:14px;
                  line-height:24px;
                }
              }
            }
            .linkBtn {
                margin: 30px auto 0;
                width: 220px;
                height: 40px;
                line-height: 40px;
            }
          }

        }
      }
    }
  }

  /*
  よくある質問（詳細）
  -------------------------------------*/
  #PageFaq.pageEntry {
    #Container {
      .innerBasic {
        #Main {
          margin-bottom: 92px;
          #ContBox01 {
            padding-bottom: 30px;
            h3 {
              margin-bottom: 50px;
            }
            .contSubBox{
              border-bottom:1px solid #f1f1f1;
              margin:0 15px 40px;
              &:last-of-type{
                border-bottom:none;
              }
              /*dl{
                dt{
                  font-size:18px;
                  color:#ac832d;
                  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                  font-weight: 400;
                  padding-bottom:25px;
                  border-bottom:1px solid #e7d48a;
                  &:after{
                    content:"";
                    width:8px;
                    height:16px;
                    padding-left: 12px;
                    display: inline-block;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url(../img/arrow10.png);
                    background-size: 8px auto;
                  }
                }
                dd{
                  padding:7px 0 35px;
                  margin-left: -15px;
                  ul{
                    position:relative;
                    &:before{
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      display: block;
                      width: 15px;
                      height: 100%;
                      background-color: #fff;
                    }
                    li{
                      display:inline-block;
                      margin:10px 0 0;
                      border-left: 1px solid #dbdbdb;
                      a{
                        font-size:16px;
                        color:#888;
                        display: block;
                        padding: 3px 15px;
                      }
                    }
                  }
                }
              }*/
              dl{
                dt{
                  border-bottom:1px solid #e7d48a;
                  font-size:18px;
                  color:#ac832d;
                  font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
                  font-weight: 400;
                  background-image:url(../img/icon_plus.png);
                  background-repeat:no-repeat;
                  background-size:15px auto;
                  background-position: top 6px right 5px;
                  padding-bottom: 22px;
                  margin-bottom: 12px;
                  &.open{
                    background-image:url(../img/icon_minus.png);
                    background-position: top 13px right 5px;
                  }
                }
                dd{
                  border-bottom:1px solid #f1f1f1;
                  padding-bottom: 34px;
                  ul{
                    position:relative;
                    margin-left:-15px;
                    &:before{
                      content:"";
                      display:block;
                      width:15px;
                      height:100%;
                      background-color:#fff;
                      position:absolute;
                      top:0;
                      left:0;
                    }
                    li{
                      font-size:16px;
                      display:inline-block;
                      border-left:1px solid #dbdbdb;
                      margin-top:7px;
                      a{
                        color:#888;
                        padding:5px 15px;
                        display:block;
                      }
                      &.current{
                        a{
                          color:#ac832d;
                          text-decoration:none;
                        }
                      }
                    }
                  }
                }
              }
              .showBox_sp{
                display:none;
              }
            }

            .click {
              width: 250px;
              height: 50px;
              margin: 0 auto;
              padding: 0;
              margin-bottom: 0;
              >div {
                margin: 0 auto;
                margin-top: 60px;
                width: 250px;
                height: 50px;
                text-align: center;
                span {
                  width: 100%;
                  height: 100%;
                  display: block;
                  font-size: 16px;
                  color: #c5a22b;
                  line-height: 50px;
                  background: transparent url(../img/icon_plus_pc.png) no-repeat center right 19px;
                  background-size: 15px auto;
                }
              }
            }
          }
          .contboxFaq{
            margin-bottom:90px;
            h4{
              padding: 15px 18px;
              width: 100%;
              background-color: #cfad64;
              line-height: 32px;
              font-size: 20px;
              font-weight: 700;
              color: #fff;
            }
            dl{
              display:block;
              border-bottom:1px solid #f1f1f1;
              padding:30px 0;
              margin:0 15px;
              dt{
                display: table;
                width: 100%;
                div{
                  vertical-align:top;
                  display: table-cell;
                  padding-right:8px;
                  width: 43px;
                }
                p{
                  display: table-cell;
                  vertical-align: top;
                  //display:inline-block;
                  margin-top:8px;
                  font-size:20px;
                  line-height: 30px;
                  color:#222222;
                }
                span{
                  display: inline-block;
                  border-radius: 50%;
                  width: 35px;
                  height: 35px;
                  text-align: center;
                  font-family: "Roboto Slab", serif;
                  font-size: 15px;
                  font-weight: 300;
                  background: linear-gradient(45deg, #b58b32 0%, #dabe68 36%, #ebd896 67%, #cfb054 100%);
                  line-height: 35px;
                  color: #fff;
                  //margin-right:15px;
                }
                font-size:20px;
                line-height: 30px;
                color:#222222;
                // font-weight:bold;
              }
              dd{
                display: table;
                width: 100%;
                margin-top:10px;
                .icon{
                  vertical-align:top;
                  display: table-cell;
                  padding-right:10px;
                  span{
                  display: inline-block;
                  border-radius: 50%;
                  width: 35px;
                  height: 35px;
                  background-color: #f5f5f5;
                  line-height: 35px;
                  text-align: center;
                  font-family: "Roboto Slab", serif;
                  font-size: 15px;
                  font-weight: 300;
                  background: linear-gradient(45deg, #818181 0%, #adaeae 36%, #e7e7e8 67%, #b7b8b7 100%);
                  color: #fff;
                  //margin-left:30px;
                  //margin-right:15px;
                  }
                }
                .entryWrapBox{
                  //width:77%;
                  display: table-cell;
                  vertical-align: top;
                  //display:inline-block;
                  padding-top:6px;
                  font-size:16px;
                  line-height:28px;
                }
                font-size:16px;
              }
            }
            .linkBtn {
                margin: 45px auto 0;
                width: 250px;
                height: 50px;
                line-height: 50px;
            }
          }

          #ContBox02 {
            margin-top: 67px;
            h3 {
              letter-spacing: 6px;
            }
            .catBlock {
              margin-top: 21px;
              padding: 0 15px;
              .showBox_sp {
                .catBox {
                  margin-bottom: 13px;
                  dl {
                    dt {
                      margin-top: -7px;
                    }
                  }
                  &:nth-of-type(even) {
                  }
                }
              }
            }
          }
          #ContBox03 {
            margin-top: 67px;
            padding: 0 15px;
          }
          #ContBox04 {
            margin-top: 68px;
            margin-bottom: 92px;
            padding: 0 15px;
          }
          ul.blogList {  //関連するコラム、質問の中身：ul,li
            margin-top: 22px;
            padding-bottom: 0;
            li {
              padding-bottom: 24px;
              dl {
                margin-bottom: 9px;
                dt {
                  margin-bottom: 5px;
                }
              }
            }
          }
          .linkBtn {
            width: 250px;
            height: 50px;
            margin: 0 auto;
            margin-top: 32px;
            a {
              line-height: 50px;
              letter-spacing: 1px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  /*
  コラム
  -------------------------------------*/
  #PageColumn.pageIndex {
    #Container {
      #Main{
        #ContBox01{
          padding:0 15px;
        }
        #ContBox02{
          padding:0 15px;
          margin-top:35px;
        }
        #ContBox03 {
          margin-bottom: 77px;
          &:after {
            content: ".";
            display: block;
            height: 0;
            font-size:0;
            clear: both;
            visibility:hidden;
          }
          h3 {
            font-family: 'Roboto Slab', serif;
            font-weight: 300;
            font-size: 30px;
            color: #ac832d;
            margin-top: 64px;
            letter-spacing: 3px;
            border-bottom: 1px solid #f1f1f1;
            padding-bottom: 38px;
            span {
              font-size: 20px;
              color: #222222;
              display: block;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              letter-spacing: 3.5px;
              margin-top: 2px;
            }
          }
          >div {
            text-align: center;
            border-bottom: 1px solid #f1f1f1;
            img {
              width: 185px;
              margin-top: 28px;
            }
            h4 {
              width: 160px;
              margin: 0 auto;
              color: #ac832d;
              font-size: 18px;
              font-family: "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              font-weight: 400;
              padding-top: 5px;
              padding-bottom: 5px;
              border-top: 1px solid #e7d48a;
              border-bottom: 1px solid #e7d48a;
              margin-bottom: 22px;
            }
            ul {
              padding: 0 45px;
              margin-bottom: 33px;
              li {
                margin: 0 auto;
                text-align: left;
                margin-top: 8px;
                border-bottom: 1px solid #f1f1f1;
                padding-bottom: 7px;
                &:last-of-type {
                  border-bottom: none;
                }
                a {
                  width: 100%;
                  height: 100%;
                  line-height: 24px;
                  display: block;
                  font-size: 16px;
                  color: #888888;
                  text-decoration: none;
                  letter-spacing: 2px;
                  background: transparent url(../img/arrow02.png) no-repeat center right 5px;
                  background-size: 7px auto;
                  span {
                    width: 160px;
                  }
                }
              }
            }
            &:nth-of-type(4) {
              border-right: 1px solid #f1f1f1;
            }
          }
        }
      }
      .innerBasic {
        #Main {
          #ContBox02 {
            margin-top: 60px;
            margin-left: 15px;
            margin-right: 15px;
            border: 1px solid #e5e5e5;
            border-radius: 7px;
            >div {
              padding: 30px 15px 0 30px;
              >div {
                border-bottom: 1px solid #f1f1f1;
                padding-bottom: 24px;
              }
              &:last-of-type {
                >div {
                  border-bottom: none;
                }
              }
              img {
                width: 135px;
                float: left;
                margin-bottom: 10px;
              }
              h5 {
                display: inline-block;
                font-size: 20px;
                margin-top: -8px;
                letter-spacing: 2px;
                float:left;
                width: 100%;
                padding-left: 148px;
                margin-left: -135px;
                margin-bottom: 5px;
                a {
                  text-decoration: none;
                  color: #222;
                }
              }
              p {
                display: inline-block;
                font-size: 14px;
                color: #888888;
                letter-spacing: 1px;
                padding-right: 15px;
                line-height: 24px;
                &:nth-of-type(2) {
                  margin-top: -1px;
                  span {
                    margin-left: 7px;
                    letter-spacing: 1.5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  コラム（カテゴリ）
  -------------------------------------*/
  #PageColumn.pageCategory {
    #Container {
      .innerBasic {
        #Main {
          margin-top: 0;
          #ContBox01 {
            margin: 48px 15px 90px;
            h3 {
              margin-bottom: 37px;
            }
            >div {
              padding: 30px 15px 21px 30px;
              border: 1px solid #e5e5e5;
              border-radius: 7px;
              margin-bottom: 30px;
              img {
                width: 135px;
                float: left;
                margin-bottom: 10px;
                height: auto;
              }
              h5 {
                display: inline-block;
                font-size: 20px;
                margin-top: -5px;
                letter-spacing: 2px;
                float:left;
                width: 100%;
                padding-left: 148px;
                margin-left: -135px;
                margin-bottom: 5px;
                a {
                  text-decoration: none;
                  color: #222;
                }
              }
              p {
                display: inline-block;
                font-size: 14px;
                color: #888888;
                letter-spacing: 1px;
                padding-right: 15px;
                line-height: 24px;
                &:nth-of-type(2) {
                  margin-top: -1px;
                  color: #aaa;
                  span {
                    margin-left: 7px;
                    letter-spacing: 1.5px;
                  }
                }
              }
              &:first-of-type {
              }
              &:last-of-type {

              }
            }
            .click.active >div span {
              background: transparent url(../img/icon_minus.png) no-repeat center right 15px;
              background-size: 16px auto;
            }
            .click {
              border: none;
              width: 250px;
              padding: 0;
              margin: 35px auto 0;
              >div {
                border: 1px solid #e7d48a;
                border-radius: 7px;
                width: 220px;
                height: 50px;
                margin: 0 auto;
                span {
                  display: block;
                  width: 100%;
                  height: 100%;
                  background: transparent url(../img/icon_plus.png) no-repeat center right 15px;
                  background-size: 15px auto;
                  line-height: 50px;
                  text-align: center;
                  font-size: 16px;
                  color: #c5a22b;
                  letter-spacing: 0;
                }
              }
            }
            .showBox {
              border: none;
              padding: 0 15px;
              >div.shourei {

              }
            }
          }
        }
      }
    }
  }

  /*
  コラム（詳細）
  -------------------------------------*/
  #PageColumn.pageEntry {
    #Container {
      .innerBasic {
        #Main {
          margin-bottom: 92px;
          #ContBox01 {
            margin-bottom: 50px;
            margin-top: 50px;
            >div.h3_box {
              margin-bottom: 34px;
              padding: 0 15px;
              h3 {
                text-align: left;
                border-bottom: 3px solid #f1f1f1;
                font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                font-weight: 500;
                letter-spacing: 2.5px;
                padding-bottom: 15px;
                line-height: 40px;
                font-size: 28px;
                span {
                  color: #aaaaaa;
                  font-size: 14px;
                  display: block;
                  letter-spacing: 1.5px;
                  margin-top: -7px;
                }
              }
              p {
                font-size: 14px;
                margin-top: 25px;
                letter-spacing: 1.5px;
                span {
                  margin-left: 26px;
                  position: relative;
                  &:before {
                    content: "";
                    display: inline-block;
                    width: 32px;
                    height: 32px;
                    background: url(../img/icon_time.png) no-repeat;
                    background-position: left top;
                    background-size: 16px auto;
                    position: absolute;
                    top: 0;
                    left: -21px;
                  }
                }
              }
            }
            .faq_box {
              width: 100%;
              .entryFaqBox01 {

              }
            }
          }
          #ContBox02 {
            margin-top: 67px;
            h3 {
              letter-spacing: 6px;
            }
            .catBlock {
              margin-top: 21px;
              padding: 0 15px;
              .showBox_sp {
                .catBox {
                  margin-bottom: 13px;
                  dl {
                    dt {
                      margin-top: -7px;
                    }
                  }
                  &:nth-of-type(even) {
                  }
                }
              }
            }
          }
          #ContBox03 {
            margin-top: 67px;
            padding: 0 15px;
          }
          #ContBox04 {
            margin-top: 68px;
            margin-bottom: 92px;
            padding: 0 15px;
          }
          ul.blogList {  //関連するコラム、質問の中身：ul,li
            margin-top: 22px;
            padding-bottom: 0;
            li {
              padding-bottom: 24px;
              dl {
                margin-bottom: 9px;
                dt {
                  margin-bottom: 5px;
                }
              }
            }
          }
          .linkBtn {
            width: 250px;
            height: 50px;
            margin: 0 auto;
            margin-top: 32px;
            a {
              line-height: 50px;
              letter-spacing: 1px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  /*
  カウンセリング予約
  -------------------------------------*/
  #PageContact.pageIndex {
    #Container {
      .innerBasic {
        width: 100%;
        margin: 0 auto;
        #Main {
          #ContBox01 {
            padding: 0 15px;
            h3 {
              margin-bottom: 51px;
            }
            .btnForForeigners{
              a{
                color: #c5a22b;
                border: 1px solid #e7d48a;
                border-radius: 7px;
                text-align: center;
                font-size: 20px;
                padding: 9px 0;
                margin: 0 auto 40px;
                background-image: url(../img/icon_link.png);
                background-size: 15px auto;
                background-position: right 20px center;
                background-repeat: no-repeat;

                display: block;
                text-decoration: none;
                line-height: 1.2;
                width: 100%;
              }
              span{
                display: block;
              }
              b{
                font-family: "Noto Sans SC", sans-serif;
                font-optical-sizing: auto;
                font-weight: 300;
                font-style: normal;
              }
            }
            .contact_calenderBlock {
              //非表示
              display: none;

              .innerBasic {
                .left {
                  margin-top: 52px;
                  dl.contactBox {
                    dt {
                    }
                    dd {
                      &:first-of-type.tel {
                        width: 100%;
                        letter-spacing: 3px;
                        margin-top: 14px;
                        font-size: 30px;
                        a {
                          border-width:5px
                        }
                      }
                      &:nth-of-type(2) {
                        display: block;
                        width: 100%;
                        font-size: 12px;
                        color: #888888;
                        text-align: center;
                        margin-top: -3px;
                        letter-spacing: 2px;
                        span {
                          display:inline-block;
                        }
                      }
                      &:nth-of-type(3) {
                        width: 100%;
                        color: #222222;
                        font-size: 14px;
                        margin-top: 11px;
                        line-height: 24px;
                        text-align: left;
                        letter-spacing: 1.8px;
                      }
                    }
                  }
                  ul.clearfix {
                    margin-top: 8px;
                    li.mailLink {
                      width: 100%;
                      height: 50px;
                      a {
                        position: relative;
                        display: block;
                        border-radius: 7px;
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-position: center left 56px;
                        background-size: 26px auto;
                        text-align: left;
                        text-decoration: none;
                        >div {
                          top: 55%;
                          left: 138px;
                          right: 0;
                          margin: 0 auto;
                          letter-spacing: 1.5px;
                          //background-image: url(../img/arrow01.png.png);
                          position: absolute;
                          margin-left: -48px;
                          &:after {
                            content: "";
                            display: inline-block;
                            width: 20px;
                            height: 20px;
                            background: url(../img/arrow01.png) no-repeat;
                            background-position: left top;
                            background-size: 17px auto;
                            position: absolute;
                            top: 4px;
                            right: 20px;
                          }
                        }
                      }
                    }
                  }
                }
                .right {
                  margin-top: 42px;
                  >div.cal_wrapper {
                    .googlecal {
                      iframe {

                      }
                    }
                  }
                }
              }
            }
            >dl {
              border: 5px solid #f1f1f1;
              padding: 20px 15px 35px;
              margin-top: 24px;
              letter-spacing: 2px;
              dt {
                font-size: 16px;
                color: #ac832d;
                font-weight: 400;
                border-bottom: 1px solid #e7d48a;
                padding-bottom: 18px;
              }
              dd {
                margin-top: 26px;
                font-size: 14px;
                line-height: 24px;
                color: #888888;
                letter-spacing: 1.2px;
                span {
                  display: block;
                  margin-top: 20px;
                }
              }
            }
          }
          #ContBox02 {
            margin: 0 auto;
            margin-top: 66px;
            h3 {
              padding: 0 15px;
            }
            p {
              text-align: left;
              margin-top: 47px;
              letter-spacing: 1.2px;
              padding: 0 15px;
              a {
              }
              span {
              }
            }
            form {
              margin-top: 35px;
              >dl {  //共通設定
                border-top: 1px solid #f1f1f1;
                padding-bottom: 28px;
                font-size: 0;
                letter-spacing: normal;
                padding: 0 15px;
                dd.require {
                  width: 40px;
                  display: inline-block;
                  vertical-align: top;
                  color: #fefefe;
                  font-size: 12px;
                  background-color: #e42f2f;
                  text-align: center;
                  height: 20px;
                  line-height: 20px;
                  margin-top: 30px;
                }
                dt {
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 30px;
                  line-height: 20px;
                  font-size: 16px;
                  color: #222222;
                  padding-left: 10px;
                  letter-spacing: 2px;
                }
                dd:nth-of-type(2) {
                  font-size: 16px;
                  color: #222;
                  padding-top: 11px;
                  >div.radio {
                    display: block;
                    margin-bottom: 10px;
                    &:nth-of-type(3) {
                      margin-top: 37px;
                    }
                    label {
                      letter-spacing: 1.5px;
                      margin-left: -40px;
                      padding-left: 50px;
                      display: inline-block;
                      float: right;
                      width: 100%;
                      margin-top: 6px;
                      line-height: 26px;
                    }
                    span {
                      width: 40px;
                      height: 40px;
                      background: transparent url(../img/radio02.png) no-repeat scroll left center;
                      background-size: 40px 40px;
                      float: left;
                      input {
                        font-size: 16px;
                        width: 40px;
                        height: 40px;
                        width: 100%;
                      }
                    }
                    span.checked {
                      background: transparent url(../img/radio01.png) no-repeat scroll left center;
                      background-size: 40px 40px;
                    }
                  }
                  >div.cautionText {

                  }
                  >input {
                    font-size: 16px;
                    border-radius: 5px;
                    height: 50px;
                    background-color: #eee;
                    font-size: 16px;
                    color: #222;
                    padding-left: 17px;
                    letter-spacing: 1.5px;
                    width: 100%;
                    &:focus{
                      border: 1px solid #e8d492;
                      background-color: #fff;
                    }
                  }
                  >span {
                    margin-top: 18px;
                    letter-spacing: -.5px;
                    display: inline-block;
                    line-height: 24px;
                    letter-spacing: 1.5px;
                    font-size: 14px;
                    color: #888;
                  }
                  input:-webkit-input-placeholder{
                    color: #888;
                  }
                  input::-webkit-input-placeholder{
                    color: #888;
                  }
                  input:-ms-input-placeholder{
                    color: #888;
                  }
                  textarea:-webkit-input-placeholder{
                    color: #888;
                  }
                  textarea::-webkit-input-placeholder{
                    color: #888;
                  }
                  textarea:-ms-input-placeholder{
                    color: #888;
                  }
                }
                &:first-of-type {  //予約の内容
                  padding-bottom: 23px;
                  dd:nth-of-type(2) {
                    padding-top: 13px;
                    >div.radio {
                      display:table;
                      width:100%;
                      table-layout:fixed;
                      .radio{
                        display:table-cell;
                        vertical-align:top;
                        width:40px;
                      }
                      label{
                        display:table-cell;
                        vertical-align:top;
                        float:none;
                        padding-left: 10px;
                        padding-top: 6px;
                      }
                      &:nth-of-type(3) {
                        margin-bottom: 15px;
                        margin-top:0;
                      }
                    }
                    >span {
                      margin-top: 7px;
                    }
                    >div {
                      &:nth-of-type(4) {
                        label {
                          padding-top: 11px;
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(2) {  //お名前
                  dd {
                    &:nth-of-type(2) {
                      input {
                      }
                    }
                  }
                }
                &:nth-of-type(3) {  //フリガナ

                }
                &:nth-of-type(4) {  //メールアドレス
                  dd {
                    &:nth-of-type(2) {
                      input {
                        width: 100%;
                      }
                    }
                  }
                }
                &:nth-of-type(5) {  //確認用メールアドレス
                  padding-bottom: 24px;
                  dd:nth-of-type(2) {
                    input {
                      width: 100%;
                    }
                    span {
                      display: block;
                      margin-top: 13px;
                      font-size: 14px;
                      color: #888888;
                      letter-spacing: 1.5px;
                    }
                  }
                }
                &:nth-of-type(6) {  //電話番号

                }
                &:nth-of-type(7) {  //性別
                  padding-bottom: 22px;
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 13px;
                      >div.radio {
                        display: inline-block;
                        vertical-align:top;
                        input {
                          span {
                          }
                        }
                        &+div.radio{
                          margin-left: 23px;
                          margin-top:0;
                        }
                      }
                      >span {
                        display: inline-block;
                        height: auto;
                        background: none;
                        vertical-align: top;
                        margin-left: -2px;
                        margin-top: 0;
                      }
                    }
                  }
                }
                &:nth-of-type(8) {  //来院歴
                  padding-bottom: 21px;
                  dd {
                    &:nth-of-type(2) {
                      padding-top: 12px;
                      >div.radio {
                        display: inline-block;
                        vertical-align:top;
                        input {
                          span {
                          }
                        }
                        &+div.radio {
                          margin-left: 27px;
                          margin-top:0;
                        }
                      }
                      >span {
                        display: inline-block;
                        height: auto;
                        background: none;
                        vertical-align: top;
                        margin-top: 0;
                        margin-left: -2px;
                      }
                    }
                  }
                }
                &:nth-of-type(9) {  //ご希望の予約日
                  padding-bottom: 20px;
                  &:after {
                    content: ".";
                    display: block;
                    height: 0;
                    font-size:0;
                    clear: both;
                    visibility:hidden;
                  }
                  dd {
                    &:nth-of-type(2) {
                      display: block;
                      width: 200px;
                      font-size: 14px;
                      line-height: 24px;
                      color: #e42f2f;
                      margin-top: -3px;
                      padding-left: 1.5em;
                      text-indent: -1.5em;
                      letter-spacing: .7px;
                    }
                    &:nth-of-type(3) {
                      margin-top: 1px;
                      .contact_calenderBlock {
                        width: 100%;
                        .innerBasic {
                          width: 100%;
                          .left {
                            width: 100%;
                            >dl {
                              >dd {
                                &:nth-of-type(2) {
                                  padding-top: 6px;
                                  padding-left: 1px;
                                  width: 100%;
                                  &:after {
                                    content: ".";
                                    display: block;
                                    height: 0;
                                    font-size:0;
                                    clear: both;
                                    visibility:hidden;
                                  }
                                  >input {
                                    width: 100%;
                                    margin-top: 9px;
                                  }
                                  >div.selectorBox {  //時
                                    float: left;
                                    select {
                                      border-radius: 5px;
                                      padding-left: 17px;
                                      width: 70px;
                                      height: 50px;
                                      background-color: #eee;
                                      letter-spacing: 1.5px;
                                      font-size: 16px;
                                      font-size: 16px;
                                      color: #222;
                                      margin-left: 20px;
                                      margin-top: 14px;
                                      background: url(../img/arrow07.png) no-repeat;
                                      background-size: 10px auto;
                                      background-position: right 15px center;
                                      &:focus{
                                        border: 1px solid #e8d492;
                                        background-color: #fff;
                                      }
                                    }
                                    span {
                                      margin-left: 6px;
                                      margin-right: 17px;
                                      vertical-align: middle;
                                      position: relative;
                                      top: 7px;
                                      font-size: 16px;
                                      color: #222222;
                                    }
                                  }
                                  >div.selectorBox_m {  //分
                                    float: left;
                                    margin-left: -5px;
                                    span {

                                    }
                                  }
                                }
                              }
                              &:first-of-type {  //第1希望日時
                                border-top: none;
                                margin-top: -9px;
                                margin-left: 30px;
                              }
                              &:nth-of-type(2) {  //第2希望日時
                                margin-left: 30px;
                              }
                              &:nth-of-type(3) {  //第3希望日時
                                margin-left: 30px;
                                dd {
                                  &:first-of-type {
                                    display: inline-block;
                                    margin-top: 30px;
                                    width: 40px;
                                    height: 20px;
                                    background-color: #b8b8b8;
                                    line-height: 20px;
                                    text-align: center;
                                    vertical-align: top;
                                    font-size: 12px;
                                    color: #fefefe;
                                  }
                                }
                              }
                            }
                          }
                          .right {
                            width: 100%;
                            margin-top: 30px;
                            .cal_wrapper {
                              width: 100%;
                              .googlecal {
                                width: 100%;
                                iframe {
                                  width: 100%;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &:last-of-type {  //ご相談内容
                  border-bottom: 1px solid #f1f1f1;
                  padding-bottom: 24px;
                  margin-top: -3px;
                  >dd {
                    &:nth-of-type(2) {
                      width: 100%;
                      textarea {
                        border-radius: 5px;
                        padding-top: 13px;
                        padding-left: 16px;
                        width: 100%;
                        height: 250px;
                        background-color: #eee;
                        letter-spacing: 1.5px;
                        font-size: 16px;
                        line-height: 28px;
                        color: #222;
                        resize: none;
                        &:focus{
                          border: 1px solid #e8d492;
                          background-color: #fff;
                        }
                      }
                    }
                  }
                }
              }
              p.parent_agree {
                color: #e42f2f;
                font-size: 14px;
                line-height: 24px;
                margin-top: 25px;
                letter-spacing: 1.5px;
                span {
                  a {
                    color: #e42f2f;
                    position: relative;
                    &:after {
                      content: "";
                      display: inline-block;
                      width: 20px;
                      height: 20px;
                      background: url(../img/img_pdf.png) no-repeat;
                      background-position: right center;
                      background-size: 20px auto;
                      position: absolute;
                      top: 0px;
                      right: -24px;
                    }
                  }
                }
              }
              p.minorLink{
                margin:20px 0;
                text-align: right;
                a{
                  font-size: 16px;
                  background: url(../img/icon_link.png) no-repeat right center;
                  background-size: 15px auto;
                  padding-right: 24px;
                }
              }
              >div.confirmBtn {
                padding: 0 15px;
                ul {
                  margin: 25px auto 75px;
                  width: 285px;
                  height: 80px;
                  li {
                    border-radius: 7px;
                    width: 100%;
                    width: 100%;
                    background-color: #d1ae39;
                    input {
                      display: block;
                      width: 100%;
                      height: 100%;
                      background: transparent url(../img/arrow05.png) no-repeat center right 15px;
                      background-size: 11px auto;
                      line-height: 80px;
                      text-align: center;
                      letter-spacing: 0;
                      font-size: 20px;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
          #ContBox03 {
            // width: 100vw;
            background-color: #f5f5f5;
            padding: 74px 15px;
            padding-bottom: 140px;
            .privacy_BigBox {
              width: 100%;
              text-align: left;
              margin: 0 auto;
              .privacy_Box01 {
                h3 {
                  font-size: 28px;
                  line-height: 32px;
                  margin-bottom: 50px;
                  letter-spacing: 5px;
                }
                >p {
                  font-size: 16px;
                  text-align: left;
                  letter-spacing: 1.5px;
                  margin-bottom: 45px;
                  span {
                  }
                }
              }
              .privacy_Box02 {
                h4 {
                  font-size: 20px;
                  border-bottom: 2px solid #e0e0e0;
                  margin-top: 43px;
                  margin-bottom: 26px;
                  line-height: 41px;
                  letter-spacing: 2px;
                }
                >p {
                  font-size: 16px;
                  margin-top: 20px;
                  letter-spacing: 1.5px;
                }
                ul {
                  margin-top: -4px;
                  li {
                    margin-top: 7px;
                    letter-spacing: 1.5px;
                    line-height: 27px;
                    margin-top: 3px;
                  }
                }
                >dl {
                  margin-top: 25px;
                  dt {
                  }
                  dd {
                    &:first-of-type {
                      margin-top: 7px;
                    }
                    &:nth-of-type(2) {
                      margin-top: 7px;
                      span {

                      }
                    }
                    &:last-of-type {
                      margin-top: 7px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  カウンセリング予約（入力内容のご確認）
  -------------------------------------*/
  #PageContact.pageConfirm {
    #Container {
      .innerBasic {
        #Main {
          margin-top: 53px;
          #ContBox01 {
            margin-bottom: 86px;
            h3 {
              font-size: 28px;
              margin-bottom: 59px;
              padding: 0 15px;
              span {
                display: block;
                font-size: 18px;
                margin-top:0;
                letter-spacing: 4px;
              }
            }
            p {
              font-size: 16px;
              text-align: left;
              letter-spacing: 1.5px;
              padding: 0 15px;
              span {
              }
            }
            form{
              >dl {  //全体設定
                border-top: 1px solid #f1f1f1;
                padding-bottom: 24px;
                font-size: 0;
                letter-spacing: normal;
                padding: 0 15px;
                >dt {
                  width: 200px;
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 28px;
                  font-size: 16px;
                  color: #222222;
                  padding-left: 10px;
                  letter-spacing: 2px;
                }
                dd.require {
                  width: 40px;
                  display: inline-block;
                  vertical-align: top;
                  color: #fefefe;
                  font-size: 12px;
                  background-color: #e42f2f;
                  text-align: center;
                  height: 20px;
                  line-height: 20px;
                  margin-top: 30px;
                }
                >dd {
                  &:nth-of-type(2) {
                    font-size: 16px;
                    color: #222;
                    padding-top: 9px;
                    letter-spacing: 1.5px;
                  }
                }
                &:first-of-type {  //予約の内容
                  margin-top: 36px;
                }
                &:nth-of-type(2) {  //お名前

                }
                &:nth-of-type(3) {  //フリガナ

                }
                &:nth-of-type(4) {  //メールアドレス

                }
                &:nth-of-type(5) {  //電話番号

                }
                &:nth-of-type(6) {  //性別

                }
                &:nth-of-type(7) {  //来院歴

                }
                &:nth-of-type(8) {  //ご希望の予約日
                  padding-bottom: 59px;
                  >dd {
                    &:nth-of-type(2) {
                      display: block;
                      width: 200px;
                      font-size: 14px;
                      line-height: 24px;
                      color: #e42f2f;
                      margin-top: 7px;
                      padding-left: 1.5em;
                      text-indent: -1.5em;
                      letter-spacing: .7px;
                      padding-top: 0;
                    }
                    &:nth-of-type(3) {
                      margin-left: 30px;
                      >dl {
                        >dt {
                          font-size: 16px;
                          color: #222;
                          display: inline-block;
                          vertical-align: top;
                          margin-top: 22px;
                          margin-left: 11px;
                          letter-spacing: 1.5px;
                        }
                        >dd {
                          &:nth-of-type(2) {
                            font-size: 16px;
                            display: block;
                            vertical-align: top;
                            margin-top: 10px;
                            letter-spacing: 1.5px;
                          }
                        }
                        >dd.require {
                          margin-top: 24px;
                        }
                        &:nth-of-type(2) {

                        }
                        &:nth-of-type(3) {
                          dd {
                            &:first-of-type {
                              width: 40px;
                              display: inline-block;
                              vertical-align: top;
                              text-align: center;
                              height: 20px;
                              line-height: 20px;
                              margin-top: 24px;
                              font-size: 12px;
                              color: #fefefe;
                              background-color: #b8b8b8;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(9) {  //ご相談内容
                  border-bottom: 1px solid #f1f1f1;
                  padding-bottom: 28px;
                  dd:nth-of-type(2) {
                    letter-spacing: 2.5px;
                  }
                }
              }
              >div {
                >ul.sendBtn {  //送信ボタン
                  margin: 45px auto 30px;
                  width: 285px;
                  height: 80px;
                  >li {
                    border-radius: 7px;
                    width: 100%;
                    background-color: #d1ae39;
                    input {
                      display: block;
                      width: 100%;
                      height: 100%;
                      background: transparent url(../img/arrow05.png) no-repeat center right 15px;
                      background-size: 11px auto;
                      line-height: 80px;
                      text-align: center;
                      letter-spacing: 2px;
                      font-size: 18px;
                      color: #fff;
                    }
                  }
                }
              }
              >ul.backBtn {  //戻るボタン
                >li {
                  margin: 0 auto;
                  border: 1px solid #e5e5e5;
                  border-radius: 7px;
                  width: 150px;
                  height: 40px;
                  >input {
                    display: block;
                    width: 100%;
                    height: 100%;
                    line-height: 40px;
                    text-align: center;
                    text-decoration: none;
                    font-size: 14px;
                    color: #888;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  カウンセリング予約（送信完了）
  -------------------------------------*/
  #PageContact.pageThanks {
    #Container {
      .innerBasic {
        padding: 0 15px;
        #Main {
          margin-bottom: -31px;
          #ContBox01 {
            h3 {
              margin-bottom: 57px;
              span {
                display: block;
                font-size: 18px;
                margin-top: -2px;
                letter-spacing: 4px;
              }
            }
            p {
              font-size: 16px;
              text-align: left;
              letter-spacing: 1.5px;
              &:nth-of-type(2) {
                margin-top: 22px;
                span {
                }
              }
            }
            ul {
              margin-top: 50px;
              li {
                margin: 0 auto;
                width: 250px;
                height: 50px;
                text-align: center;
                a {
                  display: block;
                  width: 100%;
                  height: 100%;
                  line-height: 50px;
                  text-decoration: none;
                  font-size: 16px;
                  color: #c5a22b;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  簡単画像診断
  -------------------------------------*/
  #PageDiagnostic-imaging.pageIndex {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            h3 {

            }
            >p {
              text-align: left;
              margin-top: 44px;
              letter-spacing: 1.2px;
              padding: 0 15px;
              a {
                &:hover {
                  text-decoration: none;
                }
              }
              span {
                &:first-of-type {
                }
                &:nth-of-type(2) {

                }
              }
            }
            form {
              margin-top: 35px;
              >dl {
                border-top: 1px solid #f1f1f1;
                padding-bottom: 30px;
                font-size: 0;
                letter-spacing: normal;
                padding: 0 15px;
                >dt {
                  width: 200px;
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 29px;
                  font-size: 16px;
                  color: #222222;
                  padding-left: 10px;
                  letter-spacing: 2px;
                }
                >dd {
                  &:first-of-type {
                    width: 40px;
                    display: inline-block;
                    vertical-align: top;
                    color: #fefefe;
                    font-size: 12px;
                    background-color: #e42f2f;
                    text-align: center;
                    height: 20px;
                    line-height: 20px;
                    margin-top: 30px;
                  }
                  &:nth-of-type(2) {  //画像添付
                    width: 100%;
                    padding-top: 16px;
                    input {  //inputタグ全体設定
                      //letter-spacing: 2px;
                      padding:0 16px;
                      background-color: #eeeeee;
                      color:#222;
                      &:focus{
                        border: 1px solid #e8d492;
                        background-color: #fff;
                      }
                    }
                    div.label_div {            //ファイル選択
                      table-layout: fixed;
                      position: relative;
                      display: table;
                      width: 100%;
                      margin-bottom:10px;
                      .mail_label{
                        display: table-cell;
                        border: 1px solid #ebdfaf;
                        border-radius: 7px;
                        width: 110px;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        vertical-align: middle;
                        font-size: 14px;
                        font-weight: 500;
                        cursor: pointer;
                        transition:0.4s;
                        color:#c5a22b;
                        input{
                          display:none;
                        }
                      }
                      .statusBox{
                        overflow: hidden;
                        display: table-cell;
                        padding: 0 94px 0 10px;
                        width: 100%;
                        vertical-align: middle;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        font-size: 16px;
                      }
                      .reset_button{
                        position: absolute;
                        top: 2px;
                        right: 0;
                        border: 1px solid #dfdfdf;
                        border-radius: 15px;
                        color: #888888;
                        font-size: 12px;
                        width: 73px;
                        height: 25px;
                        line-height: 25px;
                        background: url(../img/image-X.png) no-repeat left 12px top 7px;
                        background-size: 10px auto;
                        transition:0.4s;
                        padding-left:28px;
                      }
                      &+p {
                        color: #888888;
                        font-size: 14px;
                        line-height: 24px;
                        letter-spacing: 1.5px;
                        margin-top: 12px;
                        padding-left: 1.5em;
                        text-indent: -1.5em;
                      }
                    }
                  }
                }
                &:first-of-type {
                  padding-bottom: 24px;
                  position: relative;
                }
                &:nth-of-type(2) { //お名前
                  >dt {

                  }
                  >dd {
                    &:nth-of-type(2) {
                      padding-top: 12px;
                      .cautionText {

                      }
                      input {
                        width: 100%;
                        border-radius: 5px;
                        height: 50px;
                        font-size: 16px;
                      }
                    }
                  }
                }
                &:nth-of-type(3) { //フリガナ
                  >dt {

                  }
                  >dd {
                    &:nth-of-type(2) {
                      padding-top: 12px;
                      .cautionText {

                      }
                      input {
                        width: 100%;
                        border-radius: 5px;
                        height: 50px;
                        font-size: 16px;
                      }
                    }
                  }
                }
                &:nth-of-type(4) { //メールアドレス
                  >dt {

                  }
                  >dd {
                    &:nth-of-type(2) {
                      padding-top: 12px;
                      .cautionText {

                      }
                      input {
                        width: 100%;
                        border-radius: 5px;
                        height: 50px;
                        font-size: 16px;
                      }
                    }
                  }
                }
                &:nth-of-type(5) {  //確認メール
                  padding-bottom: 30px;
                  >dt {

                  }
                  >dd {
                    &:nth-of-type(2) {
                      padding-top: 12px;
                      dl {
                        dd {
                          &:first-of-type {
                            .cautionText {

                            }
                            input {
                              width: 100%;
                              border-radius: 5px;
                              height: 50px;
                              font-size: 16px;
                            }
                          }
                          &:nth-of-type(2) {
                            display: block;
                            font-size: 14px;
                            color: #888888;
                            line-height: 14px;
                            margin-top: 10px;
                            letter-spacing: 1.5px;
                          }
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(6) {  //ご相談内容
                  border-bottom: 1px solid #f1f1f1;
                  >dt {

                  }
                  >dd {
                    &:nth-of-type(2) {
                      padding-top: 12px;
                      .cautionText {

                      }
                      textarea {
                        width: 100%;
                        background-color: #eeeeee;
                        border-radius: 5px;
                        height: 250px;
                        font-size: 16px;
                        color: #222;
                        resize: none;
                        letter-spacing: 1.5px;
                        padding: 13px 16px 0;
                        &:focus{
                          border: 1px solid #e8d492;
                          background-color: #fff;
                        }
                      }
                    }
                  }
                }
                input:-webkit-input-placeholder{
                  color: #888;
                }
                input::-webkit-input-placeholder{
                  color: #888;
                }
                input:-ms-input-placeholder{
                  color: #888;
                }
                textarea:-webkit-input-placeholder{
                  color: #888;
                }
                textarea::-webkit-input-placeholder{
                  color: #888;
                }
                textarea:-ms-input-placeholder{
                  color: #888;
                }
              }
              >div {
                ul {
                  width: 285px;
                  height: 80px;
                  margin: 45px auto 105px;
                  li {
                    width: 100%;
                    background-color: #d1ae39;
                    border-radius: 7px;
                    width: 100%;
                    input {
                      display: block;
                      width: 100%;
                      height: 100%;
                      background: transparent url(../img/arrow05.png) no-repeat center right 15px;
                      background-size: 11px auto;
                      line-height: 80px;
                      text-align: center;
                      font-size: 20px;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  簡単画像診断（入力内容のご確認）
  -------------------------------------*/
  #PageDiagnostic-imaging.pageConfirm {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            h3 {
              padding: 0 15px;
              span {
                display: block;
                font-size: 20px;
                margin-top: -3px;
                letter-spacing: 2px;
              }
            }
            p {
              padding: 0 15px;
              font-size: 16px;
              text-align: center;
              margin-top: 58px;
              letter-spacing: 1.5px;
              text-align: left;
              span {
              }
            }
            >form {
              margin-top: 35px;
              >dl {
                border-top: 1px solid #f1f1f1;
                padding-bottom: 25px;
                font-size: 0;
                letter-spacing: normal;
                padding: 0 15px;
                >dt {
                  width: 200px;
                  display: inline-block;
                  vertical-align: top;
                  margin-top: 30px;
                  font-size: 16px;
                  color: #222222;
                  padding-left: 10px;
                  letter-spacing: 2px;
                }
                >dd {
                  &:first-of-type {
                    width: 40px;
                    display: inline-block;
                    vertical-align: top;
                    color: #fefefe;
                    font-size: 12px;
                    background-color: #e42f2f;
                    text-align: center;
                    height: 20px;
                    line-height: 20px;
                    margin-top: 30px;
                  }
                  &:nth-of-type(2) {
                    font-size: 16px;
                    color: #222;
                    padding-top: 7px;
                    letter-spacing: 1.5px;
                  }
                }
                &:first-of-type {  //画像添付
                  padding-bottom: 25px;
                  >dt {

                  }
                  >dd {
                    padding-top: 0;
                    &:nth-of-type(2) {  //選択した画像名
                      >dl {
                        padding-top: 8px;
                        >dd {
                          &:first-of-type {

                          }
                          &:nth-of-type(2) {
                            margin-top: 3px;
                          }
                          &.note {
                            margin-top: 4px;
                            font-size: 14px;
                            color: #888888;
                            line-height: 24px;
                            text-indent: -1.5em;
                            padding-left: 1.5em;
                          }
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(2) {  //お名前

                }
                &:nth-of-type(3) {  //フリガナ

                }
                &:nth-of-type(4) {  //メールアドレス

                }
                &:nth-of-type(5) {  //ご相談内容

                }
              }
              >div {
                ul {
                  width: 285px;
                  height: 80px;
                  margin: 48px auto 30px;
                  li {
                    width: 100%;
                    background-color: #d1ae39;
                    border-radius: 7px;
                    width: 100%;
                    input {
                      display: block;
                      width: 100%;
                      height: 100%;
                      background: transparent url(../img/arrow05.png) no-repeat center right 15px;
                      background-size: 11px auto;
                      line-height: 80px;
                      text-align: center;
                      font-size: 18px;
                      color: #fff;
                      letter-spacing: 2px;
                    }
                  }
                }
              }
              >ul {
                margin-bottom: 87px;
                li {
                  width: 150px;
                  height: 40px;
                  border: 1px solid #e5e5e5;
                  border-radius: 7px;
                  margin: 0 auto;
                  input {
                    width: 100%;
                    height: 100%;
                    display: block;
                    text-align: center;
                    line-height: 40px;
                    font-size: 14px;
                    color: #888888;
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  簡単画像診断（送信完了）
  -------------------------------------*/
  #PageDiagnostic-imaging.pageThanks {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
            margin-bottom: -30px;
            padding: 0 15px;
            h3 {
              text-align: center;
              font-size: 28px;
              letter-spacing: 5px;
              margin-bottom: 57px;
              span {
                display: block;
                font-size: 18px;
                margin-top: 0;
                letter-spacing: 4px;
              }
            }
            p {
              text-align: left;
              font-size: 16px;
              letter-spacing: 1.5px;
              line-height: 28px;
              &:nth-of-type(2) {
                margin-top: 21px;
                span {
                }
              }
            }
            ul {
              margin-top: 51px;
              li {
                width: 250px;
                height: 50px;
                margin: 0 auto;
                text-align: center;
                a {
                  display: block;
                  width: 100%;
                  height: 100%;
                  line-height: 50px;
                  font-size: 16px;
                  color: #c5a22b;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  動画解説
  -------------------------------------*/
	#PageMovie {
		#MainImg{
			#MainImgInner{
				background-image: url(../img/lower_mainimg12.jpg);
			}
		}
		#Container {
			#Main{
				margin-bottom: 144px;
				#MovieNav{
					margin: 59px 15px 74px;
					border: 1px solid #e5e5e5;
					border-radius: 6px;
					ul{
						letter-spacing: normal;
						font-size: 0;
						li{
							position: relative;
							display: inline-block;
							border-top: 1px solid #e5e5e5;
							width: 50%;
							height: 40px;
							line-height: 40px;
							text-align: center;
							font-size: 14px;
							&:nth-of-type(odd){
								border-right: 1px solid #e5e5e5;
							}
							&:nth-of-type(1), &:nth-of-type(2){
								border-top: none;
							}
							&.current{
								a{
									color: #ac832d;
								}
								span::after{
									content: "";
									position: absolute;
									bottom: 0px;
									left: 0;
									display: block;
									width: 100%;
									height: 2px;
									background-color: #e7d48a;
								}
							}
							a{
								display: block;
								width: 100%;
								height: 100%;
								text-decoration: none;
								letter-spacing: 1px;
								color: #888;
								&:hover {
									color: #c5a22b;
								}
							}
							span{
								position: relative;
								display: inline-block;
							}
						}
					}
				}
				h3{
					margin-top: -6px;
					margin-bottom: 35px;
				}
				.movieiList{
					#MovieBlock{
						margin-right: 15px;
						margin-left: 15px;
						&::after{
							content:"";
							display:block;
							clear:both;
						}
						li{
							float: left;
							margin-bottom: 50px;
							width: 50%;
							&:nth-of-type(2n){
								dl{
									padding-right: 0;
								}
							}
							&:nth-of-type(2n+1){
								clear: both;
							}
							dl{
								padding-right: 1px;
								dt{
									cursor: pointer;
									position: relative;
									padding-top: 55.823%;
									margin-bottom: 7px;
									width: 100%;
									background-repeat: no-repeat;
									background-position: center center;
									background-size: cover;
									&::before{
										content: "";
										position: absolute;
										top: 0;
										left: 0;
										width: 100%;
										height: 100%;
										background-repeat: no-repeat;
										background-position: center center;
										background-image: url("../img/icon_movie.png");
										background-size: 34px auto;
										background-color: rgba(0, 0, 0, .05);
									}
								}
								dd{
									padding-right: 25px;
									overflow: hidden;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 3;
									line-height: 20px;
									a{
										text-decoration: none;
										font-size: 12px;
										color: #888;
									}
								}
							}
						}
					}
				}
				.viewBtn {
					margin: 0 auto;
					width: 220px;
					height: 40px;
					line-height: 40px;
					cursor: pointer;
					div{
						background-repeat: no-repeat;
						background-position: center right 15px;
						background-image: url(../img/icon_plus.png);
						background-size: 15px auto;
						font-size: 14px;
					}
				}
			}
		}
		#cover {
			display:none;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height:100%;
			background-color: rgba(0,0,0,0.3);
			z-index: 99999;
		}
		#coverVideo {
			display:none;
			position: fixed;
			left: 0;
			top: 0;
			box-sizing: border-box;
			padding: 30px;
			width: 100%;
			height:100%;
			z-index: 99999;
			text-align: center;
			&:before {
				content: '';
				display: inline-block;
				height: 100%;
				vertical-align: middle;
			}
		}
		#coverVideoClose {
			position: absolute;
			top: -71px;
			right: -31px;
			width: 30px;
			height: auto;
			z-index: 9999999;
			cursor: pointer;
		}
		#coverVideoWrapContent {
          border-radius: 6px;
		  padding: 30px;
		  width: 100%;
		  max-width: 1000px;
		  background-color: #fff;
		  position: relative;
		  display: inline-block;
		  vertical-align: middle;
		  margin: 0 auto;
		}
		#coverVideoContent {
			position: relative;
			margin: auto;
			padding-top: 56.25%;
			width: 100%;
			height: 0;
			iframe{
				position:absolute;
				display: block;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

  /*
  動画解説（トップページ）
  -------------------------------------*/
  #PageMovie.pageIndex {
    #Container {
      #Main{
        #ContBox02{
		  }
		}
	  }
	}

  /*
  動画解説（カテゴリ）
  -------------------------------------*/
  #PageMovie.pageCategory {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {	
			}
		  }
		}
	  }
	}

  /*
  動画解説（詳細）
  -------------------------------------*/
  #PageMovie.pageEntry {
    #Container {
      .innerBasic {
        #Main {
          #ContBox01 {
			  margin-right: 15px;
			  margin-left: 15px;
			  .mainContTitle{
				  h3{
					margin-top: -7px;
					margin-bottom: 46px;
					letter-spacing: 3px;
					border-bottom: 3px solid #f1f1f1;
					padding: 0 0 8px 0;
					line-height: 40px;
					text-align: left;
					font-family: "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
					font-size: 28px;
					font-weight: 500;
				  }
			  }
			  #ContSubBox01{
				  .movieBox{
					position: relative;
					margin: 0 auto 23px;
					padding-top: 56.25%;
					width: 100%;
					height: 0;
					iframe{
						position:absolute;
						display: block;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
					}
				  }
			  }
			}
		  }
		}
	  }
	}

}

@media print,
screen and (max-width: 640px){
  #PageAbout.pageIndex{
    #Main{
      #ContBox02{
        .titleTmg{
          img{
            height:150px;
            width:auto;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (-ms-high-contrast: active), print and (-ms-high-contrast: none), screen and (min-width: 768px) and (-ms-high-contrast: none) {
  #Header .contactBox dt{
    padding-top: 6px;
    line-height: 1;
  }
  .contact_calenderBlock .left .contactBox dt{
    padding-top: 9px;
    line-height: 1;
  }
  .fixed #Header #HeaderMenu ul li.mailLink a{  background-position: left 4px;}
  .fixed #Header #HeaderMenu ul li.imgFormLink a{ background-position: left 1px;}
  #Main .typeList li{
    padding-top: 5px;
    line-height: 1;
  }
  .pageEntry#PageTreatment #Main #ContBox01 #CaseBox .caseList dl dt{
    padding-top: 5px;
    padding-bottom: 1px;
  }
  .pageIndex#PageFaq #Container .innerBasic #Main #ContBox02 > div h4{
    padding-top: 7px;
    padding-bottom: 3px;
  }
}
